import { Card } from '@mui/material'
import React, { useEffect, useState } from 'react'
import FormButtons from '../../FormFabs.tsx'
import GeneralHealthInformation from './steps/GeneralHealthInformation.tsx'
import Signature from './steps/Signature.tsx'
import PersonalHistory from './steps/PersonalHistory.tsx'
import Header from '../../Header.tsx'
import { useSelector } from 'react-redux'
import axios from 'axios'
import DentalProblems from './steps/DentalProblems.tsx'

export default () => {
  const SLUG = 'dental-history-minor'
  const MAX_STEPS = 4
  const [ step, setStep ] = useState(1)
  const [ validation, setValidation ] = useState(false)
  const [ submitting, setSubmitting ] = useState(false)
  const [ fetching, setFetching ] = useState(true)
  const { patient, formName } = useSelector((state) => state)
  const [ patientForm, setPatientForm ] = useState({})
  
  const nextStep = () => {
    if(step < MAX_STEPS) setStep(step + 1)
    else submitForm()
  }
  const prevStep = () => {
    if(step > 1) setStep(step - 1)
  }

  const [ formData, setFormData ] = useState({
    step_1: {
      prev_dentist: '',
      date_of_last_dental_exam: '',
      date_of_last_cleaning: '',
      dental_visit_reason: '',
    },
    step_2: {
      unfavourable_reac_from_prev: '',
      unfavourable_reac_from_prev_reason: '',
      suck_finger: '',
      suck_finger_reason: '',
      pain_in_chewing: '',
      pain_in_chewing_reason: '',
      goes_bed_with_bottle: '',
      goes_bed_with_bottle_reason: '',
      snack_frequently: '',
      snack_frequently_reason: '',
      injured_teeth: '',
      injured_teeth_reason: '',
      use_fluoride_toothpaste: '',
      use_fluoride_toothpaste_reason: '',
      had_local_anesthetic: '',
      had_local_anesthetic_reason: '',
      sedated_for_treatment: '',
      sedated_for_treatment_reason: '',
    },
    step_3: {
      cavities: '',
      cavities_reason: '',
      trauma: '',
      trauma_reason: '',
      orthodontics: '',
      orthodontics_reason: '',
      toothache: '',
      toothache_reason: '',
      gum_infection: '',
      gum_infection_reason: '',
      jaw_sound: '',
      jaw_sound_reason: '',
      sensitive_teeth: '',
      sensitive_teeth_reason: '',
      color_of_teeth: '',
      color_of_teeth_reason: '',
      grinding_of_teeth: '',
      grinding_of_teeth_reason: '',
      mouth_breathing: '',
      mouth_breathing_reason: '',
      other: '',
      other_reason: '',
    },
    step_4: {
      sign_as: '',
      signature: '',
    },
  })

  const submitForm = async () => {
    if(submitting) return
    setSubmitting(true)
    try{
      const payload = {
        form_activation_id: patient?.form_activation_id,
        patient_id: patient?.patient?.id,
        form_name: formName,
        form_data: formData
      };
      const { data } = patientForm?.id ? await axios.put(`${process.env.REACT_APP_CARESUITE_APP_URL}/public/api/v1/patient_forms/${patientForm?.id}`, payload) : await axios.post(`${process.env.REACT_APP_CARESUITE_APP_URL}/public/api/v1/patient_forms`, payload);
      setPatientForm(data)
    }catch(e){
      console.log(e)
    }
    setSubmitting(false)
  }

  const fetchData = async () => {
    console.log(patient)
    const { data } = await axios.get(`${process.env.REACT_APP_CARESUITE_APP_URL}/public/api/v1/patient_forms/show_by_form_name`,{
      params: { form_activation_id: patient?.form_activation_id }
    });
    if(data){
      setPatientForm(data)
      setFormData(data.form_data)
    }
    setFetching(false)
  }
  
  useEffect(() => { fetchData() }, []);
  return (
    <>
      <Header title='Dental History' />
      <div className='max-w-[1000px] mx-auto py-12'>
        <div className="flex gap-4 flex-wrap justify-between items-center">
          <p className="text-[#1d3243] font-bold text-[20px]">
            {
            step == 1 ? <span>General Information</span>
            :step == 2 ? <span>Personal History</span>
            :step == 3 ? <span>Dental Problems</span>
            :step == 4 ? <span>Signature</span>
            :<span></span>
            }
          </p>
          <p className="text-[#1d3243] font-bold text-[16px]">Step {step}/{MAX_STEPS}</p>
        </div>
        <Card className='p-8 flex flex-col gap-4' elevation={3}>
          <GeneralHealthInformation className={`${step == 1 ? 'flex' : 'hidden'}`} step={1} prevStep={prevStep} nextStep={nextStep} validation={validation} slug={SLUG} formData={formData} setFormData={setFormData} />
          <PersonalHistory className={`${step == 2 ? 'flex' : 'hidden'}`} showBack step={2} prevStep={prevStep} nextStep={nextStep} validation={validation} slug={SLUG} formData={formData} setFormData={setFormData} />
          <DentalProblems className={`${step == 3 ? 'flex' : 'hidden'}`} showBack step={3} prevStep={prevStep} nextStep={nextStep} validation={validation} slug={SLUG} formData={formData} setFormData={setFormData} />
          <Signature className={`${step == 4 ? 'flex' : 'hidden'}`} showBack step={4} prevStep={prevStep} nextStep={nextStep} validation={validation} slug={SLUG} formData={formData} setFormData={setFormData} />
          {!validation && <p className='mb-0 text-[1rem] text-[#ff6e19] text-center'>PREVIEW MODE - form validation disabled!</p>}
        </Card>
      </div>
      <FormButtons prevStep={prevStep} nextStep={nextStep} step={step} validation={validation} setValidation={setValidation} slug={SLUG} maxStep={MAX_STEPS} />
    </>
  )
}