import { Card } from '@mui/material'
import React, { useEffect, useState } from 'react'
import FormButtons from '../../FormFabs.tsx'
import BasicInfo from './steps/BasicInfo.tsx';
import ContactInfo from './steps/ContactInfo.tsx';
import AddressInfo from './steps/AddressInfo.tsx';
import EmergencyContact from './steps/EmergencyContact.tsx';
import WorkInfo from './steps/WorkInfo.tsx';
import ConsentForDental from './steps/ConsentForDental.tsx';
import FinancialPolicy from './steps/FinancialPolicy.tsx';
import CommunicationConsent from './steps/CommunicationConsent.tsx';
import PrivacyPolicy from './steps/PrivacyPolicy.tsx';
import Signature from './steps/Signature.tsx';
import Header from '../../Header.tsx';
import { useSelector } from 'react-redux';
import axios from "axios"

export default () => {
  const SLUG = 'existing-patient'
  const MAX_STEPS = 10
  const [ step, setStep ] = useState(1)
  const [ validation, setValidation ] = useState(false)
  
  const nextStep = () => {
    if(step < MAX_STEPS) setStep(step + 1)
  }
  const prevStep = () => {
    if(step > 1) setStep(step - 1)
  }
  const [ formData, setFormData ] = useState({
    step_1: {
      first_name: '',
      last_name: '',
      middle_initial: '',
      preferred_name: '',
      dob: '',
      photo: '',
      gender: '',
      ssn: '',
      licence_front_photo: '',
      licence_back_photo: '',
      marital_status: '',
      heard_from: '',
      heard_from_other: '',
    },
    step_2: {
      email: '',
      phone: '',
      home_phone: '',
    },
    step_3: {
      address: '',
      city: '',
      state: '',
      zip: '',
    },
    step_4: {
      emergency_contact: 'true',
      full_name: '',
      contact_phone: '',
      relationship: '',
    },
    step_5: {
      provide_work_address: 'true',
      employer: '',
      occupation: '',
      address_work: '',
      city_work: '',
      state_work: '',
      zip_work: '',
    },
    step_6: {
      agree: false,
    },
    step_7: {
      agree: false,
    },
    step_8: {
      agree: false,
      info_by_email: '',
      info_by_email_reason: '',
      info_by_text: '',
      info_by_text_reason: '',
    },
    step_9: {
      agree: false,
    },
    step_10: {
      sign_as: '',
      signature: '',
      guardian_full_name: '',
      guardian_dob: '',
      guardian_address: '',
      guardian_city: '',
      guardian_state: '',
      guardian_zip: '',
    },
  })

  return (
    <>
      <Header title='Existing Patient Form' />
      <div className='max-w-[1000px] mx-auto py-12'>
        <div className="flex gap-4 flex-wrap justify-between items-center">
          <p className="text-[#1d3243] font-bold text-[20px]">
            {
            step == 1 ? <span>Basic Information</span>
            :step == 2 ? <span>Contact Information</span>
            :step == 3 ? <span>Address</span>
            :step == 4 ? <span>Emergency Contact Information</span>
            :step == 5 ? <span>Work Information</span>
            :step == 6 ? <span>Consent for Dental Treatment</span>
            :step == 7 ? <span>Financial Policy</span>
            :step == 8 ? <span>Communication Consents</span>
            :step == 9 ? <span>Privacy Policy Consent</span>
            :step == 10 ? <span>Signature</span>
            :<span>Signature</span>
            }
          </p>
          <p className="text-[#1d3243] font-bold text-[16px]">Step {step}/{MAX_STEPS}</p>
        </div>
        <Card className='p-8 flex flex-col gap-4' elevation={3}>
          <BasicInfo className={`${step == 1 ? 'flex' : 'hidden'}`} step={1} prevStep={prevStep} nextStep={nextStep} validation={validation} slug={SLUG} formData={formData} setFormData={setFormData} />
          <ContactInfo className={`${step == 2 ? 'flex' : 'hidden'}`} showBack step={2} prevStep={prevStep} nextStep={nextStep} validation={validation} slug={SLUG} formData={formData} setFormData={setFormData} />
          <AddressInfo className={`${step == 3 ? 'flex' : 'hidden'}`} showBack step={3} prevStep={prevStep} nextStep={nextStep} validation={validation} slug={SLUG} formData={formData} setFormData={setFormData} />
          <EmergencyContact className={`${step == 4 ? 'flex' : 'hidden'}`} showBack step={4} prevStep={prevStep} nextStep={nextStep} validation={validation} slug={SLUG} formData={formData} setFormData={setFormData} />
          <WorkInfo className={`${step == 5 ? 'flex' : 'hidden'}`} showBack step={5} prevStep={prevStep} nextStep={nextStep} validation={validation} slug={SLUG} formData={formData} setFormData={setFormData} />
          <ConsentForDental className={`${step == 6 ? 'flex' : 'hidden'}`} showBack step={6} prevStep={prevStep} nextStep={nextStep} validation={validation} slug={SLUG} formData={formData} setFormData={setFormData} />
          <FinancialPolicy className={`${step == 7 ? 'flex' : 'hidden'}`} showBack step={7} prevStep={prevStep} nextStep={nextStep} validation={validation} slug={SLUG} formData={formData} setFormData={setFormData} />
          <CommunicationConsent className={`${step == 8 ? 'flex' : 'hidden'}`} showBack step={8} prevStep={prevStep} nextStep={nextStep} validation={validation} slug={SLUG} formData={formData} setFormData={setFormData} />
          <PrivacyPolicy className={`${step == 9 ? 'flex' : 'hidden'}`} showBack step={9} prevStep={prevStep} nextStep={nextStep} validation={validation} slug={SLUG} formData={formData} setFormData={setFormData} />
          <Signature className={`${step == 10 ? 'flex' : 'hidden'}`} showBack step={10} prevStep={prevStep} nextStep={nextStep} validation={validation} slug={SLUG} formData={formData} setFormData={setFormData} />
          {!validation && <p className='mb-0 text-[1rem] text-[#ff6e19] text-center'>PREVIEW MODE - form validation disabled!</p>}
        </Card>
      </div>
      <FormButtons prevStep={prevStep} nextStep={nextStep} step={step} validation={validation} setValidation={setValidation} slug={SLUG} maxStep={MAX_STEPS} />
    </>
  )
}