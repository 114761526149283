import React, { useEffect } from 'react'
import { Button, FormHelperText } from '@mui/joy'
import { ArrowForward, ArrowBack } from '@mui/icons-material'
import { useFormik } from 'formik'
import * as yup from 'yup'
import IFormProps from 'component/Form/IFormProps.ts'
import EventBus from '../../../../../bus.ts'
import { TextField, FormControl } from '@mui/material'

export default (props: IFormProps) => {

  const onFilePicked = e => {
    let files = e.target.files
    var reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = function () {
      console.log({ name: e.target.name, value: reader.result })
      form1.setFieldValue(e.target.name, reader.result)
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }
  
  const validationSchema = () => {
    if(!props.validation) return yup.object({})
    const obj = {
      picture_1: yup.string().required('Required'),
    }
    return yup.object(obj)
  }

  const form1 = useFormik({
    initialValues: props.formData['step_' + props.step],
    validationSchema: validationSchema,
    onSubmit: (values) => {
      try{
        props.setFormData({ ...props.formData, ['step_' + props.step]: values })
        props.nextStep()
      }catch(e){
        console.log(e)
      }
    },
  });

  useEffect(() => {
    EventBus.$on('submit-next-' + props.slug + '-' + props.step, form1.handleSubmit)
  }, [])
  
  useEffect(() => {
    Object.keys(props.formData['step_' + props.step]).forEach(key => form1?.setFieldValue(key, props.formData['step_' + props.step][key]))
  }, [ props.formData['step_' + props.step] ])

  return (
    <form onSubmit={form1.handleSubmit} className={'flex flex-col gap-4 ' + props.className}>
      <FormControl error={form1.touched.symptoms && Boolean(form1.errors.symptoms)}>
        <TextField
          label="Please describe the symptoms or concerns related to pictures you are sending (if applicable)" variant="standard" name='symptoms' fullWidth
          value={ form1.values.symptoms } onChange={ form1.handleChange } onBlur={form1.handleBlur}
        />
        {form1.touched.symptoms && Boolean(form1.errors.symptoms) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      
      <FormControl error={form1.touched.picture_1 && Boolean(form1.errors.picture_1)}>
        <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Please attach a desired picture (1)*</p>
        <TextField variant="standard" name='picture_1' fullWidth type='file' onChange={ onFilePicked } />
        {form1.touched.picture_1 && Boolean(form1.errors.picture_1) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      <div>
        <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Please attach a desired picture (2)</p>
        <TextField variant="standard" name='picture_2' fullWidth type='file' onChange={ onFilePicked } />
      </div>
      <div>
        <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Please attach a desired picture (3)</p>
        <TextField variant="standard" name='picture_3' fullWidth type='file' onChange={ onFilePicked } />
      </div>

      <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>* Mandatory fields</p>
      <div className="flex">
        {props.showBack && <Button variant='plain' onClick={props.prevStep}>
          <ArrowBack />
          Back
        </Button>}
        <Button color='primary' type='submit' className='!ml-auto'>
          Next
          <ArrowForward />
        </Button>
      </div>
    </form>
  )
}