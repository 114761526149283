import "./App.css";
import { Routes, Route } from "react-router-dom"
import React from "react"
import { Provider } from "react-redux";
import store, { persistor } from "./store";
import { PersistGate } from 'redux-persist/integration/react';
import DinoDentForm from "./component/Form/DinoDentForm";
import Auth from "./component/Auth/Auth";
import Verify from "./component/Verify/Verify";
import ProtectedRoute from "./ProtectedRoute";
import Toast from "./component/Toast.tsx";
import DinoDentFormPreview from "./component/Form/DinoDentFormPreview.tsx";

import NewPatientForm from "./component/Form/lakewooddentaltrails/new-patient/NewPatientForm.tsx";
import NewPatientFormPreview from "./component/Form/lakewooddentaltrails/new-patient/NewPatientFormPreview.tsx";
import NewPatientMinorForm from "./component/Form/lakewooddentaltrails/new-patient-minor/NewPatientMinorForm.tsx";
import NewPatientMinorFormPreview from "./component/Form/lakewooddentaltrails/new-patient-minor/NewPatientMinorFormPreview.tsx";
import Covid19QuestionForm from "./component/Form/lakewooddentaltrails/covid-19-questionnaire/Covid19QuestionForm.tsx";
import Covid19QuestionFormPreview from "./component/Form/lakewooddentaltrails/covid-19-questionnaire/Covid19QuestionFormPreview.tsx";
import ContactDetailsForm from "./component/Form/lakewooddentaltrails/contact-details/ContactDetailsForm.tsx";
import ContactDetailsFormPreview from "./component/Form/lakewooddentaltrails/contact-details/ContactDetailsFormPreview.tsx";
import UpdateProfilePhoto from "./component/Form/lakewooddentaltrails/update-profile-photo/UpdateProfilePhoto.tsx";
import UpdateProfilePhotoPreview from "./component/Form/lakewooddentaltrails/update-profile-photo/UpdateProfilePhotoPreview.tsx";
import HealthHistory from "./component/Form/lakewooddentaltrails/health-history/HealthHistory.tsx";
import HealthHistoryPreview from "./component/Form/lakewooddentaltrails/health-history/HealthHistoryPreview.tsx";
import HealthHistoryMinor from "./component/Form/lakewooddentaltrails/health-history-minor/HealthHistoryMinor.tsx";
import HealthHistoryMinorPreview from "./component/Form/lakewooddentaltrails/health-history-minor/HealthHistoryMinorPreview.tsx";
import DentalHistory from "./component/Form/lakewooddentaltrails/dental-history/DentalHistory.tsx";
import DentalHistoryPreview from "./component/Form/lakewooddentaltrails/dental-history/DentalHistoryPreview.tsx";
import DentalHistoryMinor from "./component/Form/lakewooddentaltrails/dental-history-minor/DentalHistoryMinor.tsx";
import DentalHistoryMinorPreview from "./component/Form/lakewooddentaltrails/dental-history-minor/DentalHistoryMinorPreview.tsx";
import DentalInsurance from "./component/Form/lakewooddentaltrails/dental-insurance/DentalInsurance.tsx";
import DentalInsurancePreview from "./component/Form/lakewooddentaltrails/dental-insurance/DentalInsurancePreview.tsx";
import RequestPicture from "./component/Form/lakewooddentaltrails/request-picture/RequestPicture.tsx";
import RequestPicturePreview from "./component/Form/lakewooddentaltrails/request-picture/RequestPicturePreview.tsx";
import LicenceUpdate from "./component/Form/lakewooddentaltrails/licence-update/LicenceUpdate.tsx";
import LicenceUpdatePreview from "./component/Form/lakewooddentaltrails/licence-update/LicenceUpdatePreview.tsx";
import MedicalInsurance from "./component/Form/lakewooddentaltrails/medical-insurance/MedicalInsurance.tsx";
import MedicalInsurancePreview from "./component/Form/lakewooddentaltrails/medical-insurance/MedicalInsurancePreview.tsx";
import ReloaseOfRecords from "./component/Form/lakewooddentaltrails/release-of-records/ReloaseOfRecords.tsx";
import ReloaseOfRecordsPreview from "./component/Form/lakewooddentaltrails/release-of-records/ReloaseOfRecordsPreview.tsx";
import ReloaseOfRecordsMinor from "./component/Form/lakewooddentaltrails/release-of-records-minor/ReloaseOfRecordsMinor.tsx";
import ReloaseOfRecordsMinorPreview from "./component/Form/lakewooddentaltrails/release-of-records-minor/ReloaseOfRecordsMinorPreview.tsx";
import ExistingPatient from "./component/Form/lakewooddentaltrails/existing-patient/ExistingPatient.tsx";
import ExistingPatientPreview from "./component/Form/lakewooddentaltrails/existing-patient/ExistingPatientPreview.tsx";

import NewPatientForm_templedentaltrails from "./component/Form/templedentaltrails/new-patient/NewPatientForm.tsx";
import NewPatientFormPreview_templedentaltrails from "./component/Form/templedentaltrails/new-patient/NewPatientFormPreview.tsx";
import NewPatientMinorForm_templedentaltrails from "./component/Form/templedentaltrails/new-patient-minor/NewPatientMinorForm.tsx";
import NewPatientMinorFormPreview_templedentaltrails from "./component/Form/templedentaltrails/new-patient-minor/NewPatientMinorFormPreview.tsx";
import Covid19QuestionForm_templedentaltrails from "./component/Form/templedentaltrails/covid-19-questionnaire/Covid19QuestionForm.tsx";
import Covid19QuestionFormPreview_templedentaltrails from "./component/Form/templedentaltrails/covid-19-questionnaire/Covid19QuestionFormPreview.tsx";
import ContactDetailsForm_templedentaltrails from "./component/Form/templedentaltrails/contact-details/ContactDetailsForm.tsx";
import ContactDetailsFormPreview_templedentaltrails from "./component/Form/templedentaltrails/contact-details/ContactDetailsFormPreview.tsx";
import UpdateProfilePhoto_templedentaltrails from "./component/Form/templedentaltrails/update-profile-photo/UpdateProfilePhoto.tsx";
import UpdateProfilePhotoPreview_templedentaltrails from "./component/Form/templedentaltrails/update-profile-photo/UpdateProfilePhotoPreview.tsx";
import HealthHistory_templedentaltrails from "./component/Form/templedentaltrails/health-history/HealthHistory.tsx";
import HealthHistoryPreview_templedentaltrails from "./component/Form/templedentaltrails/health-history/HealthHistoryPreview.tsx";
import HealthHistoryMinor_templedentaltrails from "./component/Form/templedentaltrails/health-history-minor/HealthHistoryMinor.tsx";
import HealthHistoryMinorPreview_templedentaltrails from "./component/Form/templedentaltrails/health-history-minor/HealthHistoryMinorPreview.tsx";
import DentalHistory_templedentaltrails from "./component/Form/templedentaltrails/dental-history/DentalHistory.tsx";
import DentalHistoryPreview_templedentaltrails from "./component/Form/templedentaltrails/dental-history/DentalHistoryPreview.tsx";
import DentalHistoryMinor_templedentaltrails from "./component/Form/templedentaltrails/dental-history-minor/DentalHistoryMinor.tsx";
import DentalHistoryMinorPreview_templedentaltrails from "./component/Form/templedentaltrails/dental-history-minor/DentalHistoryMinorPreview.tsx";
import DentalInsurance_templedentaltrails from "./component/Form/templedentaltrails/dental-insurance/DentalInsurance.tsx";
import DentalInsurancePreview_templedentaltrails from "./component/Form/templedentaltrails/dental-insurance/DentalInsurancePreview.tsx";
import RequestPicture_templedentaltrails from "./component/Form/templedentaltrails/request-picture/RequestPicture.tsx";
import RequestPicturePreview_templedentaltrails from "./component/Form/templedentaltrails/request-picture/RequestPicturePreview.tsx";
import LicenceUpdate_templedentaltrails from "./component/Form/templedentaltrails/licence-update/LicenceUpdate.tsx";
import LicenceUpdatePreview_templedentaltrails from "./component/Form/templedentaltrails/licence-update/LicenceUpdatePreview.tsx";
import MedicalInsurance_templedentaltrails from "./component/Form/templedentaltrails/medical-insurance/MedicalInsurance.tsx";
import MedicalInsurancePreview_templedentaltrails from "./component/Form/templedentaltrails/medical-insurance/MedicalInsurancePreview.tsx";
import ReloaseOfRecords_templedentaltrails from "./component/Form/templedentaltrails/release-of-records/ReloaseOfRecords.tsx";
import ReloaseOfRecordsPreview_templedentaltrails from "./component/Form/templedentaltrails/release-of-records/ReloaseOfRecordsPreview.tsx";
import ReloaseOfRecordsMinor_templedentaltrails from "./component/Form/templedentaltrails/release-of-records-minor/ReloaseOfRecordsMinor.tsx";
import ReloaseOfRecordsMinorPreview_templedentaltrails from "./component/Form/templedentaltrails/release-of-records-minor/ReloaseOfRecordsMinorPreview.tsx";
import ExistingPatient_templedentaltrails from "./component/Form/templedentaltrails/existing-patient/ExistingPatient.tsx";
import ExistingPatientPreview_templedentaltrails from "./component/Form/templedentaltrails/existing-patient/ExistingPatientPreview.tsx";
import PostAppointmentSurvey_templedentaltrails from "./component/Form/templedentaltrails/post-appointment-survey/PostAppointmentSurvey.tsx";
import PostAppointmentSurveyPreview_templedentaltrails from "./component/Form/templedentaltrails/post-appointment-survey/PostAppointmentSurveyPreview.tsx";

import NewPatientForm_riverheartfamily from "./component/Form/riverheartfamily/new-patient/NewPatientForm.tsx";
import NewPatientFormPreview_riverheartfamily from "./component/Form/riverheartfamily/new-patient/NewPatientFormPreview.tsx";
import NewPatientMinorForm_riverheartfamily from "./component/Form/riverheartfamily/new-patient-minor/NewPatientMinorForm.tsx";
import NewPatientMinorFormPreview_riverheartfamily from "./component/Form/riverheartfamily/new-patient-minor/NewPatientMinorFormPreview.tsx";
import Covid19Consent_riverheartfamily from "./component/Form/riverheartfamily/covid-19-consent/Covid19Consent.tsx";
import Covid19ConsentPreview_riverheartfamily from "./component/Form/riverheartfamily/covid-19-consent/Covid19ConsentPreview.tsx";
import ContactDetailsForm_riverheartfamily from "./component/Form/riverheartfamily/contact-details/ContactDetailsForm.tsx";
import ContactDetailsFormPreview_riverheartfamily from "./component/Form/riverheartfamily/contact-details/ContactDetailsFormPreview.tsx";
import UpdateProfilePhoto_riverheartfamily from "./component/Form/riverheartfamily/update-profile-photo/UpdateProfilePhoto.tsx";
import UpdateProfilePhotoPreview_riverheartfamily from "./component/Form/riverheartfamily/update-profile-photo/UpdateProfilePhotoPreview.tsx";
import HealthHistory_riverheartfamily from "./component/Form/riverheartfamily/health-history/HealthHistory.tsx";
import HealthHistoryPreview_riverheartfamily from "./component/Form/riverheartfamily/health-history/HealthHistoryPreview.tsx";
import HealthHistoryMinor_riverheartfamily from "./component/Form/riverheartfamily/health-history-minor/HealthHistoryMinor.tsx";
import HealthHistoryMinorPreview_riverheartfamily from "./component/Form/riverheartfamily/health-history-minor/HealthHistoryMinorPreview.tsx";
import DentalHistory_riverheartfamily from "./component/Form/riverheartfamily/dental-history/DentalHistory.tsx";
import DentalHistoryPreview_riverheartfamily from "./component/Form/riverheartfamily/dental-history/DentalHistoryPreview.tsx";
import DentalHistoryMinor_riverheartfamily from "./component/Form/riverheartfamily/dental-history-minor/DentalHistoryMinor.tsx";
import DentalHistoryMinorPreview_riverheartfamily from "./component/Form/riverheartfamily/dental-history-minor/DentalHistoryMinorPreview.tsx";
import DentalInsurance_riverheartfamily from "./component/Form/riverheartfamily/dental-insurance/DentalInsurance.tsx";
import DentalInsurancePreview_riverheartfamily from "./component/Form/riverheartfamily/dental-insurance/DentalInsurancePreview.tsx";
import RequestPicture_riverheartfamily from "./component/Form/riverheartfamily/request-picture/RequestPicture.tsx";
import RequestPicturePreview_riverheartfamily from "./component/Form/riverheartfamily/request-picture/RequestPicturePreview.tsx";
import LicenceUpdate_riverheartfamily from "./component/Form/riverheartfamily/licence-update/LicenceUpdate.tsx";
import LicenceUpdatePreview_riverheartfamily from "./component/Form/riverheartfamily/licence-update/LicenceUpdatePreview.tsx";
import ReloaseOfRecords_riverheartfamily from "./component/Form/riverheartfamily/release-of-records/ReloaseOfRecords.tsx";
import ReloaseOfRecordsPreview_riverheartfamily from "./component/Form/riverheartfamily/release-of-records/ReloaseOfRecordsPreview.tsx";
import ReloaseOfRecordsMinor_riverheartfamily from "./component/Form/riverheartfamily/release-of-records-minor/ReloaseOfRecordsMinor.tsx";
import ReloaseOfRecordsMinorPreview_riverheartfamily from "./component/Form/riverheartfamily/release-of-records-minor/ReloaseOfRecordsMinorPreview.tsx";
import ExistingPatient_riverheartfamily from "./component/Form/riverheartfamily/existing-patient/ExistingPatient.tsx";
import ExistingPatientPreview_riverheartfamily from "./component/Form/riverheartfamily/existing-patient/ExistingPatientPreview.tsx";
import OrthodonticExamination_riverheartfamily from "./component/Form/riverheartfamily/orthodontic-examination/OrthodonticExamination.tsx";
import OrthodonticExaminationPreview_riverheartfamily from "./component/Form/riverheartfamily/orthodontic-examination/OrthodonticExaminationPreview.tsx";

function App() {
  return (
    <>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Routes>
            <Route path="verify/:officeName/:formName/:token" element={<Verify />} />
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <Auth />
                </ProtectedRoute>
              }
            />
            <Route
              path=":form"
              element={
                <ProtectedRoute>
                  <DinoDentForm />
                </ProtectedRoute>
              }
            />
            <Route
              path="/form/preview/dinodent"
              element={
                <DinoDentFormPreview />
              }
            />
            <Route path="/lakewooddentaltrails/new-patient" element={ <ProtectedRoute> <NewPatientForm /> </ProtectedRoute> } />
            <Route path="/lakewooddentaltrails/new-patient-preview" element={ <NewPatientFormPreview /> } />
            <Route path="/lakewooddentaltrails/new-patient-minor" element={ <ProtectedRoute> <NewPatientMinorForm /> </ProtectedRoute> } />
            <Route path="/lakewooddentaltrails/new-patient-minor-preview" element={ <NewPatientMinorFormPreview /> } />
            <Route path="/lakewooddentaltrails/covid-19-questionnaire" element={ <ProtectedRoute> <Covid19QuestionForm /> </ProtectedRoute> } />
            <Route path="/lakewooddentaltrails/covid-19-questionnaire-preview" element={ <Covid19QuestionFormPreview /> } />
            <Route path="/lakewooddentaltrails/contact-details" element={ <ProtectedRoute> <ContactDetailsForm /> </ProtectedRoute> } />
            <Route path="/lakewooddentaltrails/contact-details-preview" element={ <ContactDetailsFormPreview /> } />
            <Route path="/lakewooddentaltrails/update-profile-photo" element={ <ProtectedRoute> <UpdateProfilePhoto /> </ProtectedRoute> } />
            <Route path="/lakewooddentaltrails/update-profile-photo-preview" element={ <UpdateProfilePhotoPreview /> } />
            <Route path="/lakewooddentaltrails/health-history" element={ <ProtectedRoute> <HealthHistory /> </ProtectedRoute> } />
            <Route path="/lakewooddentaltrails/health-history-preview" element={ <HealthHistoryPreview /> } />
            <Route path="/lakewooddentaltrails/health-history-minor" element={ <ProtectedRoute> <HealthHistoryMinor /> </ProtectedRoute> } />
            <Route path="/lakewooddentaltrails/health-history-minor-preview" element={ <HealthHistoryMinorPreview /> } />
            <Route path="/lakewooddentaltrails/dental-history" element={ <ProtectedRoute> <DentalHistory /> </ProtectedRoute> } />
            <Route path="/lakewooddentaltrails/dental-history-preview" element={ <DentalHistoryPreview /> } />
            <Route path="/lakewooddentaltrails/dental-history-minor" element={ <ProtectedRoute> <DentalHistoryMinor /> </ProtectedRoute> } />
            <Route path="/lakewooddentaltrails/dental-history-minor-preview" element={ <DentalHistoryMinorPreview /> } />
            <Route path="/lakewooddentaltrails/dental-insurance" element={ <ProtectedRoute> <DentalInsurance /> </ProtectedRoute> } />
            <Route path="/lakewooddentaltrails/dental-insurance-preview" element={ <DentalInsurancePreview /> } />
            <Route path="/lakewooddentaltrails/request-picture" element={ <ProtectedRoute> <RequestPicture /> </ProtectedRoute> } />
            <Route path="/lakewooddentaltrails/request-picture-preview" element={ <RequestPicturePreview /> } />
            <Route path="/lakewooddentaltrails/licence-update" element={ <ProtectedRoute> <LicenceUpdate /> </ProtectedRoute> } />
            <Route path="/lakewooddentaltrails/licence-update-preview" element={ <LicenceUpdatePreview /> } />
            <Route path="/lakewooddentaltrails/medical-insurance" element={ <ProtectedRoute> <MedicalInsurance /> </ProtectedRoute> } />
            <Route path="/lakewooddentaltrails/medical-insurance-preview" element={ <MedicalInsurancePreview /> } />
            <Route path="/lakewooddentaltrails/release-of-records" element={ <ProtectedRoute> <ReloaseOfRecords /> </ProtectedRoute> } />
            <Route path="/lakewooddentaltrails/release-of-records-preview" element={ <ReloaseOfRecordsPreview /> } />
            <Route path="/lakewooddentaltrails/release-of-records-minor" element={ <ProtectedRoute> <ReloaseOfRecordsMinor /> </ProtectedRoute> } />
            <Route path="/lakewooddentaltrails/release-of-records-minor-preview" element={ <ReloaseOfRecordsMinorPreview /> } />
            <Route path="/lakewooddentaltrails/existing-patient" element={ <ProtectedRoute> <ExistingPatient /> </ProtectedRoute> } />
            <Route path="/lakewooddentaltrails/existing-patient-preview" element={ <ExistingPatientPreview /> } />
            
            <Route path="/templedentaltrails/new-patient" element={ <ProtectedRoute> <NewPatientForm_templedentaltrails /> </ProtectedRoute> } />
            <Route path="/templedentaltrails/new-patient-preview" element={ <NewPatientFormPreview_templedentaltrails /> } />
            <Route path="/templedentaltrails/new-patient-minor" element={ <ProtectedRoute> <NewPatientMinorForm_templedentaltrails /> </ProtectedRoute> } />
            <Route path="/templedentaltrails/new-patient-minor-preview" element={ <NewPatientMinorFormPreview_templedentaltrails /> } />
            <Route path="/templedentaltrails/covid-19-questionnaire" element={ <ProtectedRoute> <Covid19QuestionForm_templedentaltrails /> </ProtectedRoute> } />
            <Route path="/templedentaltrails/covid-19-questionnaire-preview" element={ <Covid19QuestionFormPreview_templedentaltrails /> } />
            <Route path="/templedentaltrails/contact-details" element={ <ProtectedRoute> <ContactDetailsForm_templedentaltrails /> </ProtectedRoute> } />
            <Route path="/templedentaltrails/contact-details-preview" element={ <ContactDetailsFormPreview_templedentaltrails /> } />
            <Route path="/templedentaltrails/update-profile-photo" element={ <ProtectedRoute> <UpdateProfilePhoto_templedentaltrails /> </ProtectedRoute> } />
            <Route path="/templedentaltrails/update-profile-photo-preview" element={ <UpdateProfilePhotoPreview_templedentaltrails /> } />
            <Route path="/templedentaltrails/health-history" element={ <ProtectedRoute> <HealthHistory_templedentaltrails /> </ProtectedRoute> } />
            <Route path="/templedentaltrails/health-history-preview" element={ <HealthHistoryPreview_templedentaltrails /> } />
            <Route path="/templedentaltrails/health-history-minor" element={ <ProtectedRoute> <HealthHistoryMinor_templedentaltrails /> </ProtectedRoute> } />
            <Route path="/templedentaltrails/health-history-minor-preview" element={ <HealthHistoryMinorPreview_templedentaltrails /> } />
            <Route path="/templedentaltrails/dental-history" element={ <ProtectedRoute> <DentalHistory_templedentaltrails /> </ProtectedRoute> } />
            <Route path="/templedentaltrails/dental-history-preview" element={ <DentalHistoryPreview_templedentaltrails /> } />
            <Route path="/templedentaltrails/dental-history-minor" element={ <ProtectedRoute> <DentalHistoryMinor_templedentaltrails /> </ProtectedRoute> } />
            <Route path="/templedentaltrails/dental-history-minor-preview" element={ <DentalHistoryMinorPreview_templedentaltrails /> } />
            <Route path="/templedentaltrails/dental-insurance" element={ <ProtectedRoute> <DentalInsurance_templedentaltrails /> </ProtectedRoute> } />
            <Route path="/templedentaltrails/dental-insurance-preview" element={ <DentalInsurancePreview_templedentaltrails /> } />
            <Route path="/templedentaltrails/request-picture" element={ <ProtectedRoute> <RequestPicture_templedentaltrails /> </ProtectedRoute> } />
            <Route path="/templedentaltrails/request-picture-preview" element={ <RequestPicturePreview_templedentaltrails /> } />
            <Route path="/templedentaltrails/licence-update" element={ <ProtectedRoute> <LicenceUpdate_templedentaltrails /> </ProtectedRoute> } />
            <Route path="/templedentaltrails/licence-update-preview" element={ <LicenceUpdatePreview_templedentaltrails /> } />
            <Route path="/templedentaltrails/medical-insurance" element={ <ProtectedRoute> <MedicalInsurance_templedentaltrails /> </ProtectedRoute> } />
            <Route path="/templedentaltrails/medical-insurance-preview" element={ <MedicalInsurancePreview_templedentaltrails /> } />
            <Route path="/templedentaltrails/release-of-records" element={ <ProtectedRoute> <ReloaseOfRecords_templedentaltrails /> </ProtectedRoute> } />
            <Route path="/templedentaltrails/release-of-records-preview" element={ <ReloaseOfRecordsPreview_templedentaltrails /> } />
            <Route path="/templedentaltrails/release-of-records-minor" element={ <ProtectedRoute> <ReloaseOfRecordsMinor_templedentaltrails /> </ProtectedRoute> } />
            <Route path="/templedentaltrails/release-of-records-minor-preview" element={ <ReloaseOfRecordsMinorPreview_templedentaltrails /> } />
            <Route path="/templedentaltrails/existing-patient" element={ <ProtectedRoute> <ExistingPatient_templedentaltrails /> </ProtectedRoute> } />
            <Route path="/templedentaltrails/existing-patient-preview" element={ <ExistingPatientPreview_templedentaltrails /> } />
            <Route path="/templedentaltrails/post-appointment-survey" element={ <ProtectedRoute> <PostAppointmentSurvey_templedentaltrails /> </ProtectedRoute> } />
            <Route path="/templedentaltrails/post-appointment-survey-preview" element={ <PostAppointmentSurveyPreview_templedentaltrails /> } />
            
            <Route path="/riverheartfamily/new-patient" element={ <ProtectedRoute> <NewPatientForm_riverheartfamily /> </ProtectedRoute> } />
            <Route path="/riverheartfamily/new-patient-preview" element={ <NewPatientFormPreview_riverheartfamily /> } />
            <Route path="/riverheartfamily/new-patient-minor" element={ <ProtectedRoute> <NewPatientMinorForm_riverheartfamily /> </ProtectedRoute> } />
            <Route path="/riverheartfamily/new-patient-minor-preview" element={ <NewPatientMinorFormPreview_riverheartfamily /> } />
            <Route path="/riverheartfamily/covid-19-consent" element={ <ProtectedRoute> <Covid19Consent_riverheartfamily /> </ProtectedRoute> } />
            <Route path="/riverheartfamily/covid-19-consent-preview" element={ <Covid19ConsentPreview_riverheartfamily /> } />
            <Route path="/riverheartfamily/contact-details" element={ <ProtectedRoute> <ContactDetailsForm_riverheartfamily /> </ProtectedRoute> } />
            <Route path="/riverheartfamily/contact-details-preview" element={ <ContactDetailsFormPreview_riverheartfamily /> } />
            <Route path="/riverheartfamily/update-profile-photo" element={ <ProtectedRoute> <UpdateProfilePhoto_riverheartfamily /> </ProtectedRoute> } />
            <Route path="/riverheartfamily/update-profile-photo-preview" element={ <UpdateProfilePhotoPreview_riverheartfamily /> } />
            <Route path="/riverheartfamily/health-history" element={ <ProtectedRoute> <HealthHistory_riverheartfamily /> </ProtectedRoute> } />
            <Route path="/riverheartfamily/health-history-preview" element={ <HealthHistoryPreview_riverheartfamily /> } />
            <Route path="/riverheartfamily/health-history-minor" element={ <ProtectedRoute> <HealthHistoryMinor_riverheartfamily /> </ProtectedRoute> } />
            <Route path="/riverheartfamily/health-history-minor-preview" element={ <HealthHistoryMinorPreview_riverheartfamily /> } />
            <Route path="/riverheartfamily/dental-history" element={ <ProtectedRoute> <DentalHistory_riverheartfamily /> </ProtectedRoute> } />
            <Route path="/riverheartfamily/dental-history-preview" element={ <DentalHistoryPreview_riverheartfamily /> } />
            <Route path="/riverheartfamily/dental-history-minor" element={ <ProtectedRoute> <DentalHistoryMinor_riverheartfamily /> </ProtectedRoute> } />
            <Route path="/riverheartfamily/dental-history-minor-preview" element={ <DentalHistoryMinorPreview_riverheartfamily /> } />
            <Route path="/riverheartfamily/dental-insurance" element={ <ProtectedRoute> <DentalInsurance_riverheartfamily /> </ProtectedRoute> } />
            <Route path="/riverheartfamily/dental-insurance-preview" element={ <DentalInsurancePreview_riverheartfamily /> } />
            <Route path="/riverheartfamily/request-picture" element={ <ProtectedRoute> <RequestPicture_riverheartfamily /> </ProtectedRoute> } />
            <Route path="/riverheartfamily/request-picture-preview" element={ <RequestPicturePreview_riverheartfamily /> } />
            <Route path="/riverheartfamily/licence-update" element={ <ProtectedRoute> <LicenceUpdate_riverheartfamily /> </ProtectedRoute> } />
            <Route path="/riverheartfamily/licence-update-preview" element={ <LicenceUpdatePreview_riverheartfamily /> } />
            <Route path="/riverheartfamily/release-of-records" element={ <ProtectedRoute> <ReloaseOfRecords_riverheartfamily /> </ProtectedRoute> } />
            <Route path="/riverheartfamily/release-of-records-preview" element={ <ReloaseOfRecordsPreview_riverheartfamily /> } />
            <Route path="/riverheartfamily/release-of-records-minor" element={ <ProtectedRoute> <ReloaseOfRecordsMinor_riverheartfamily /> </ProtectedRoute> } />
            <Route path="/riverheartfamily/release-of-records-minor-preview" element={ <ReloaseOfRecordsMinorPreview_riverheartfamily /> } />
            <Route path="/riverheartfamily/existing-patient" element={ <ProtectedRoute> <ExistingPatient_riverheartfamily /> </ProtectedRoute> } />
            <Route path="/riverheartfamily/existing-patient-preview" element={ <ExistingPatientPreview_riverheartfamily /> } />
            <Route path="/riverheartfamily/orthodontic-examination" element={ <ProtectedRoute> <OrthodonticExamination_riverheartfamily /> </ProtectedRoute> } />
            <Route path="/riverheartfamily/orthodontic-examination-preview" element={ <OrthodonticExaminationPreview_riverheartfamily /> } />
          </Routes>
        </PersistGate>
      </Provider>
      <Toast />
    </>
  );
}

export default App;
