import React, { useState, useEffect } from 'react'
import { Radio, Button, Checkbox, RadioGroup, FormHelperText } from '@mui/joy'
import { ArrowForward, ArrowBack } from '@mui/icons-material'
import { useFormik } from 'formik'
import * as yup from 'yup'
import IFormProps from 'component/Form/IFormProps.ts'
import EventBus from '../../../../../bus.ts'
import { TextField, Select, MenuItem, FormControl, ListItemText } from '@mui/material'

export default (props: IFormProps) => {

  const OTHER_MEDICAL_ISSUES = ['Anaphylaxis', 'Cold sores', 'HPV', 'Hepatitis', 'Other']
  const DIGESTIVE_ISSUES = ['Gastroesophageal reflux disease', 'Other']
  const HEART_ISSUES = ['Heart Arrhythmias', 'Heart Failure', 'Heart Attack', 'Stroke', 'High Blood Pressure', 'Artificial Heart Valve' ,'Other']
  const NEUROLOGICAL_ISSUES = ['Epilepsy', 'Seizures', 'Stroke', 'Migraines/severe headaches', "Parkinson's Disease", "Alzheimer's or Dementia" ,'Other']
  const BREATHING_ISSUES = ['Asthama', 'Chronic obstructive pulmonary disease (COPD)', 'Tuberculosis', 'Sleep Apnea' ,'Other']
  const AUTOIMMUNE_ISSUES = ['Arthritis', 'Systemic lupus erythematosus', 'Diabetes' ,'Other']

  const validationSchema = () => {
    if(!props.validation) return yup.object({})
    const obj = {}
    if(form1?.values.digestive == 'true') obj['digestive_options'] = yup.array().of(yup.string()).min(1, 'Required')
    if(form1?.values.heart == 'true') obj['heart_options'] = yup.array().of(yup.string()).min(1, 'Required')
    if(form1?.values.neurological == 'true') obj['neurological_options'] = yup.array().of(yup.string()).min(1, 'Required')
    if(form1?.values.breathing == 'true') obj['breathing_options'] = yup.array().of(yup.string()).min(1, 'Required')
    if(form1?.values.autoimmune == 'true') obj['autoimmune_options'] = yup.array().of(yup.string()).min(1, 'Required')
    if(form1?.values.other_medical_issue == 'true') obj['other_medical_issue_options'] = yup.array().of(yup.string()).min(1, 'Required')
    return yup.object(obj)
  }

  const form1 = useFormik({
    initialValues: props.formData['step_' + props.step],
    validationSchema: validationSchema,
    onSubmit: (values) => {
      try{
        props.setFormData({ ...props.formData, ['step_' + props.step]: values })
        props.nextStep()
      }catch(e){
        console.log(e)
      }
    },
  });

  useEffect(() => {
    EventBus.$on('submit-next-' + props.slug + '-' + props.step, form1.handleSubmit)
  }, [])
  
  useEffect(() => {
    Object.keys(props.formData['step_' + props.step]).forEach(key => form1?.setFieldValue(key, props.formData['step_' + props.step][key]))
  }, [ props.formData['step_' + props.step] ])

  return (
    <form onSubmit={form1.handleSubmit} className={'flex flex-col gap-4 ' + props.className}>
      <p className='mb-0 text-[1rem] text-[#000] text-center font-bold'>Please check all conditions that you have history of or are currently being treated for</p>
      <FormControl error={form1.touched.digestive && Boolean(form1.errors.digestive)}>
        <div className="flex gap-4 justify-between">
          <div>
            <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Do you have a history or are currently being treated for any Digestive conditions?</p>
            <p className='mb-0 text-[0.7rem] text-[#000]/[0.6]'>Gastroesophageal reflux disease, Other...</p>
          </div>
          <RadioGroup value={ form1.values.digestive } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='digestive' orientation='horizontal'>
            <Radio label='Yes' value='true' />
            <Radio label='No' value='false' />
          </RadioGroup>
        </div>
        {form1.touched.digestive && Boolean(form1.errors.digestive) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>

      { form1.values.digestive == 'true' &&
      <FormControl error={form1.touched.digestive_options && Boolean(form1.errors.digestive_options)} variant='standard'>
        <Select name="digestive_options" multiple value={form1.values.digestive_options} renderValue={(selected) => selected.join(', ')}
          onChange={e => {
            const { target: { value } } = e
            form1.setFieldValue('digestive_options', typeof value === 'string' ? value.split(',') : value, true)
          }}
        >
          {DIGESTIVE_ISSUES.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={form1.values.digestive_options.indexOf(name) > -1} /> &nbsp;
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
        {form1.touched.digestive_options && Boolean(form1.errors.digestive_options) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>}
      {form1.values.digestive_options?.indexOf('Other') > -1 &&
      <TextField
        label="Please provide details here" variant="standard" name='digestive_option_reason' fullWidth
        value={ form1.values.digestive_option_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}

      <FormControl error={form1.touched.heart && Boolean(form1.errors.heart)}>
        <div className="flex gap-4 justify-between">
          <div>
            <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Do you have a history or are currently being treated for any Heart or Circulatory conditions?</p>
            <p className='mb-0 text-[0.7rem] text-[#000]/[0.6]'>Heart Arrhythmias, Heart Failure, Heart Attack, Stroke, High Blood Pressure, Artificial Heart Valve, Other...</p>
          </div>
          <RadioGroup value={ form1.values.heart } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='heart' orientation='horizontal'>
            <Radio label='Yes' value='true' />
            <Radio label='No' value='false' />
          </RadioGroup>
        </div>
        {form1.touched.heart && Boolean(form1.errors.heart) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>

      { form1.values.heart == 'true' &&
      <FormControl error={form1.touched.heart_options && Boolean(form1.errors.heart_options)} variant='standard'>
        <Select name="heart_options" multiple value={form1.values.heart_options} renderValue={(selected) => selected.join(', ')}
          onChange={e => {
            const { target: { value } } = e
            form1.setFieldValue('heart_options', typeof value === 'string' ? value.split(',') : value, true)
          }}
        >
          {HEART_ISSUES.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={form1.values.heart_options.indexOf(name) > -1} /> &nbsp;
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
        {form1.touched.heart_options && Boolean(form1.errors.heart_options) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>}
      {form1.values.heart_options?.indexOf('Other') > -1 &&
      <TextField
        label="Please provide details here" variant="standard" name='heart_option_reason' fullWidth
        value={ form1.values.heart_option_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}

      <FormControl error={form1.touched.neurological && Boolean(form1.errors.neurological)}>
        <div className="flex gap-4 justify-between">
          <div>
            <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Do you have a history or are currently being treated for any Neurological conditions?</p>
            <p className='mb-0 text-[0.7rem] text-[#000]/[0.6]'>Epilepsy, Seizures, Stroke, Migraines/severe headaches, Parkinson's Disease, Alzheimer's or Dementia, Other...</p>
          </div>
          <RadioGroup value={ form1.values.neurological } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='neurological' orientation='horizontal'>
            <Radio label='Yes' value='true' />
            <Radio label='No' value='false' />
          </RadioGroup>
        </div>
        {form1.touched.neurological && Boolean(form1.errors.neurological) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>

      { form1.values.neurological == 'true' &&
      <FormControl error={form1.touched.neurological_options && Boolean(form1.errors.neurological_options)} variant='standard'>
        <Select name="neurological_options" multiple value={form1.values.neurological_options} renderValue={(selected) => selected.join(', ')}
          onChange={e => {
            const { target: { value } } = e
            form1.setFieldValue('neurological_options', typeof value === 'string' ? value.split(',') : value, true)
          }}
        >
          {NEUROLOGICAL_ISSUES.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={form1.values.neurological_options.indexOf(name) > -1} /> &nbsp;
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
        {form1.touched.neurological_options && Boolean(form1.errors.neurological_options) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>}
      {form1.values.neurological_options?.indexOf('Other') > -1 &&
      <TextField
        label="Please provide details here" variant="standard" name='neurological_option_reason' fullWidth
        value={ form1.values.neurological_option_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}

      <FormControl error={form1.touched.breathing && Boolean(form1.errors.breathing)}>
        <div className="flex gap-4 justify-between">
          <div>
            <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Do you have a history or are currently being treated for any Lung or Breathing conditions?</p>
            <p className='mb-0 text-[0.7rem] text-[#000]/[0.6]'>Asthma, Chronic obstructive pulmonary disease (COPD), Tuberculosis, Sleep Apnea, Other...</p>
          </div>
          <RadioGroup value={ form1.values.breathing } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='breathing' orientation='horizontal'>
            <Radio label='Yes' value='true' />
            <Radio label='No' value='false' />
          </RadioGroup>
        </div>
        {form1.touched.breathing && Boolean(form1.errors.breathing) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>

      { form1.values.breathing == 'true' &&
      <FormControl error={form1.touched.breathing_options && Boolean(form1.errors.breathing_options)} variant='standard'>
        <Select name="breathing_options" multiple value={form1.values.breathing_options} renderValue={(selected) => selected.join(', ')}
          onChange={e => {
            const { target: { value } } = e
            form1.setFieldValue('breathing_options', typeof value === 'string' ? value.split(',') : value, true)
          }}
        >
          {BREATHING_ISSUES.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={form1.values.breathing_options.indexOf(name) > -1} /> &nbsp;
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
        {form1.touched.breathing_options && Boolean(form1.errors.breathing_options) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>}
      {form1.values.breathing_options?.indexOf('Other') > -1 &&
      <TextField
        label="Please provide details here" variant="standard" name='breathing_option_reason' fullWidth
        value={ form1.values.breathing_option_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}

      <FormControl error={form1.touched.autoimmune && Boolean(form1.errors.autoimmune)}>
        <div className="flex gap-4 justify-between">
          <div>
            <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Do you have a history or are currently being treated for any Autoimmune conditions?</p>
            <p className='mb-0 text-[0.7rem] text-[#000]/[0.6]'>Arthritis, Systemic lupus erythematosus, Diabetes, Other...</p>
          </div>
          <RadioGroup value={ form1.values.autoimmune } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='autoimmune' orientation='horizontal'>
            <Radio label='Yes' value='true' />
            <Radio label='No' value='false' />
          </RadioGroup>
        </div>
        {form1.touched.autoimmune && Boolean(form1.errors.autoimmune) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>

      { form1.values.autoimmune == 'true' &&
      <FormControl error={form1.touched.autoimmune_options && Boolean(form1.errors.autoimmune_options)} variant='standard'>
        <Select name="autoimmune_options" multiple value={form1.values.autoimmune_options} renderValue={(selected) => selected.join(', ')}
          onChange={e => {
            const { target: { value } } = e
            form1.setFieldValue('autoimmune_options', typeof value === 'string' ? value.split(',') : value, true)
          }}
        >
          {AUTOIMMUNE_ISSUES.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={form1.values.autoimmune_options.indexOf(name) > -1} /> &nbsp;
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
        {form1.touched.autoimmune_options && Boolean(form1.errors.autoimmune_options) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>}
      {form1.values.autoimmune_options?.indexOf('Other') > -1 &&
      <TextField
        label="Please provide details here" variant="standard" name='autoimmune_option_reason' fullWidth
        value={ form1.values.autoimmune_option_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}

      <FormControl error={form1.touched.neck_injury && Boolean(form1.errors.neck_injury)}>
        <div className="flex gap-4 justify-between">
          <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Head or neck injuries?</p>
          <RadioGroup value={ form1.values.neck_injury } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='neck_injury' orientation='horizontal'>
            <Radio label='Yes' value='true' />
            <Radio label='No' value='false' />
          </RadioGroup>
        </div>
        {form1.touched.neck_injury && Boolean(form1.errors.neck_injury) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      {form1.values.neck_injury == 'true' &&
      <TextField
        label="Please provide details here" variant="standard" name='neck_injury_reason' fullWidth
        value={ form1.values.neck_injury_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}
      <FormControl error={form1.touched.artificial_joint && Boolean(form1.errors.artificial_joint)}>
        <div className="flex gap-4 justify-between">
          <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Artificial Joint?</p>
          <RadioGroup value={ form1.values.artificial_joint } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='artificial_joint' orientation='horizontal'>
            <Radio label='Yes' value='true' />
            <Radio label='No' value='false' />
          </RadioGroup>
        </div>
        {form1.touched.artificial_joint && Boolean(form1.errors.artificial_joint) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      {form1.values.artificial_joint == 'true' &&
      <TextField
        label="Please provide details here" variant="standard" name='artificial_joint_reason' fullWidth
        value={ form1.values.artificial_joint_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}
      <FormControl error={form1.touched.cancer && Boolean(form1.errors.cancer)}>
        <div className="flex gap-4 justify-between">
          <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>History of cancer?</p>
          <RadioGroup value={ form1.values.cancer } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='cancer' orientation='horizontal'>
            <Radio label='Yes' value='true' />
            <Radio label='No' value='false' />
          </RadioGroup>
        </div>
        {form1.touched.cancer && Boolean(form1.errors.cancer) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      {form1.values.cancer == 'true' &&
      <TextField
        label="Please provide details here" variant="standard" name='cancer_reason' fullWidth
        value={ form1.values.cancer_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}
      <FormControl error={form1.touched.abnormal_growth && Boolean(form1.errors.abnormal_growth)}>
        <div className="flex gap-4 justify-between">
          <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Tumor or abnormal growth?</p>
          <RadioGroup value={ form1.values.abnormal_growth } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='abnormal_growth' orientation='horizontal'>
            <Radio label='Yes' value='true' />
            <Radio label='No' value='false' />
          </RadioGroup>
        </div>
        {form1.touched.abnormal_growth && Boolean(form1.errors.abnormal_growth) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      {form1.values.abnormal_growth == 'true' &&
      <TextField
        label="Please provide details here" variant="standard" name='abnormal_growth_reason' fullWidth
        value={ form1.values.abnormal_growth_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}
      <FormControl error={form1.touched.radiation_therapy && Boolean(form1.errors.radiation_therapy)}>
        <div className="flex gap-4 justify-between">
          <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Radiation therapy?</p>
          <RadioGroup value={ form1.values.radiation_therapy } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='radiation_therapy' orientation='horizontal'>
            <Radio label='Yes' value='true' />
            <Radio label='No' value='false' />
          </RadioGroup>
        </div>
        {form1.touched.radiation_therapy && Boolean(form1.errors.radiation_therapy) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      {form1.values.radiation_therapy == 'true' &&
      <TextField
        label="Please provide details here" variant="standard" name='radiation_therapy_reason' fullWidth
        value={ form1.values.radiation_therapy_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}
      <FormControl error={form1.touched.chemotherapy && Boolean(form1.errors.chemotherapy)}>
        <div className="flex gap-4 justify-between">
          <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Chemotherapy?</p>
          <RadioGroup value={ form1.values.chemotherapy } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='chemotherapy' orientation='horizontal'>
            <Radio label='Yes' value='true' />
            <Radio label='No' value='false' />
          </RadioGroup>
        </div>
        {form1.touched.chemotherapy && Boolean(form1.errors.chemotherapy) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      {form1.values.chemotherapy == 'true' &&
      <TextField
        label="Please provide details here" variant="standard" name='chemotherapy_reason' fullWidth
        value={ form1.values.chemotherapy_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}
      <FormControl error={form1.touched.hiv && Boolean(form1.errors.hiv)}>
        <div className="flex gap-4 justify-between">
          <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>HIV / AIDS?</p>
          <RadioGroup value={ form1.values.hiv } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='hiv' orientation='horizontal'>
            <Radio label='Yes' value='true' />
            <Radio label='No' value='false' />
          </RadioGroup>
        </div>
        {form1.touched.hiv && Boolean(form1.errors.hiv) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      {form1.values.hiv == 'true' &&
      <TextField
        label="Please provide details here" variant="standard" name='hiv_reason' fullWidth
        value={ form1.values.hiv_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}
      <FormControl error={form1.touched.osteoporosis && Boolean(form1.errors.osteoporosis)}>
        <div className="flex gap-4 justify-between">
          <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Osteoporosis / osteopenia?</p>
          <RadioGroup value={ form1.values.osteoporosis } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='osteoporosis' orientation='horizontal'>
            <Radio label='Yes' value='true' />
            <Radio label='No' value='false' />
          </RadioGroup>
        </div>
        {form1.touched.osteoporosis && Boolean(form1.errors.osteoporosis) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      {form1.values.osteoporosis == 'true' &&
      <TextField
        label="Please provide details here" variant="standard" name='osteoporosis_reason' fullWidth
        value={ form1.values.osteoporosis_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}
      <FormControl error={form1.touched.diabetes && Boolean(form1.errors.diabetes)}>
        <div className="flex gap-4 justify-between">
          <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Type I or Type II diabetes?</p>
          <RadioGroup value={ form1.values.diabetes } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='diabetes' orientation='horizontal'>
            <Radio label='Yes' value='true' />
            <Radio label='No' value='false' />
          </RadioGroup>
        </div>
        {form1.touched.diabetes && Boolean(form1.errors.diabetes) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      {form1.values.diabetes == 'true' &&
      <TextField
        label="Please provide details here" variant="standard" name='diabetes_reason' fullWidth
        value={ form1.values.diabetes_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}
      <FormControl error={form1.touched.anemia && Boolean(form1.errors.anemia)}>
        <div className="flex gap-4 justify-between">
          <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Anemia?</p>
          <RadioGroup value={ form1.values.anemia } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='anemia' orientation='horizontal'>
            <Radio label='Yes' value='true' />
            <Radio label='No' value='false' />
          </RadioGroup>
        </div>
        {form1.touched.anemia && Boolean(form1.errors.anemia) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      {form1.values.anemia == 'true' &&
      <TextField
        label="Please provide details here" variant="standard" name='anemia_reason' fullWidth
        value={ form1.values.anemia_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}
      <FormControl error={form1.touched.tuberculosis && Boolean(form1.errors.tuberculosis)}>
        <div className="flex gap-4 justify-between">
          <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Tuberculosis?</p>
          <RadioGroup value={ form1.values.tuberculosis } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='tuberculosis' orientation='horizontal'>
            <Radio label='Yes' value='true' />
            <Radio label='No' value='false' />
          </RadioGroup>
        </div>
        {form1.touched.tuberculosis && Boolean(form1.errors.tuberculosis) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      {form1.values.tuberculosis == 'true' &&
      <TextField
        label="Please provide details here" variant="standard" name='tuberculosis_reason' fullWidth
        value={ form1.values.tuberculosis_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}

      <FormControl error={form1.touched.other_medical_issue && Boolean(form1.errors.other_medical_issue)}>
        <div className="flex gap-4 justify-between">
          <div>
            <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Any other medical condition we should know of?</p>
            <p className='mb-0 text-[0.7rem] text-[#000]/[0.6]'>Anaphylaxis, Cold sores, HPV, Hepatitis, Other...</p>
          </div>
          <RadioGroup value={ form1.values.other_medical_issue } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='other_medical_issue' orientation='horizontal'>
            <Radio label='Yes' value='true' />
            <Radio label='No' value='false' />
          </RadioGroup>
        </div>
        {form1.touched.other_medical_issue && Boolean(form1.errors.other_medical_issue) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      
      { form1.values.other_medical_issue == 'true' &&
      <FormControl error={form1.touched.other_medical_issue_options && Boolean(form1.errors.other_medical_issue_options)} variant='standard'>
        <Select name="other_medical_issue_options" multiple value={form1.values.other_medical_issue_options} renderValue={(selected) => selected.join(', ')}
          onChange={e => {
            const { target: { value } } = e
            form1.setFieldValue('other_medical_issue_options', typeof value === 'string' ? value.split(',') : value, true)
          }}
        >
          {OTHER_MEDICAL_ISSUES.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={form1.values.other_medical_issue_options.indexOf(name) > -1} /> &nbsp;
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
        {form1.touched.other_medical_issue_options && Boolean(form1.errors.other_medical_issue_options) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>}
      {form1.values.other_medical_issue_options?.indexOf('Other') > -1 &&
      <TextField
        label="Please provide details here" variant="standard" name='other_medical_issue_option_reason' fullWidth
        value={ form1.values.other_medical_issue_option_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}
      
      <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>* Mandatory fields</p>
      <div className="flex">
        {props.showBack && <Button variant='plain' onClick={props.prevStep}>
          <ArrowBack />
          Back
        </Button>}
        <Button color='primary' type='submit' className='!ml-auto'>
          Next
          <ArrowForward />
        </Button>
      </div>
    </form>
  )
}