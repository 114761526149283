import React, { useState, useEffect } from 'react'
import { Radio, Button, Checkbox, RadioGroup, FormHelperText } from '@mui/joy'
import { ArrowForward, ArrowBack } from '@mui/icons-material'
import { useFormik } from 'formik'
import * as yup from 'yup'
import IFormProps from 'component/Form/IFormProps.ts'
import EventBus from '../../../../../bus.ts'
import { TextField, FormControl, Select, MenuItem, ListItemText } from '@mui/material'

export default (props: IFormProps) => {

  const IMMEDIATE_CONCERN_OPTIONS = [
    'Tooth pain', 'Bad breath', 'More attractive smile', 'Crooked/Crowded teeth', 'Discoloration', 'Missing teeth', 'Other'
  ]
  
  const validationSchema = () => {
    if(!props.validation) return yup.object({})
    const obj = {
      immediate_concern: yup.string().required('Required'),
    }
    if(form1?.values.immediate_concern == 'true') obj['immediate_concern_options'] = yup.array().of(yup.string()).min(1, 'Required')
    return yup.object(obj)
  }

  const form1 = useFormik({
    initialValues: props.formData['step_' + props.step],
    validationSchema: validationSchema,
    onSubmit: (values) => {
      try{
        props.setFormData({ ...props.formData, ['step_' + props.step]: values })
        props.nextStep()
      }catch(e){
        console.log(e)
      }
    },
  });

  useEffect(() => {
    EventBus.$on('submit-next-' + props.slug + '-' + props.step, form1.handleSubmit)
  }, [])
  
  useEffect(() => {
    Object.keys(props.formData['step_' + props.step]).forEach(key => form1?.setFieldValue(key, props.formData['step_' + props.step][key]))
  }, [ props.formData['step_' + props.step] ])

  return (
    <form onSubmit={form1.handleSubmit} className={'flex flex-col gap-4 ' + props.className}>
      <FormControl error={form1.touched.value_most_in_visit && Boolean(form1.errors.value_most_in_visit)}>
        <TextField
          label="What do you value most in your dental visits?" variant="standard" name='value_most_in_visit' fullWidth
          value={ form1.values.value_most_in_visit } onChange={ form1.handleChange } onBlur={form1.handleBlur}
        />
        {form1.touched.value_most_in_visit && Boolean(form1.errors.value_most_in_visit) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      <FormControl error={form1.touched.prefer_anything && Boolean(form1.errors.prefer_anything)}>
        <TextField
          label="Is there anything you prefer during your visits to make you more comfortable during your time with us?" variant="standard" name='prefer_anything' fullWidth
          value={ form1.values.prefer_anything } onChange={ form1.handleChange } onBlur={form1.handleBlur}
        />
        {form1.touched.prefer_anything && Boolean(form1.errors.prefer_anything) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      <FormControl error={form1.touched.dental_treatment_scaling && Boolean(form1.errors.dental_treatment_scaling)} variant='standard'>
        <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>On a scale from 1-5, 5 being most terrified, are you fearful of dental treatment?*</p>
        <Select name="dental_treatment_scaling" value={form1.values.dental_treatment_scaling}
          onChange={e => {
            const { target: { value } } = e
            form1.setFieldValue('dental_treatment_scaling', value, true)
          }}
        >
          {['1','2','3','4','5'].map((name) => (
            <MenuItem key={name} value={name}>
              {/* <Checkbox checked={form1.values.dental_treatment_scaling.indexOf(name) > -1} /> &nbsp; */}
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
        {form1.touched.dental_treatment_scaling && Boolean(form1.errors.dental_treatment_scaling) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>

      <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>* Mandatory fields</p>
      <div className="flex">
        {props.showBack && <Button variant='plain' onClick={props.prevStep}>
          <ArrowBack />
          Back
        </Button>}
        <Button color='primary' type='submit' className='!ml-auto'>
          Next
          <ArrowForward />
        </Button>
      </div>
    </form>
  )
}