import thunk from 'redux-thunk';
import { createStore, applyMiddleware } from "redux";
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { composeWithDevTools } from 'redux-devtools-extension';
import { authReducer } from "./reducers/authReducer";

let middleware = [];

middleware = [thunk];

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['formName', 'patient']
}
const persistedReducer = persistReducer(persistConfig, authReducer);

const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(...middleware)));

export const persistor = persistStore(store);
export default store;
