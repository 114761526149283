import { Card } from '@mui/material'
import React, { useEffect, useState } from 'react'
import FormButtons from '../../FormFabs.tsx'
import GeneralHealthInformation from './steps/GeneralHealthInformation.tsx'
import MedicalCondition from './steps/MedicalCondition.tsx'
import Signature from './steps/Signature.tsx'
import Medication from './steps/Medication.tsx'
import Header from '../../Header.tsx'
import { useSelector } from 'react-redux'
import axios from 'axios'

export default () => {
  const SLUG = 'health-history'
  const MAX_STEPS = 4
  const [ step, setStep ] = useState(1)
  const [ validation, setValidation ] = useState(false)
  const [ submitting, setSubmitting ] = useState(false)
  const [ fetching, setFetching ] = useState(true)
  const { patient, formName } = useSelector((state) => state)
  const [ patientForm, setPatientForm ] = useState({})
  
  const nextStep = () => {
    if(step < MAX_STEPS) setStep(step + 1)
    else submitForm()
  }
  const prevStep = () => {
    if(step > 1) setStep(step - 1)
  }

  const [ formData, setFormData ] = useState({
    step_1: {
      under_care_of_physician: '',
      name_of_current_physician: '',
      physician_phone_no: '',
      date_of_last_physical_exam: '',
      preferred_pharmacy: '',
      injury_of_illness_treatment: '',
      injury_of_illness_treatment_reason: '',
      injury_of_illness_hospitalized: '',
      injury_of_illness_hospitalized_reason: '',
      antibiotic_before_dental_tratment: '',
      antibiotic_before_dental_tratment_reason: '',
      use_tobacco: '',
      use_tobacco_reason: '',
      alergic_reaction: '',
      alergic_reaction_options: [],
      alergic_reaction_option_other_reason: '',
      pregnant: '',
      pregnant_reason: '',
      breastfeeding: '',
      breastfeeding_reason: '',
    },
    step_2: {
      digestive: '',
      digestive_options: [],
      digestive_option_other_reason: '',
      heart: '',
      heart_options: [],
      heart_option_other_reason: '',
      neurological: '',
      neurological_options: [],
      neurological_option_other_reason: '',
      breathing: '',
      breathing_options: [],
      breathing_option_other_reason: '',
      autoimmune: '',
      autoimmune_options: [],
      autoimmune_option_other_reason: '',
      neck_injury: '',
      artificial_joint: '',
      cancer: '',
      abnormal_growth: '',
      radiation_therapy: '',
      chemotherapy: '',
      hiv: '',
      osteoporosis: '',
      diabetes: '',
      anemia: '',
      tuberculosis: '',
      other_medical_issue: '',
      other_medical_issue_options: [],
      other_medical_issue_option_reason: '',
    },
    step_3: {
      pain_medication: '',
      pain_medication_options: '',
      pain_medication_option_reason: '',
      anxiety_medication: '',
      anxiety_medication_options: '',
      anxiety_medication_option_reason: '',
      diabetes_medication: '',
      diabetes_medication_options: '',
      diabetes_medication_option_reason: '',
      asthma_medication: '',
      asthma_medication_options: '',
      asthma_medication_option_reason: '',
      antibiotics: '',
      antibiotics_options: '',
      antibiotics_option_reason: '',
      has_other_medication: '',
      other_medication: '',
    },
    step_4: {
      sign_as: '',
      signature: '',
      guardian_full_name: '',
      guardian_dob: '',
      guardian_address: '',
      guardian_city: '',
      guardian_state: '',
      guardian_zip: '',
    },
  })

  const submitForm = async () => {
    if(submitting) return
    setSubmitting(true)
    try{
      const payload = {
        form_activation_id: patient?.form_activation_id,
        patient_id: patient?.patient?.id,
        form_name: formName,
        form_data: formData
      };
      const { data } = patientForm?.id ? await axios.put(`${process.env.REACT_APP_CARESUITE_APP_URL}/public/api/v1/patient_forms/${patientForm?.id}`, payload) : await axios.post(`${process.env.REACT_APP_CARESUITE_APP_URL}/public/api/v1/patient_forms`, payload);
      setPatientForm(data)
    }catch(e){
      console.log(e)
    }
    setSubmitting(false)
  }

  const fetchData = async () => {
    console.log(patient)
    const { data } = await axios.get(`${process.env.REACT_APP_CARESUITE_APP_URL}/public/api/v1/patient_forms/show_by_form_name`,{
      params: { form_activation_id: patient?.form_activation_id }
    });
    if(data){
      setPatientForm(data)
      setFormData(data.form_data)
    }
    setFetching(false)
  }
  
  useEffect(() => { fetchData() }, []);
  return (
    <>
      <Header title='Health History' />
      <div className='max-w-[1000px] mx-auto py-12'>
        <div className="flex gap-4 flex-wrap justify-between items-center">
          <p className="text-[#1d3243] font-bold text-[20px]">
            {
            step == 1 ? <span>General Health Information</span>
            :step == 2 ? <span>Medical Conditions</span>
            :step == 3 ? <span>Address</span>
            :step == 4 ? <span>Emergency Contact Information</span>
            :step == 5 ? <span>Work Information</span>
            :<span></span>
            }
          </p>
          <p className="text-[#1d3243] font-bold text-[16px]">Step {step}/{MAX_STEPS}</p>
        </div>
        <Card className='p-8 flex flex-col gap-4' elevation={3}>
          <GeneralHealthInformation className={`${step == 1 ? 'flex' : 'hidden'}`} step={1} prevStep={prevStep} nextStep={nextStep} validation={validation} slug={SLUG} formData={formData} setFormData={setFormData} />
          <MedicalCondition className={`${step == 2 ? 'flex' : 'hidden'}`} showBack step={2} prevStep={prevStep} nextStep={nextStep} validation={validation} slug={SLUG} formData={formData} setFormData={setFormData} />
          <Medication className={`${step == 3 ? 'flex' : 'hidden'}`} showBack step={3} prevStep={prevStep} nextStep={nextStep} validation={validation} slug={SLUG} formData={formData} setFormData={setFormData} />
          <Signature className={`${step == 4 ? 'flex' : 'hidden'}`} showBack step={4} prevStep={prevStep} nextStep={nextStep} validation={validation} slug={SLUG} formData={formData} setFormData={setFormData} />
          {!validation && <p className='mb-0 text-[1rem] text-[#ff6e19] text-center'>PREVIEW MODE - form validation disabled!</p>}
        </Card>
      </div>
      <FormButtons prevStep={prevStep} nextStep={nextStep} step={step} validation={validation} setValidation={setValidation} slug={SLUG} maxStep={MAX_STEPS} />
    </>
  )
}