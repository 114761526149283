import React, { useState, useEffect } from 'react'
import { Radio, Button, Checkbox, RadioGroup, FormHelperText } from '@mui/joy'
import { ArrowForward, ArrowBack } from '@mui/icons-material'
import { useFormik } from 'formik'
import * as yup from 'yup'
import IFormProps from 'component/Form/IFormProps.ts'
import EventBus from '../../../../../bus.ts'
import { TextField, Select, MenuItem, FormControl, ListItemText } from '@mui/material'

export default (props: IFormProps) => {

  const validationSchema = () => {
    if(!props.validation) return yup.object({})
    const obj = {
      concerned_in_appearance_of_teeth: yup.string().required('Required'),
      interested_in_improving_smile: yup.string().required('Required'),
      cavities_in_2_yrs: yup.string().required('Required'),
      sensitive_teeth: yup.string().required('Required'),
      difficulty_in_biting: yup.string().required('Required'),
      problems_in_sleep: yup.string().required('Required'),
      clench_in_day: yup.string().required('Required'),
      wear_bite_appliance: yup.string().required('Required'),
      bite_nails: yup.string().required('Required'),
      low_silva: yup.string().required('Required'),
      unpleasant_taste: yup.string().required('Required'),
    }
    return yup.object(obj)
  }

  const form1 = useFormik({
    initialValues: props.formData['step_' + props.step],
    validationSchema: validationSchema,
    onSubmit: (values) => {
      try{
        props.setFormData({ ...props.formData, ['step_' + props.step]: values })
        props.nextStep()
      }catch(e){
        console.log(e)
      }
    },
  });

  useEffect(() => {
    EventBus.$on('submit-next-' + props.slug + '-' + props.step, form1.handleSubmit)
  }, [])
  
  useEffect(() => {
    Object.keys(props.formData['step_' + props.step]).forEach(key => form1?.setFieldValue(key, props.formData['step_' + props.step][key]))
  }, [ props.formData['step_' + props.step] ])

  return (
    <form onSubmit={form1.handleSubmit} className={'flex flex-col gap-4 ' + props.className}>
      <p className='mb-0 text-[1rem] text-[#000] text-center font-bold'>Please answer the following questions</p>
      
      <FormControl error={form1.touched.concerned_in_appearance_of_teeth && Boolean(form1.errors.concerned_in_appearance_of_teeth)}>
        <div className="flex gap-4 justify-between">
          <div>
            <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Are you concerned about the appearance of your teeth?</p>
          </div>
          <RadioGroup value={ form1.values.concerned_in_appearance_of_teeth } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='concerned_in_appearance_of_teeth' orientation='horizontal'>
            <Radio label='Yes' value='true' />
            <Radio label='No' value='false' />
          </RadioGroup>
        </div>
        {form1.touched.concerned_in_appearance_of_teeth && Boolean(form1.errors.concerned_in_appearance_of_teeth) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      {form1.values.concerned_in_appearance_of_teeth == 'true' &&
      <TextField
        label="Please provide details here" variant="standard" name='concerned_in_appearance_of_teeth_reason' fullWidth
        value={ form1.values.concerned_in_appearance_of_teeth_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}
      <FormControl error={form1.touched.interested_in_improving_smile && Boolean(form1.errors.interested_in_improving_smile)}>
        <div className="flex gap-4 justify-between">
          <div>
            <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Are you interested in improving your smile?</p>
          </div>
          <RadioGroup value={ form1.values.interested_in_improving_smile } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='interested_in_improving_smile' orientation='horizontal'>
            <Radio label='Yes' value='true' />
            <Radio label='No' value='false' />
          </RadioGroup>
        </div>
        {form1.touched.interested_in_improving_smile && Boolean(form1.errors.interested_in_improving_smile) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      {form1.values.interested_in_improving_smile == 'true' &&
      <TextField
        label="Please provide details here" variant="standard" name='interested_in_improving_smile_reason' fullWidth
        value={ form1.values.interested_in_improving_smile_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}
      <FormControl error={form1.touched.cavities_in_2_yrs && Boolean(form1.errors.cavities_in_2_yrs)}>
        <div className="flex gap-4 justify-between">
          <div>
            <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Have you had any cavities within the past 2 years?</p>
          </div>
          <RadioGroup value={ form1.values.cavities_in_2_yrs } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='cavities_in_2_yrs' orientation='horizontal'>
            <Radio label='Yes' value='true' />
            <Radio label='No' value='false' />
          </RadioGroup>
        </div>
        {form1.touched.cavities_in_2_yrs && Boolean(form1.errors.cavities_in_2_yrs) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      {form1.values.cavities_in_2_yrs == 'true' &&
      <TextField
        label="Please provide details here" variant="standard" name='cavities_in_2_yrs_reason' fullWidth
        value={ form1.values.cavities_in_2_yrs_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}
      <FormControl error={form1.touched.sensitive_teeth && Boolean(form1.errors.sensitive_teeth)}>
        <div className="flex gap-4 justify-between">
          <div>
            <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Are any teeth currently sensitive to biting, sweets, hot, or cold?</p>
          </div>
          <RadioGroup value={ form1.values.sensitive_teeth } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='sensitive_teeth' orientation='horizontal'>
            <Radio label='Yes' value='true' />
            <Radio label='No' value='false' />
          </RadioGroup>
        </div>
        {form1.touched.sensitive_teeth && Boolean(form1.errors.sensitive_teeth) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      {form1.values.sensitive_teeth == 'true' &&
      <TextField
        label="Please provide details here" variant="standard" name='sensitive_teeth_reason' fullWidth
        value={ form1.values.sensitive_teeth_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}
      <FormControl error={form1.touched.difficulty_in_biting && Boolean(form1.errors.difficulty_in_biting)}>
        <div className="flex gap-4 justify-between">
          <div>
            <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Do you avoid or have difficulty chewing or biting heavily any hard foods?</p>
          </div>
          <RadioGroup value={ form1.values.difficulty_in_biting } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='difficulty_in_biting' orientation='horizontal'>
            <Radio label='Yes' value='true' />
            <Radio label='No' value='false' />
          </RadioGroup>
        </div>
        {form1.touched.difficulty_in_biting && Boolean(form1.errors.difficulty_in_biting) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      {form1.values.difficulty_in_biting == 'true' &&
      <TextField
        label="Please provide details here" variant="standard" name='difficulty_in_biting_reason' fullWidth
        value={ form1.values.difficulty_in_biting_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}
      <FormControl error={form1.touched.problems_in_sleep && Boolean(form1.errors.problems_in_sleep)}>
        <div className="flex gap-4 justify-between">
          <div>
            <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Do you have any problems sleeping, wake up with a headache or with sore or sensitive teeth?</p>
          </div>
          <RadioGroup value={ form1.values.problems_in_sleep } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='problems_in_sleep' orientation='horizontal'>
            <Radio label='Yes' value='true' />
            <Radio label='No' value='false' />
          </RadioGroup>
        </div>
        {form1.touched.problems_in_sleep && Boolean(form1.errors.problems_in_sleep) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      {form1.values.problems_in_sleep == 'true' &&
      <TextField
        label="Please provide details here" variant="standard" name='problems_in_sleep_reason' fullWidth
        value={ form1.values.problems_in_sleep_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}
      <FormControl error={form1.touched.clench_in_day && Boolean(form1.errors.clench_in_day)}>
        <div className="flex gap-4 justify-between">
          <div>
            <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Do you clench your teeth in the daytime?</p>
          </div>
          <RadioGroup value={ form1.values.clench_in_day } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='clench_in_day' orientation='horizontal'>
            <Radio label='Yes' value='true' />
            <Radio label='No' value='false' />
          </RadioGroup>
        </div>
        {form1.touched.clench_in_day && Boolean(form1.errors.clench_in_day) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      {form1.values.clench_in_day == 'true' &&
      <TextField
        label="Please provide details here" variant="standard" name='clench_in_day_reason' fullWidth
        value={ form1.values.clench_in_day_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}
      <FormControl error={form1.touched.wear_bite_appliance && Boolean(form1.errors.wear_bite_appliance)}>
        <div className="flex gap-4 justify-between">
          <div>
            <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Do you wear, or have you ever worn a bite appliance? Either for clenching at night (a night guard) or for sleep apnea?</p>
          </div>
          <RadioGroup value={ form1.values.wear_bite_appliance } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='wear_bite_appliance' orientation='horizontal'>
            <Radio label='Yes' value='true' />
            <Radio label='No' value='false' />
          </RadioGroup>
        </div>
        {form1.touched.wear_bite_appliance && Boolean(form1.errors.wear_bite_appliance) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      {form1.values.wear_bite_appliance == 'true' &&
      <TextField
        label="Please provide details here" variant="standard" name='wear_bite_appliance_reason' fullWidth
        value={ form1.values.wear_bite_appliance_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}
      <FormControl error={form1.touched.bite_nails && Boolean(form1.errors.bite_nails)}>
        <div className="flex gap-4 justify-between">
          <div>
            <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Do you bite your nails, chew gum or on pens, hold nails with your teeth, or any other oral habits?</p>
          </div>
          <RadioGroup value={ form1.values.bite_nails } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='bite_nails' orientation='horizontal'>
            <Radio label='Yes' value='true' />
            <Radio label='No' value='false' />
          </RadioGroup>
        </div>
        {form1.touched.bite_nails && Boolean(form1.errors.bite_nails) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      {form1.values.bite_nails == 'true' &&
      <TextField
        label="Please provide details here" variant="standard" name='bite_nails_reason' fullWidth
        value={ form1.values.bite_nails_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}
      <FormControl error={form1.touched.low_silva && Boolean(form1.errors.low_silva)}>
        <div className="flex gap-4 justify-between">
          <div>
            <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Does the amount of saliva in your mouth seem too little or do you find yourself with a dry mouth often?</p>
          </div>
          <RadioGroup value={ form1.values.low_silva } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='low_silva' orientation='horizontal'>
            <Radio label='Yes' value='true' />
            <Radio label='No' value='false' />
          </RadioGroup>
        </div>
        {form1.touched.low_silva && Boolean(form1.errors.low_silva) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      {form1.values.low_silva == 'true' &&
      <TextField
        label="Please provide details here" variant="standard" name='low_silva_reason' fullWidth
        value={ form1.values.low_silva_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}
      <FormControl error={form1.touched.unpleasant_taste && Boolean(form1.errors.unpleasant_taste)}>
        <div className="flex gap-4 justify-between">
          <div>
            <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Have you ever noticed a consistently unpleasant taste or odor in your mouth?</p>
          </div>
          <RadioGroup value={ form1.values.unpleasant_taste } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='unpleasant_taste' orientation='horizontal'>
            <Radio label='Yes' value='true' />
            <Radio label='No' value='false' />
          </RadioGroup>
        </div>
        {form1.touched.unpleasant_taste && Boolean(form1.errors.unpleasant_taste) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      {form1.values.unpleasant_taste == 'true' &&
      <TextField
        label="Please provide details here" variant="standard" name='unpleasant_taste_reason' fullWidth
        value={ form1.values.unpleasant_taste_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}

      <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>* Mandatory fields</p>
      <div className="flex">
        {props.showBack && <Button variant='plain' onClick={props.prevStep}>
          <ArrowBack />
          Back
        </Button>}
        <Button color='primary' type='submit' className='!ml-auto'>
          Next
          <ArrowForward />
        </Button>
      </div>
    </form>
  )
}