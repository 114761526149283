import React, { useState } from 'react'

export default (props) => {
  return (
    <div className="sticky top-0 left-0 z-10 bg-[#00448d] px-8 py-6 flex flex-wrap gap-6 justify-between items-center">
      <p className="text-[16px] text-white mb-0">Lakewood Dental Trails</p>
      <p className="text-[16px] text-white mb-0 font-bold">{props.title}</p>
      <p className="text-[16px] text-white mb-0">Cancel</p>
    </div>
  )
}