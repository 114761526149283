import { Card, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { DateField } from '@mui/x-date-pickers'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { Radio, Select, Option, Button, Checkbox, RadioGroup, FormControl, FormHelperText } from '@mui/joy'
import { ArrowForward, ArrowBack } from '@mui/icons-material'
import { Dayjs } from 'dayjs'
import { useFormik } from 'formik'
import * as yup from 'yup'
import SignatureCanvas from 'react-signature-canvas'
import IFormProps from 'component/Form/IFormProps.ts';
import EventBus from '../../../../../bus.ts';

export default (props:IFormProps) => {

  const validationSchema = () => {
    if(!props.validation) return yup.object({})
    return yup.object({
      signature: yup.string().required('Required'),
    })
  }

  const [ sigCanvas, setSigCanvas ] = useState(null)

  const onEndSignature = () => {
    form1.values.signature = sigCanvas?.toDataURL()
  }

  const form1 = useFormik({
    initialValues: props.formData['step_' + props.step],
    validationSchema: validationSchema,
    onSubmit: (values) => {
      try{
        props.setFormData({ ...props.formData, ['step_' + props.step]: values })
        props.nextStep()
      }catch(e){
        console.log(e)
      }
    },
  });

  useEffect(() => {
    EventBus.$on('submit-next-' + props.slug + '-' + props.step, form1.handleSubmit)
  }, [])
  
  useEffect(() => {
    Object.keys(props.formData['step_' + props.step]).forEach(key => form1?.setFieldValue(key, props.formData['step_' + props.step][key]))
  }, [ props.formData['step_' + props.step] ])

  return (
    <form onSubmit={form1.handleSubmit} className={'flex flex-col gap-4 ' + props.className}>
      <p className='text-[1rem] text-[#000]/[0.6] mb-0  '>Please sign*</p>
      <FormControl error={form1.touched.signature && Boolean(form1.errors.signature)}>
        {props.className.includes('flex') && <SignatureCanvas penColor='black' ref={setSigCanvas} canvasProps={{ className: 'sigCanvas bg-[#f1f3f4] w-full h-[250px] border-2 border-[#bbc2c7] rounded-[25px]' }} onEnd={onEndSignature} clearOnResize={false} />}
        {form1.touched.signature && Boolean(form1.errors.signature) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Please sign by drawing in the box above.</FormHelperText>}
      </FormControl>
      <p className='text-[10px] text-[#000]/[0.6]'>By drawing in the box above I understand and agree that this is a legal representation of my signature.</p>
      <p className='text-[1rem] text-[#000]/[0.6]'>* Mandatory fields</p>
      <div className="flex">
        {props.showBack && <Button variant='plain' onClick={props.prevStep}>
          <ArrowBack />
          Back
        </Button>}
        <Button color='primary' type='submit' className='!ml-auto'>
          Next
          <ArrowForward />
        </Button>
      </div>
    </form>
  )
}