import React, { useState, useEffect } from 'react'
import { Radio, Button, Checkbox, RadioGroup, FormHelperText } from '@mui/joy'
import { ArrowForward, ArrowBack } from '@mui/icons-material'
import { useFormik } from 'formik'
import * as yup from 'yup'
import IFormProps from 'component/Form/IFormProps.ts'
import EventBus from '../../../../../bus.ts'
import { TextField, Select, MenuItem, FormControl, ListItemText } from '@mui/material'

export default (props: IFormProps) => {

  const validationSchema = () => {
    if(!props.validation) return yup.object({})
    const obj = {
      had_local_anesthetic: yup.string().required('Required'),
      sedated_for_treatment: yup.string().required('Required'),
      unfavourable_reac_from_prev: yup.string().required('Required'),
      suck_finger: yup.string().required('Required'),
      pain_in_chewing: yup.string().required('Required'),
      goes_bed_with_bottle: yup.string().required('Required'),
      snack_frequently: yup.string().required('Required'),
      injured_teeth: yup.string().required('Required'),
      use_fluoride_toothpaste: yup.string().required('Required'),
    }
    return yup.object(obj)
  }

  const form1 = useFormik({
    initialValues: props.formData['step_' + props.step],
    validationSchema: validationSchema,
    onSubmit: (values) => {
      try{
        props.setFormData({ ...props.formData, ['step_' + props.step]: values })
        props.nextStep()
      }catch(e){
        console.log(e)
      }
    },
  });

  useEffect(() => {
    EventBus.$on('submit-next-' + props.slug + '-' + props.step, form1.handleSubmit)
  }, [])
  
  useEffect(() => {
    Object.keys(props.formData['step_' + props.step]).forEach(key => form1?.setFieldValue(key, props.formData['step_' + props.step][key]))
  }, [ props.formData['step_' + props.step] ])

  return (
    <form onSubmit={form1.handleSubmit} className={'flex flex-col gap-4 ' + props.className}>
      <FormControl error={form1.touched.unfavourable_reac_from_prev && Boolean(form1.errors.unfavourable_reac_from_prev)}>
        <div>
          <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Has your child experienced any unfavorable reaction from previous dental care?*</p>
        </div>
        <RadioGroup value={ form1.values.unfavourable_reac_from_prev } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='unfavourable_reac_from_prev' orientation='horizontal'>
          <Radio label='Yes' value='true' />
          <Radio label='No' value='false' />
        </RadioGroup>
        {form1.touched.unfavourable_reac_from_prev && Boolean(form1.errors.unfavourable_reac_from_prev) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      {form1.values.unfavourable_reac_from_prev == 'true' &&
      <TextField
        label="Please provide details here*" variant="standard" name='unfavourable_reac_from_prev_reason' fullWidth
        value={ form1.values.unfavourable_reac_from_prev_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}
      <FormControl error={form1.touched.suck_finger && Boolean(form1.errors.suck_finger)}>
        <div>
          <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Does your child suck a finger, thumb, or pacifier?*</p>
        </div>
        <RadioGroup value={ form1.values.suck_finger } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='suck_finger' orientation='horizontal'>
          <Radio label='Yes' value='true' />
          <Radio label='No' value='false' />
        </RadioGroup>
        {form1.touched.suck_finger && Boolean(form1.errors.suck_finger) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      {form1.values.suck_finger == 'true' &&
      <TextField
        label="Please provide details here*" variant="standard" name='suck_finger_reason' fullWidth
        value={ form1.values.suck_finger_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}
      <FormControl error={form1.touched.pain_in_chewing && Boolean(form1.errors.pain_in_chewing)}>
        <div>
          <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Does your child have pain with chewing, yawning, or wide opening?*</p>
        </div>
        <RadioGroup value={ form1.values.pain_in_chewing } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='pain_in_chewing' orientation='horizontal'>
          <Radio label='Yes' value='true' />
          <Radio label='No' value='false' />
        </RadioGroup>
        {form1.touched.pain_in_chewing && Boolean(form1.errors.pain_in_chewing) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      {form1.values.pain_in_chewing == 'true' &&
      <TextField
        label="Please provide details here*" variant="standard" name='pain_in_chewing_reason' fullWidth
        value={ form1.values.pain_in_chewing_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}
      <FormControl error={form1.touched.goes_bed_with_bottle && Boolean(form1.errors.goes_bed_with_bottle)}>
        <div>
          <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Does your child go to bed with a bottle or sippy cup?*</p>
        </div>
        <RadioGroup value={ form1.values.goes_bed_with_bottle } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='goes_bed_with_bottle' orientation='horizontal'>
          <Radio label='Yes' value='true' />
          <Radio label='No' value='false' />
        </RadioGroup>
        {form1.touched.goes_bed_with_bottle && Boolean(form1.errors.goes_bed_with_bottle) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      {form1.values.goes_bed_with_bottle == 'true' &&
      <TextField
        label="Please provide details here*" variant="standard" name='goes_bed_with_bottle_reason' fullWidth
        value={ form1.values.goes_bed_with_bottle_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}
      <FormControl error={form1.touched.snack_frequently && Boolean(form1.errors.snack_frequently)}>
        <div>
          <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Does your child snack frequently?*</p>
        </div>
        <RadioGroup value={ form1.values.snack_frequently } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='snack_frequently' orientation='horizontal'>
          <Radio label='Yes' value='true' />
          <Radio label='No' value='false' />
        </RadioGroup>
        {form1.touched.snack_frequently && Boolean(form1.errors.snack_frequently) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      {form1.values.snack_frequently == 'true' &&
      <TextField
        label="Please provide details here*" variant="standard" name='snack_frequently_reason' fullWidth
        value={ form1.values.snack_frequently_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}

      <FormControl error={form1.touched.had_local_anesthetic && Boolean(form1.errors.had_local_anesthetic)}>
        <div>
          <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Has your child had local anesthetic?*</p>
        </div>
        <RadioGroup value={ form1.values.had_local_anesthetic } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='had_local_anesthetic' orientation='horizontal'>
          <Radio label='Yes' value='true' />
          <Radio label='No' value='false' />
        </RadioGroup>
        {form1.touched.had_local_anesthetic && Boolean(form1.errors.had_local_anesthetic) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      {form1.values.had_local_anesthetic == 'true' &&
      <TextField
        label="Please provide details here*" variant="standard" name='had_local_anesthetic_reason' fullWidth
        value={ form1.values.had_local_anesthetic_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}
      
      <FormControl error={form1.touched.sedated_for_treatment && Boolean(form1.errors.sedated_for_treatment)}>
        <div>
          <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Has your child been sedated for dental treatment?*</p>
        </div>
        <RadioGroup value={ form1.values.sedated_for_treatment } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='sedated_for_treatment' orientation='horizontal'>
          <Radio label='Yes' value='true' />
          <Radio label='No' value='false' />
        </RadioGroup>
        {form1.touched.sedated_for_treatment && Boolean(form1.errors.sedated_for_treatment) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      {form1.values.sedated_for_treatment == 'true' &&
      <TextField
        label="Please provide details here*" variant="standard" name='sedated_for_treatment_reason' fullWidth
        value={ form1.values.sedated_for_treatment_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}
      <FormControl error={form1.touched.injured_teeth && Boolean(form1.errors.injured_teeth)}>
        <div>
          <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Have your child’s teeth ever been injured?*</p>
        </div>
        <RadioGroup value={ form1.values.injured_teeth } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='injured_teeth' orientation='horizontal'>
          <Radio label='Yes' value='true' />
          <Radio label='No' value='false' />
        </RadioGroup>
        {form1.touched.injured_teeth && Boolean(form1.errors.injured_teeth) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      {form1.values.injured_teeth == 'true' &&
      <TextField
        label="Please provide details here*" variant="standard" name='injured_teeth_reason' fullWidth
        value={ form1.values.injured_teeth_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}
      <FormControl error={form1.touched.use_fluoride_toothpaste && Boolean(form1.errors.use_fluoride_toothpaste)}>
        <div>
          <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Does your child use fluoride toothpaste?*</p>
        </div>
        <RadioGroup value={ form1.values.use_fluoride_toothpaste } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='use_fluoride_toothpaste' orientation='horizontal'>
          <Radio label='Yes' value='true' />
          <Radio label='No' value='false' />
        </RadioGroup>
        {form1.touched.use_fluoride_toothpaste && Boolean(form1.errors.use_fluoride_toothpaste) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      {form1.values.use_fluoride_toothpaste == 'true' &&
      <TextField
        label="Please provide details here*" variant="standard" name='use_fluoride_toothpaste_reason' fullWidth
        value={ form1.values.use_fluoride_toothpaste_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}

      <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>* Mandatory fields</p>
      <div className="flex">
        {props.showBack && <Button variant='plain' onClick={props.prevStep}>
          <ArrowBack />
          Back
        </Button>}
        <Button color='primary' type='submit' className='!ml-auto'>
          Next
          <ArrowForward />
        </Button>
      </div>
    </form>
  )
}