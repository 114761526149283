import React, { useState, useEffect } from 'react'
import { Radio, Button, Checkbox, RadioGroup, FormControl, FormHelperText } from '@mui/joy'
import { ArrowForward, ArrowBack } from '@mui/icons-material'
import { useFormik } from 'formik'
import * as yup from 'yup'
import IFormProps from 'component/Form/IFormProps.ts'
import EventBus from '../../../../../bus.ts';
import Financial from '../../../../policies/Financial.tsx'

export default (props: IFormProps) => {

  const validationSchema = () => {
    if(!props.validation) return yup.object({})
    return yup.object({
      agree: yup.boolean().isTrue().required('Required'),
    })
  }

  const form1 = useFormik({
    initialValues: props.formData['step_' + props.step],
    validationSchema: validationSchema,
    onSubmit: (values) => {
      try{
        props.setFormData({ ...props.formData, ['step_' + props.step]: values })
        props.nextStep()
      }catch(e){
        console.log(e)
      }
    },
  });

  useEffect(() => {
    EventBus.$on('submit-next-' + props.slug + '-' + props.step, form1.handleSubmit)
  }, [])
  
  useEffect(() => {
    Object.keys(props.formData['step_' + props.step]).forEach(key => form1?.setFieldValue(key, props.formData['step_' + props.step][key]))
  }, [ props.formData['step_' + props.step] ])

  return (
    <form onSubmit={form1.handleSubmit} className={'flex flex-col gap-4 ' + props.className}>
      <Financial />
      <FormControl error={form1.touched.agree && Boolean(form1.errors.agree)}>
        <Checkbox label='I confirm and agree*' name='agree' onChange={ form1.handleChange } checked={ form1.values.agree } />
        {form1.touched.agree && Boolean(form1.errors.agree) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>* Mandatory fields</p>
      <div className="flex">
        {props.showBack && <Button variant='plain' onClick={props.prevStep}>
          <ArrowBack />
          Back
        </Button>}
        <Button color='primary' type='submit' className='!ml-auto'>
          Next
          <ArrowForward />
        </Button>
      </div>
    </form>
  )
}