import React, { useState, useEffect } from 'react'
import { Radio, Button, Checkbox, RadioGroup, FormHelperText } from '@mui/joy'
import { ArrowForward, ArrowBack } from '@mui/icons-material'
import { useFormik } from 'formik'
import * as yup from 'yup'
import IFormProps from 'component/Form/IFormProps.ts'
import EventBus from '../../../../../bus.ts'
import { TextField, Select, MenuItem, FormControl, ListItemText } from '@mui/material'

export default (props: IFormProps) => {

  const validationSchema = () => {
    if(!props.validation) return yup.object({})
    const obj = {
      cavities: yup.string().required('Required'),
      trauma: yup.string().required('Required'),
      orthodontics: yup.string().required('Required'),
      toothache: yup.string().required('Required'),
      gum_infection: yup.string().required('Required'),
      jaw_sound: yup.string().required('Required'),
      sensitive_teeth: yup.string().required('Required'),
      color_of_teeth: yup.string().required('Required'),
      grinding_of_teeth: yup.string().required('Required'),
      mouth_breathing: yup.string().required('Required'),
    }
    return yup.object(obj)
  }

  const form1 = useFormik({
    initialValues: props.formData['step_' + props.step],
    validationSchema: validationSchema,
    onSubmit: (values) => {
      try{
        props.setFormData({ ...props.formData, ['step_' + props.step]: values })
        props.nextStep()
      }catch(e){
        console.log(e)
      }
    },
  });

  useEffect(() => {
    EventBus.$on('submit-next-' + props.slug + '-' + props.step, form1.handleSubmit)
  }, [])
  
  useEffect(() => {
    Object.keys(props.formData['step_' + props.step]).forEach(key => form1?.setFieldValue(key, props.formData['step_' + props.step][key]))
  }, [ props.formData['step_' + props.step] ])

  return (
    <form onSubmit={form1.handleSubmit} className={'flex flex-col gap-4 ' + props.className}>
      <p className='mb-0 text-[1rem] text-[#000] text-center font-bold'>Please check if your child is having problems with any of the following</p>
      <FormControl error={form1.touched.cavities && Boolean(form1.errors.cavities)}>
        <div>
          <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Cavities*</p>
        </div>
        <RadioGroup value={ form1.values.cavities } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='cavities' orientation='horizontal'>
          <Radio label='Yes' value='true' />
          <Radio label='No' value='false' />
        </RadioGroup>
        {form1.touched.cavities && Boolean(form1.errors.cavities) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      {form1.values.cavities == 'true' &&
      <TextField
        label="Please provide details here*" variant="standard" name='cavities_reason' fullWidth
        value={ form1.values.cavities_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}
      <FormControl error={form1.touched.trauma && Boolean(form1.errors.trauma)}>
        <div>
          <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Trauma*</p>
        </div>
        <RadioGroup value={ form1.values.trauma } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='trauma' orientation='horizontal'>
          <Radio label='Yes' value='true' />
          <Radio label='No' value='false' />
        </RadioGroup>
        {form1.touched.trauma && Boolean(form1.errors.trauma) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      {form1.values.trauma == 'true' &&
      <TextField
        label="Please provide details here*" variant="standard" name='trauma_reason' fullWidth
        value={ form1.values.trauma_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}
      <FormControl error={form1.touched.orthodontics && Boolean(form1.errors.orthodontics)}>
        <div>
          <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Orthodontics*</p>
        </div>
        <RadioGroup value={ form1.values.orthodontics } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='orthodontics' orientation='horizontal'>
          <Radio label='Yes' value='true' />
          <Radio label='No' value='false' />
        </RadioGroup>
        {form1.touched.orthodontics && Boolean(form1.errors.orthodontics) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      {form1.values.orthodontics == 'true' &&
      <TextField
        label="Please provide details here*" variant="standard" name='orthodontics_reason' fullWidth
        value={ form1.values.orthodontics_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}
      <FormControl error={form1.touched.toothache && Boolean(form1.errors.toothache)}>
        <div>
          <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Toothache*</p>
        </div>
        <RadioGroup value={ form1.values.toothache } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='toothache' orientation='horizontal'>
          <Radio label='Yes' value='true' />
          <Radio label='No' value='false' />
        </RadioGroup>
        {form1.touched.toothache && Boolean(form1.errors.toothache) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      {form1.values.toothache == 'true' &&
      <TextField
        label="Please provide details here*" variant="standard" name='toothache_reason' fullWidth
        value={ form1.values.toothache_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}
      <FormControl error={form1.touched.gum_infection && Boolean(form1.errors.gum_infection)}>
        <div>
          <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Gum Infections*</p>
        </div>
        <RadioGroup value={ form1.values.gum_infection } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='gum_infection' orientation='horizontal'>
          <Radio label='Yes' value='true' />
          <Radio label='No' value='false' />
        </RadioGroup>
        {form1.touched.gum_infection && Boolean(form1.errors.gum_infection) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      {form1.values.gum_infection == 'true' &&
      <TextField
        label="Please provide details here*" variant="standard" name='gum_infection_reason' fullWidth
        value={ form1.values.gum_infection_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}
      <FormControl error={form1.touched.jaw_sound && Boolean(form1.errors.jaw_sound)}>
        <div>
          <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Jaw Sounds*</p>
        </div>
        <RadioGroup value={ form1.values.jaw_sound } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='jaw_sound' orientation='horizontal'>
          <Radio label='Yes' value='true' />
          <Radio label='No' value='false' />
        </RadioGroup>
        {form1.touched.jaw_sound && Boolean(form1.errors.jaw_sound) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      {form1.values.jaw_sound == 'true' &&
      <TextField
        label="Please provide details here*" variant="standard" name='jaw_sound_reason' fullWidth
        value={ form1.values.jaw_sound_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}
      <FormControl error={form1.touched.sensitive_teeth && Boolean(form1.errors.sensitive_teeth)}>
        <div>
          <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Sensitive Teeth*</p>
        </div>
        <RadioGroup value={ form1.values.sensitive_teeth } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='sensitive_teeth' orientation='horizontal'>
          <Radio label='Yes' value='true' />
          <Radio label='No' value='false' />
        </RadioGroup>
        {form1.touched.sensitive_teeth && Boolean(form1.errors.sensitive_teeth) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      {form1.values.sensitive_teeth == 'true' &&
      <TextField
        label="Please provide details here*" variant="standard" name='sensitive_teeth_reason' fullWidth
        value={ form1.values.sensitive_teeth_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}
      <FormControl error={form1.touched.color_of_teeth && Boolean(form1.errors.color_of_teeth)}>
        <div>
          <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Color of Teeth*</p>
        </div>
        <RadioGroup value={ form1.values.color_of_teeth } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='color_of_teeth' orientation='horizontal'>
          <Radio label='Yes' value='true' />
          <Radio label='No' value='false' />
        </RadioGroup>
        {form1.touched.color_of_teeth && Boolean(form1.errors.color_of_teeth) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      {form1.values.color_of_teeth == 'true' &&
      <TextField
        label="Please provide details here*" variant="standard" name='color_of_teeth_reason' fullWidth
        value={ form1.values.color_of_teeth_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}
      <FormControl error={form1.touched.grinding_of_teeth && Boolean(form1.errors.grinding_of_teeth)}>
        <div>
          <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Grinding of Teeth*</p>
        </div>
        <RadioGroup value={ form1.values.grinding_of_teeth } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='grinding_of_teeth' orientation='horizontal'>
          <Radio label='Yes' value='true' />
          <Radio label='No' value='false' />
        </RadioGroup>
        {form1.touched.grinding_of_teeth && Boolean(form1.errors.grinding_of_teeth) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      {form1.values.grinding_of_teeth == 'true' &&
      <TextField
        label="Please provide details here*" variant="standard" name='grinding_of_teeth_reason' fullWidth
        value={ form1.values.grinding_of_teeth_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}
      <FormControl error={form1.touched.mouth_breathing && Boolean(form1.errors.mouth_breathing)}>
        <div>
          <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Mouth Breathing*</p>
        </div>
        <RadioGroup value={ form1.values.mouth_breathing } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='mouth_breathing' orientation='horizontal'>
          <Radio label='Yes' value='true' />
          <Radio label='No' value='false' />
        </RadioGroup>
        {form1.touched.mouth_breathing && Boolean(form1.errors.mouth_breathing) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      {form1.values.mouth_breathing == 'true' &&
      <TextField
        label="Please provide details here*" variant="standard" name='mouth_breathing_reason' fullWidth
        value={ form1.values.mouth_breathing_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}
      <FormControl error={form1.touched.other && Boolean(form1.errors.other)}>
        <div>
          <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Other</p>
        </div>
        <RadioGroup value={ form1.values.other } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='other' orientation='horizontal'>
          <Radio label='Yes' value='true' />
          <Radio label='No' value='false' />
        </RadioGroup>
        {form1.touched.other && Boolean(form1.errors.other) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      {form1.values.other == 'true' &&
      <TextField
        label="Please provide details here*" variant="standard" name='other_reason' fullWidth
        value={ form1.values.other_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}

      <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>* Mandatory fields</p>
      <div className="flex">
        {props.showBack && <Button variant='plain' onClick={props.prevStep}>
          <ArrowBack />
          Back
        </Button>}
        <Button color='primary' type='submit' className='!ml-auto'>
          Next
          <ArrowForward />
        </Button>
      </div>
    </form>
  )
}