import React, { useState, useEffect } from 'react'
import { Radio, Button, Checkbox, RadioGroup, FormHelperText } from '@mui/joy'
import { ArrowForward, ArrowBack } from '@mui/icons-material'
import { useFormik } from 'formik'
import * as yup from 'yup'
import IFormProps from 'component/Form/IFormProps.ts'
import EventBus from '../../../../../bus.ts'
import { TextField, Select, MenuItem, FormControl, ListItemText } from '@mui/material'

export default (props: IFormProps) => {

  const validationSchema = () => {
    if(!props.validation) return yup.object({})
    const obj = {}
    return yup.object(obj)
  }

  const form1 = useFormik({
    initialValues: props.formData['step_' + props.step],
    validationSchema: validationSchema,
    onSubmit: (values) => {
      try{
        props.setFormData({ ...props.formData, ['step_' + props.step]: values })
        props.nextStep()
      }catch(e){
        console.log(e)
      }
    },
  });

  useEffect(() => {
    EventBus.$on('submit-next-' + props.slug + '-' + props.step, form1.handleSubmit)
  }, [])
  
  useEffect(() => {
    Object.keys(props.formData['step_' + props.step]).forEach(key => form1?.setFieldValue(key, props.formData['step_' + props.step][key]))
  }, [ props.formData['step_' + props.step] ])

  return (
    <form onSubmit={form1.handleSubmit} className={'flex flex-col gap-4 ' + props.className}>
      <p className='mb-0 text-[1rem] text-[#000] text-center font-bold'>Please answer the following questions</p>
      <FormControl error={form1.touched.interested_in_improving_smile && Boolean(form1.errors.interested_in_improving_smile)}>
        <div className="flex gap-4 justify-between">
          <div>
            <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Are you interested in improving your smile?</p>
          </div>
          <RadioGroup value={ form1.values.interested_in_improving_smile } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='interested_in_improving_smile' orientation='horizontal'>
            <Radio label='Yes' value='true' />
            <Radio label='No' value='false' />
          </RadioGroup>
        </div>
        {form1.touched.interested_in_improving_smile && Boolean(form1.errors.interested_in_improving_smile) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      {form1.values.interested_in_improving_smile == 'true' &&
      <TextField
        label="Please provide details here" variant="standard" name='interested_in_improving_smile_reason' fullWidth
        value={ form1.values.interested_in_improving_smile_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}

      <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>* Mandatory fields</p>
      <div className="flex">
        {props.showBack && <Button variant='plain' onClick={props.prevStep}>
          <ArrowBack />
          Back
        </Button>}
        <Button color='primary' type='submit' className='!ml-auto'>
          Next
          <ArrowForward />
        </Button>
      </div>
    </form>
  )
}