import React, { useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import classNames from 'classnames';
import classes from './style.module.scss';
import SignaturePad from 'react-signature-canvas';
import { Button } from 'antd';

const Signature = forwardRef(({ form, signature, setSignatureUrl }, ref) => {

  const padRef = useRef();

  useImperativeHandle(ref, () => ({
    handleClearSignature
  }));


  useEffect(() => {
    // Populate signature from redux state
    if (signature && padRef.current.isEmpty()) {
      if (typeof signature === 'string') {
        padRef.current.fromDataURL(signature);
      } else if (Array.isArray(signature)) {
        padRef.current.fromData(signature);
      }
    }
  }, [signature]);

  const handleClearSignature = () => {
    console.log('clearing....')
    padRef?.current?.clear();
    setSignatureUrl(null);
    form.setFieldsValue({ signature: [] });
  };

  const handleSaveSignature = (data) => {
    console.log('signature', data);
    setSignatureUrl(padRef.current.getTrimmedCanvas().toDataURL('image/png'));
    // form.setFieldsValue({ signature: ref.current.toData() });
    form.setFieldsValue({ signature: padRef.current.toData() });
    console.log('SIGNATURE', signature);
  };

  // clear all texts being highlighted/selected when user clicks on the signature pad
  const clearTextSelection = () => {
    if (window.getSelection) {
      if (window.getSelection().empty) {
        // Chrome
        window.getSelection().empty();
      } else if (window.getSelection().removeAllRanges) {
        // Firefox
        window.getSelection().removeAllRanges();
      }
    } else if (document.selection) {
      // IE?
      document.selection.empty();
    }
  };

  return (
    <div>
      <div className={classNames(classes.signPadWrapper)}>
        <SignaturePad
          onEnd={() => handleSaveSignature()}
          onBegin={clearTextSelection}
          ref={padRef}
          penColor="black"
          backgroundColor="white"
          canvasProps={{ className: 'signCanvas' }}
          className={classNames(classes.signCanvas)}
          clearOnResize={false}
        />
      </div>
      {/* {!isSigned && (
        <p className={classNames(classes.signWarning)}>
          {t("legal.signEmptyIssue")}
        </p>
      )} */}
    </div>
  );
});

export default Signature;
