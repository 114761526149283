import { Card, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { DateField } from '@mui/x-date-pickers'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { Radio, Select, Option, Button, Checkbox, RadioGroup, FormControl, FormHelperText } from '@mui/joy'
import { ArrowForward, ArrowBack } from '@mui/icons-material'
import { Dayjs } from 'dayjs'
import { useFormik } from 'formik'
import * as yup from 'yup'
import SignatureCanvas from 'react-signature-canvas'
import IFormProps from '../../IFormProps.ts';
import EventBus from '../../../../../bus.ts';

export default (props:IFormProps) => {

  const validationSchema = () => {
    if(!props.validation) return yup.object({})
    if(form1?.values.sign_as == 'parent'){
      return yup.object({
        sign_as: yup.string().required('Required'),
        signature: yup.string().required('Required'),
        guardian_full_name: yup.string().required('Required'),
        guardian_dob: yup.string().required('Required'),
        guardian_address: yup.string().required('Required'),
        guardian_city: yup.string().required('Required'),
        guardian_state: yup.string().required('Required'),
        guardian_zip: yup.string().required('Required'),
      })
    }
    return yup.object({
      sign_as: yup.string().required('Required'),
      signature: yup.string().required('Required'),
    })
  }

  const [ sigCanvas, setSigCanvas ] = useState(null)

  const onEndSignature = () => {
    form1.values.signature = sigCanvas?.toDataURL()
  }

  useEffect(() => {
    if(form1.values.signature) {
      sigCanvas?.fromDataURL(form1.values.signature)
    }
  }, [ sigCanvas ])

  const form1 = useFormik({
    initialValues: props.formData['step_' + props.step],
    validationSchema: validationSchema,
    onSubmit: (values) => {
      try{
        props.setFormData({ ...props.formData, ['step_' + props.step]: values })
        props.nextStep()
      }catch(e){
        console.log(e)
      }
    },
  });

  useEffect(() => {
    EventBus.$on('submit-next-' + props.slug + '-' + props.step, form1.handleSubmit)
  }, [])
  
  useEffect(() => {
    Object.keys(props.formData['step_' + props.step]).forEach(key => form1?.setFieldValue(key, props.formData['step_' + props.step][key]))
  }, [ props.formData['step_' + props.step] ])

  return (
    <form onSubmit={form1.handleSubmit} className={'flex flex-col gap-4 ' + props.className}>
      <FormControl error={form1.touched.sign_as && Boolean(form1.errors.sign_as)}>
        <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Signing as*</p>
        <RadioGroup value={ form1.values.sign_as } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='sign_as' orientation='horizontal'>
          <Radio label='Patient' value='patient' />
          <Radio label='Parent/Guardian' value='parent' />
        </RadioGroup>
        {form1.touched.sign_as && Boolean(form1.errors.sign_as) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      {form1.values.sign_as == 'parent' &&
        <>
          <FormControl error={form1.touched.guardian_full_name && Boolean(form1.errors.guardian_full_name)}>
            <TextField
              label="Parent/Guardian full name*" variant="standard" name='guardian_full_name' fullWidth
              value={ form1.values.guardian_full_name } onChange={ form1.handleChange } onBlur={form1.handleBlur}
            />
            {form1.touched.guardian_full_name && Boolean(form1.errors.guardian_full_name) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
          </FormControl>
          <FormControl error={form1.touched.guardian_dob && Boolean(form1.errors.guardian_dob)}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker']}>
                <DateField
                  label="Date of Birth" variant="standard" name='guardian_dob' fullWidth
                  onChange={ ( v: Dayjs | null ) => {
                    if(v?.isValid()) form1.setFieldValue('guardian_dob', v?.toISOString().substring(0, 10), true)
                  }}
                  onBlur={form1.handleBlur}
                />
              </DemoContainer>
            </LocalizationProvider>
            {form1.touched.guardian_dob && Boolean(form1.errors.guardian_dob) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
          </FormControl>
          <FormControl error={form1.touched.guardian_address && Boolean(form1.errors.guardian_address)}>
            <TextField
              label="Address*" variant="standard" name='guardian_address' fullWidth placeholder='Street Address, Apt#'
              value={ form1.values.guardian_address } onChange={ form1.handleChange } onBlur={form1.handleBlur}
            />
            {form1.touched.guardian_address && Boolean(form1.errors.guardian_address) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
          </FormControl>
          <FormControl error={form1.touched.guardian_city && Boolean(form1.errors.guardian_city)}>
            <TextField
              label="City*" variant="standard" name='guardian_city' fullWidth
              value={ form1.values.guardian_city } onChange={ form1.handleChange } onBlur={form1.handleBlur}
            />
            {form1.touched.guardian_city && Boolean(form1.errors.guardian_city) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
          </FormControl>
          <FormControl error={form1.touched.guardian_state && Boolean(form1.errors.guardian_state)}>
            <TextField
              label="State*" variant="standard" name='guardian_state' fullWidth placeholder='Texas'
              value={ form1.values.guardian_state } onChange={ form1.handleChange } onBlur={form1.handleBlur}
            />
            {form1.touched.guardian_state && Boolean(form1.errors.guardian_state) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
          </FormControl>
          <FormControl error={form1.touched.guardian_zip && Boolean(form1.errors.guardian_zip)}>
            <TextField
              label="ZIP*" variant="standard" name='guardian_zip' fullWidth
              value={ form1.values.guardian_zip } onChange={ form1.handleChange } onBlur={form1.handleBlur}
            />
            {form1.touched.guardian_zip && Boolean(form1.errors.guardian_zip) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
          </FormControl>
        </>
      }
      <p className='text-[1rem] text-[#000]/[0.6] mb-0'>Signature (touch to draw)*</p>
      <FormControl error={form1.touched.signature && Boolean(form1.errors.signature)}>
        {props.className.includes('flex') && <SignatureCanvas penColor='black' ref={setSigCanvas} canvasProps={{ className: 'bg-[#f1f3f4] w-full h-[250px] border-2 border-[#bbc2c7] rounded-[25px]' }} onEnd={onEndSignature} clearOnResize={false} />}
        {form1.touched.signature && Boolean(form1.errors.signature) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Please sign by drawing in the box above.</FormHelperText>}
      </FormControl>
      <p className='text-[10px] text-[#000]/[0.6]'>By drawing in the box above I understand and agree that this is a legal representation of my signature.</p>

      <p className='text-[1rem] text-[#000]/[0.6]'>* Mandatory fields</p>
      <div className="flex">
        {props.showBack && <Button variant='plain' onClick={props.prevStep}>
          <ArrowBack />
          Back
        </Button>}
        <Button color='primary' type='submit' className='!ml-auto'>
          Next
          <ArrowForward />
        </Button>
      </div>
    </form>
  )
}