import React, { useState, useEffect } from 'react'
import { Radio, Button, Checkbox, RadioGroup, FormHelperText } from '@mui/joy'
import { ArrowForward, ArrowBack } from '@mui/icons-material'
import { useFormik } from 'formik'
import * as yup from 'yup'
import IFormProps from 'component/Form/IFormProps.ts'
import EventBus from '../../../../../bus.ts'
import { TextField, FormControl, Select, MenuItem, ListItemText } from '@mui/material'

export default (props: IFormProps) => {

  const IMMEDIATE_CONCERN_OPTIONS = [
    'Tooth pain', 'Bad breath', 'More attractive smile', 'Crooked/Crowded teeth', 'Discoloration', 'Missing teeth', 'Other'
  ]
  
  const validationSchema = () => {
    if(!props.validation) return yup.object({})
    const obj = {
      immediate_concern: yup.string().required('Required'),
    }
    if(form1?.values.immediate_concern == 'true') obj['immediate_concern_options'] = yup.array().of(yup.string()).min(1, 'Required')
    return yup.object(obj)
  }

  const form1 = useFormik({
    initialValues: props.formData['step_' + props.step],
    validationSchema: validationSchema,
    onSubmit: (values) => {
      try{
        props.setFormData({ ...props.formData, ['step_' + props.step]: values })
        props.nextStep()
      }catch(e){
        console.log(e)
      }
    },
  });

  useEffect(() => {
    EventBus.$on('submit-next-' + props.slug + '-' + props.step, form1.handleSubmit)
  }, [])
  
  useEffect(() => {
    Object.keys(props.formData['step_' + props.step]).forEach(key => form1?.setFieldValue(key, props.formData['step_' + props.step][key]))
  }, [ props.formData['step_' + props.step] ])

  return (
    <form onSubmit={form1.handleSubmit} className={'flex flex-col gap-4 ' + props.className}>
      <FormControl error={form1.touched.prev_dentist && Boolean(form1.errors.prev_dentist)}>
        <TextField
          label="Who was your previous Dentist and how long were you a patient there?" variant="standard" name='prev_dentist' fullWidth
          value={ form1.values.prev_dentist } onChange={ form1.handleChange } onBlur={form1.handleBlur}
        />
        {form1.touched.prev_dentist && Boolean(form1.errors.prev_dentist) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      <FormControl error={form1.touched.date_of_last_dental_exam && Boolean(form1.errors.date_of_last_dental_exam)}>
        <TextField
          label="Date of your last dental exam" variant="standard" name='date_of_last_dental_exam' fullWidth
          value={ form1.values.date_of_last_dental_exam } onChange={ form1.handleChange } onBlur={form1.handleBlur}
        />
        {form1.touched.date_of_last_dental_exam && Boolean(form1.errors.date_of_last_dental_exam) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      <FormControl error={form1.touched.date_of_last_cleaning && Boolean(form1.errors.date_of_last_cleaning)}>
        <TextField
          label="Date of your last cleaning" variant="standard" name='date_of_last_cleaning' fullWidth
          value={ form1.values.date_of_last_cleaning } onChange={ form1.handleChange } onBlur={form1.handleBlur}
        />
        {form1.touched.date_of_last_cleaning && Boolean(form1.errors.date_of_last_cleaning) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      
      <FormControl error={form1.touched.immediate_concern && Boolean(form1.errors.immediate_concern)}>
        <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Do you have any immediate concerns you’d like us to address?*</p>
        <p className='mb-0 text-[0.7rem] text-[#000]/[0.6]'>Tooth pain, Bad breath, More attractive smile, Crooked/Crowded teeth, Discoloration, Missing teeth, Other...</p>
        <RadioGroup value={ form1.values.immediate_concern } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='immediate_concern' orientation='horizontal'>
          <Radio label='Yes' value='true' />
          <Radio label='No' value='false' />
        </RadioGroup>
        {form1.touched.immediate_concern && Boolean(form1.errors.immediate_concern) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      { form1.values.immediate_concern == 'true' &&
      <FormControl error={form1.touched.immediate_concern_options && Boolean(form1.errors.immediate_concern_options)} variant='standard'>
        <Select name="immediate_concern_options" multiple value={form1.values.immediate_concern_options} renderValue={(selected) => selected.join(', ')}
          onChange={e => {
            const { target: { value } } = e
            form1.setFieldValue('immediate_concern_options', typeof value === 'string' ? value.split(',') : value, true)
          }}
        >
          {IMMEDIATE_CONCERN_OPTIONS.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={form1.values.immediate_concern_options.indexOf(name) > -1} /> &nbsp;
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
        {form1.touched.immediate_concern_options && Boolean(form1.errors.immediate_concern_options) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>}
      {form1.values.immediate_concern_options?.indexOf('Other') > -1 &&
      <TextField
        label="Please provide details here" variant="standard" name='immediate_concern_option_other_reason' fullWidth
        value={ form1.values.immediate_concern_option_other_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}

      <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>* Mandatory fields</p>
      <div className="flex">
        {props.showBack && <Button variant='plain' onClick={props.prevStep}>
          <ArrowBack />
          Back
        </Button>}
        <Button color='primary' type='submit' className='!ml-auto'>
          Next
          <ArrowForward />
        </Button>
      </div>
    </form>
  )
}