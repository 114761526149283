import React, { useState, useEffect, useRef } from 'react';
import {
  Card,
  Form,
  Input,
  Button,
  Typography,
  message,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import NumberFormat from 'react-number-format';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import 'antd/dist/antd.css';
import OtpInput from 'react18-input-otp';
import BeatLoader from 'react-spinners/BeatLoader';
import PropagateLoader from 'react-spinners/PropagateLoader';
import { Navigate } from "react-router-dom";

import { normalizePhoneNumber } from '../../helper/general';
import Otp from './Otp';
import classes from './auth.module.scss';
import { setOtpSuccess, setOtpFailure, setSmsVerified } from '../../actions/authAction'
import {isSafari} from "react-device-detect";
import logo from '../../assets/images/logo.svg'

const { Paragraph, Text } = Typography;

function isNumeric(n) {
  return !isNaN(parseInt(n)) && isFinite(n);
}

const Auth = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isVerifyLoading, setIsVerifyLoading] = useState(false);
  const [isResendLoading, setIsResendLoading] = useState(false);
  const [otpValue, setOtpValue] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [numOfAttempts, setNumOfAttempts] = useState(3);
  const [resendCounter, setResendCounter] = useState(3);

  const form = useRef();
  const [login_form] = Form.useForm();
  const submitBtnRef = useRef();
  const { isOtpSent, officeName, formName, isSmsVerified } = useSelector((state) => state);

  useEffect(() => {
    handleOTPChange(otpValue);
  }, [otpValue]);

  useEffect(() => {
    if (isSmsVerified)
      navigate(`/${officeName}/${formName}`);
  }, [isSmsVerified]);

  const onSubmit = async (values) => {
    if (!isOtpSent) {
      setIsLoading(true);
      setIsDisabled(true);
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}/verify/getcode`, {
        params: {
          phone_number: `+1${normalizePhoneNumber(
            values?.phone_number
          )}`,
          channel: 'sms'
        }
      })
        .then(data => {
          setPhoneNumber(values?.phone_number);
          dispatch(setOtpSuccess());
          setIsLoading(false);
        })
        .catch(err => {
          console.log(err);
          dispatch(setOtpFailure());
          message.error('Failed to send the code to your phone number. Please check your network.');
          setIsDisabled(false);
          setIsLoading(false);
        });
    } else {
      setIsVerifyLoading(true);
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}/verify/verifycode`, {
        params: {
          phone_number: `+1${normalizePhoneNumber(
            phoneNumber
          )}`,
          code: values?.code
        }
      })
        .then(data => {
          if (data?.data?.valid) {
            setIsVerifyLoading(false);
            dispatch(setSmsVerified(true));
          } else {
            setIsVerifyLoading(false);
            form.current?.setFieldsValue({ code: '' });
            message.error('Code is invalid.');
          }
        })
        .catch(err => {
          console.log(err);
          setIsVerifyLoading(false);
          form.current?.setFieldsValue({ code: '' });
          message.error('Failed to send the otp code. Please check your network.');
        });
    }
  };

  const handleOTPChange = (otpValue) => {
    setIsDisabled(false);
    setOtpValue(otpValue);
    if (otpValue.length === 4) {
      submitBtnRef.current.click();
    }
  };

  const handleResendClick = async (e) => {
    setNumOfAttempts(3);
    setResendCounter(resendCounter - 1);

    e.preventDefault();
    setIsResendLoading(true);

    await axios.get(`${process.env.REACT_APP_BACKEND_URL}/verify/getcode`, {
      params: {
        phone_number: `+1${normalizePhoneNumber(
          phoneNumber
        )}`,
        channel: 'sms'
      }
    })
      .then(data => {
        console.log(data);
        dispatch(setOtpSuccess());
        setIsResendLoading(false);
      })
      .catch(err => {
        console.log(err);
        dispatch(setOtpFailure());
        message.error('Failed to send the code to your phone number. Please check your network.');
        setIsResendLoading(false);
      });
  };

  const handleFinishFailed = (values) => {
    const errorFieldNames = values.errorFields.map((field) => {
      return field.name[0];
    });
  };

  return (
    <div className={classNames(classes.container)}>
      <div className={classNames(classes.loginBox)}>
        <Form
          name="normal_login"
          ref={form}
          form={login_form}
          className={classNames(classes.loginForm)}
          onFinish={onSubmit}
          onFinishFailed={handleFinishFailed}
          layout="vertical"
          size="large"
          scrollToFirstError={{ behavior: 'smooth', block: 'center' }}
        >
          <div className={classNames(classes.logoWrapper)}>
            <img alt="logo" src={logo} />
          </div>

          <h2 style={{marginBottom: '20px', textAlign: 'center', fontSize: '24px'}}>Please verify your patient account</h2>
          {!isOtpSent ?
            <>
              <h4 style={{marginBottom: '40px', textAlign: 'center', fontSize: '16px', opacity: '50%', lineHeight: '1.4'}}>Please input your phone number to verify your patient account and complete your requested forms</h4>
              <Form.Item
                name="phone_number"
                messageVariables={{
                  label: 'Phone',
                }}
                label={null}
                // style={{ marginBottom: 0 }}
                rules={[
                  {
                    required: true,
                    validator: (_, value) => {
                      if (value) {
                        if (
                          normalizePhoneNumber(value)?.length !==
                          10
                        ) {
                          return Promise.reject(
                            new Error('Phone Number must be 10 digits.')
                          );
                        } else {
                          return Promise.resolve();
                        }
                      } else {
                        return Promise.reject(
                          new Error('Phone Number is required')
                        );
                      }
                    },
                  },
                ]}
              >
                <NumberFormat
                  format="(###) ###-####"
                  mask=" "
                  customInput={Input}
                  placeholder={'(555) 555-5555'}
                />
              </Form.Item>
            </>
            :
            <>
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <Form.Item
                  style={{
                    display: isVerifyLoading ? 'none' : 'block',
                  }}
                  name="code"
                  rules={[
                    {
                      required: isOtpSent,
                      // message: 'Please input the password	code!',
                      whitespace: true,
                    },
                  ]}
                >
                  <OtpInput
                    value={otpValue}
                    onChange={handleOTPChange}
                    numInputs={4}
                    separator={
                      <span className={classNames(classes.separator)}>
                                    &nbsp;
                                  </span>
                    }
                    shouldAutoFocus={true}
                    containerStyle={classNames(
                      classes.otp_container_styles
                    )}
                    inputStyle={classNames(
                      isSafari
                        ? classes.otp_input_styles_safari
                        : classes.otp_input_styles
                    )}
                    inputMode="decimal"
                    isInputNum={true}
                  />
                </Form.Item>
              </div>
              <div>
                <BeatLoader
                  color="#3db9eb"
                  loading={isVerifyLoading}
                  size={10}
                />
                <Paragraph className={classNames(classes.desc)}>
                  Not received yet?
                  <a
                    href=""
                    id="024b0c46-c5ce-4a67-b687-c9afd6ca995c"
                    onClick={handleResendClick}
                    className={classNames(classes.resend_button)}
                  >
                    {!isResendLoading && <>Resend</>}
                  </a>
                  <PropagateLoader
                    css={{ marginLeft: '5rem' }}
                    color="#3db9eb"
                    loading={isResendLoading}
                    size={7}
                  />
                </Paragraph>
                <Paragraph className={classNames(classes.desc)}>
                  Did you put in the wrong number?
                  <a
                    href=""
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(setOtpFailure());
                      setIsDisabled(false);
                      setIsLoading(false);
                    }}
                    className={classNames(classes.resend_button)}
                  >
                    Change Number
                  </a>
                </Paragraph>
              </div>
            </>
          }
          <Form.Item style={{ display: !isOtpSent ? 'block' : 'none' }}>
            <Button
              type="primary"
              htmlType="submit"
              className={classNames(classes.submitButton)}
              ref={submitBtnRef}
              loading={isLoading}
              disabled={isDisabled}
              style={{ display: !isOtpSent ? 'block' : 'none', height: '48.8px' }}
            >
              Verify your phone
            </Button>
          </Form.Item>
        </Form>
      </div>
      <div className={classNames(classes.footer)}>
        <p>Powered by Caresuite</p>
      </div>
    </div>
  )
};

export default Auth;