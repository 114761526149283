import { Card } from '@mui/material'
import React, { useEffect, useState } from 'react'
import FormButtons from '../../FormFabs.tsx'
import Step1 from './steps/Step1.tsx'
import Header from '../../Header.tsx'

export default () => {
  const SLUG = 'release-of-records-minor-preview'
  const MAX_STEPS = 1
  const [ step, setStep ] = useState(1)
  const [ validation, setValidation ] = useState(false)
  
  const nextStep = () => {
    if(step < MAX_STEPS) setStep(step + 1)
  }
  const prevStep = () => {
    if(step > 1) setStep(step - 1)
  }

  const [ formData, setFormData ] = useState({
    step_1: {
      scenario: '',
      child_prev_dentist_name: '',
      child_prev_dentist_address: '',
      child_prev_dentist_phone: '',
      child_prev_dentist_email: '',
      child_new_dentist_name: '',
      child_new_dentist_address: '',
      child_new_dentist_phone: '',
      child_new_dentist_email: '',
      send_a_copy_of: '',
      send_a_copy_of_other: '',
      agree: false,
      signature: '',
    },
  })
  return (
    <>
      <Header title='Release of Records Authorization' />
      <div className='max-w-[1000px] mx-auto py-12'>
        <Card className='p-8 flex flex-col gap-4' elevation={3}>
          <Step1 className={`${step == 1 ? 'flex' : 'hidden'}`} step={1} prevStep={prevStep} nextStep={nextStep} validation={validation} slug={SLUG} formData={formData} setFormData={setFormData} />
          {!validation && <p className='mb-0 text-[1rem] text-[#ff6e19] text-center'>PREVIEW MODE - form validation disabled!</p>}
        </Card>
      </div>
      <FormButtons prevStep={prevStep} nextStep={nextStep} step={step} validation={validation} setValidation={setValidation} slug={SLUG} maxStep={MAX_STEPS} />
    </>
  )
}