import { Card } from '@mui/material'
import React, { useEffect, useState } from 'react'
import FormButtons from '../../FormFabs.tsx'
import Form from './steps/Form.tsx'
import Header from '../../Header.tsx'

export default () => {
  const SLUG = 'orthodontic-examination-preview'
  const MAX_STEPS = 1
  const [ step, setStep ] = useState(1)
  const [ validation, setValidation ] = useState(false)
  
  const nextStep = () => {
    if(step < MAX_STEPS) setStep(step + 1)
    else submitForm()
  }
  const prevStep = () => {
    if(step > 1) setStep(step - 1)
  }

  const [ formData, setFormData ] = useState({
    step_1: {
      chief_concern: '',
      general_health: '',
      significant_condition: '',
      prescribed_drugs: '',
      tonsil: '',
      nasal_airway: '',
      habits: '',
      trauma: '',
      prev_orthodic_treatment: '',
      temporomandibular_joint_exam: '',
      vertical_face_height: '',
      bilateral: '',
      lips_position_at_rest: '',
      gummy_smile: '',
      profile: '',
      stage_of_dentition: '',
      periodontal_status: '',
      restorative_status: '',
      oral_hygiene: '',
      vertical: '',
      dental_midlines_to_face: '',
      posterior_crossbite: '',
      asymmetry_in_dental_arches: '',
      right_moral: '',
      angle_classification: '',
      incisor_overjet: '',
    },
  })

  return (
    <>
      <Header title='OrthodonticExamination' />
      <div className='max-w-[1000px] mx-auto py-12'>
        <div className="flex gap-4 flex-wrap justify-between items-center">
          <p className="text-[#1d3243] font-bold text-[20px]"></p>
          <p className="text-[#1d3243] font-bold text-[16px]">Step {step}/{MAX_STEPS}</p>
        </div>
        <Card className='p-8 flex flex-col gap-4' elevation={3}>
          <Form className={`${step == 1 ? 'flex' : 'hidden'}`} step={1} prevStep={prevStep} nextStep={nextStep} validation={validation} slug={SLUG} formData={formData} setFormData={setFormData} />
          {!validation && <p className='mb-0 text-[1rem] text-[#ff6e19] text-center'>PREVIEW MODE - form validation disabled!</p>}
        </Card>
      </div>
      <FormButtons prevStep={prevStep} nextStep={nextStep} step={step} validation={validation} setValidation={setValidation} slug={SLUG} maxStep={MAX_STEPS} />
    </>
  )
}