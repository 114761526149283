import React, { useState, useEffect } from 'react'
import { Radio, Button, Checkbox, RadioGroup, FormControl, FormHelperText } from '@mui/joy'
import { ArrowForward, ArrowBack } from '@mui/icons-material'
import { useFormik } from 'formik'
import * as yup from 'yup'
import IFormProps from 'component/Form/IFormProps.ts'
import EventBus from '../../../../../bus.ts';
import { TextField } from '@mui/material'

export default (props: IFormProps) => {

  const validationSchema = () => {
    if(!props.validation) return yup.object({})
    return yup.object({
      covid_positive_test_in_10_days: yup.string().required('Required'),
      short_breath: yup.string().required('Required'),
      cough: yup.string().required('Required'),
      loss_of_taste: yup.string().required('Required'),
      contact_in_covid_patient: yup.string().required('Required'),
      agree: yup.boolean().isTrue().required('Required'),
    })
  }

  const form1 = useFormik({
    initialValues: props.formData['step_' + props.step],
    validationSchema: validationSchema,
    onSubmit: (values) => {
      try{
        props.setFormData({ ...props.formData, ['step_' + props.step]: values })
        props.nextStep()
      }catch(e){
        console.log(e)
      }
    },
  });

  useEffect(() => {
    EventBus.$on('submit-next-' + props.slug + '-' + props.step, form1.handleSubmit)
  }, [])
  
  useEffect(() => {
    Object.keys(props.formData['step_' + props.step]).forEach(key => form1?.setFieldValue(key, props.formData['step_' + props.step][key]))
  }, [ props.formData['step_' + props.step] ])

  return (
    <form onSubmit={form1.handleSubmit} className={'flex flex-col gap-4 ' + props.className}>
      <p className="text-[#1d3243] text-[15px]">COVID 19 Questionnaire</p>
      <p className="text-[#1d3243] text-[15px]"> For the health and safety of our patients and team members, please disclose the following: </p>
      <FormControl error={form1.touched.covid_positive_test_in_10_days && Boolean(form1.errors.covid_positive_test_in_10_days)}>
        <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Have you tested positive to COVID-19 within the past 10 days?*</p>
        <RadioGroup value={ form1.values.covid_positive_test_in_10_days } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='covid_positive_test_in_10_days' orientation='horizontal'>
          <Radio label='Yes' value='true' />
          <Radio label='No' value='false' />
        </RadioGroup>
        {form1.touched.covid_positive_test_in_10_days && Boolean(form1.errors.covid_positive_test_in_10_days) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      { form1.values.covid_positive_test_in_10_days == 'true' &&
        <TextField
          label="Type in details here" variant="standard" name='covid_positive_test_in_10_days_reason' fullWidth
          value={ form1.values.covid_positive_test_in_10_days_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
        />
      }
      <FormControl error={form1.touched.fever && Boolean(form1.errors.fever)}>
        <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Do you have a fever or have you felt hot or feverish recently (14-21 days)?*</p>
        <RadioGroup value={ form1.values.fever } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='fever' orientation='horizontal'>
          <Radio label='Yes' value='true' />
          <Radio label='No' value='false' />
        </RadioGroup>
        {form1.touched.fever && Boolean(form1.errors.fever) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      { form1.values.fever == 'true' &&
        <TextField
          label="Type in details here" variant="standard" name='fever_reason' fullWidth
          value={ form1.values.fever_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
        />
      }
      <FormControl error={form1.touched.short_breath && Boolean(form1.errors.short_breath)}>
        <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Are you having shortness of breath or other difficulties breathing?*</p>
        <RadioGroup value={ form1.values.short_breath } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='short_breath' orientation='horizontal'>
          <Radio label='Yes' value='true' />
          <Radio label='No' value='false' />
        </RadioGroup>
        {form1.touched.short_breath && Boolean(form1.errors.short_breath) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      { form1.values.short_breath == 'true' &&
        <TextField
          label="Type in details here" variant="standard" name='short_breath_reason' fullWidth
          value={ form1.values.short_breath_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
        />
      }
      <FormControl error={form1.touched.cough && Boolean(form1.errors.cough)}>
        <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Do you have a cough?*</p>
        <RadioGroup value={ form1.values.cough } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='cough' orientation='horizontal'>
          <Radio label='Yes' value='true' />
          <Radio label='No' value='false' />
        </RadioGroup>
        {form1.touched.cough && Boolean(form1.errors.cough) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      { form1.values.cough == 'true' &&
        <TextField
          label="Type in details here" variant="standard" name='cough_reason' fullWidth
          value={ form1.values.cough_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
        />
      }
      <FormControl error={form1.touched.loss_of_taste && Boolean(form1.errors.loss_of_taste)}>
        <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Have you experienced recent loss of taste or smell?*</p>
        <RadioGroup value={ form1.values.loss_of_taste } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='loss_of_taste' orientation='horizontal'>
          <Radio label='Yes' value='true' />
          <Radio label='No' value='false' />
        </RadioGroup>
        {form1.touched.loss_of_taste && Boolean(form1.errors.loss_of_taste) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      { form1.values.loss_of_taste == 'true' &&
        <TextField
          label="Type in details here" variant="standard" name='loss_of_taste_reason' fullWidth
          value={ form1.values.loss_of_taste_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
        />
      }
      <FormControl error={form1.touched.contact_in_covid_patient && Boolean(form1.errors.contact_in_covid_patient)}>
        <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Have you been in contact with any laboratory confirmed COVID-19 positive patients the past 10 days?*</p>
        <RadioGroup value={ form1.values.contact_in_covid_patient } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='contact_in_covid_patient' orientation='horizontal'>
          <Radio label='Yes' value='true' />
          <Radio label='No' value='false' />
        </RadioGroup>
        {form1.touched.contact_in_covid_patient && Boolean(form1.errors.contact_in_covid_patient) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      { form1.values.contact_in_covid_patient == 'true' &&
        <TextField
          label="Type in details here" variant="standard" name='contact_in_covid_patient_reason' fullWidth
          value={ form1.values.contact_in_covid_patient_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
        />
      }
      <p className="text-[#1d3243] text-[15px]">I consent that the information above is correct</p>
      <p className="text-[#1d3243] text-[15px]">By signing this document, I acknowledge that the answers I have provided above are true and accurate </p>
      <FormControl error={form1.touched.agree && Boolean(form1.errors.agree)}>
        <Checkbox label='I confirm and agree*' name='agree' onChange={ form1.handleChange } checked={ form1.values.agree } />
        {form1.touched.agree && Boolean(form1.errors.agree) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>* Mandatory fields</p>
      <div className="flex">
        {props.showBack && <Button variant='plain' onClick={props.prevStep}>
          <ArrowBack />
          Back
        </Button>}
        <Button color='primary' type='submit' className='!ml-auto'>
          Next
          <ArrowForward />
        </Button>
      </div>
    </form>
  )
}