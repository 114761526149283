import * as actionTypes from '../actionTypes/actionTypes';
import {IS_SMS_VERIFIED} from "../actionTypes/actionTypes";

export const setOtpSuccess = () => {
  return (dispatch) => {
    try {
      dispatch({
        type: actionTypes.IS_OTP_SENT_SUCCESS,
        payload: true,
      });
      return true;
    } catch (err) {
      return false;
    }
  };
};

export const setOtpFailure = () => {
  return (dispatch) => {
    try {
      dispatch({
        type: actionTypes.IS_OTP_SENT_FAILED,
        payload: false,
      });
      return true;
    } catch (err) {
      return false;
    }
  };
};

export const setSmsVerified = (verified) => {
  return (dispatch) => {
    try {
      dispatch({
        type: actionTypes.IS_SMS_VERIFIED,
        payload: verified,
      });
      return true;
    } catch (err) {
      return false;
    }
  };
};
