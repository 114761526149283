import { TextField } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { Button, FormControl, FormHelperText, Radio, RadioGroup } from '@mui/joy'
import { ArrowBack, ArrowForward } from '@mui/icons-material'
import IFormProps from 'component/Form/IFormProps.ts';
import { useFormik } from 'formik'
import * as yup from 'yup'
import EventBus from '../../../../../bus.ts';

export default (props: IFormProps) => {

  const validationSchema = () => {
    if(!props.validation) return yup.object({})
    if(form1?.values.provide_work_address == 'true') return yup.object({
      address_work: yup.string().required('Required').nullable(),
      city_work: yup.string().required('Required').nullable(),
      state_work: yup.string().required('Required').nullable(),
      zip_work: yup.string().required('Required').nullable(),
    })
    return yup.object({})
  }

  const form1 = useFormik({
    initialValues: props.formData['step_' + props.step],
    validationSchema: validationSchema,
    onSubmit: (values) => {
      try{
        props.setFormData({ ...props.formData, ['step_' + props.step]: values })
        props.nextStep()
      }catch(e){
        console.log(e)
      }
    },
  });

  useEffect(() => {
    EventBus.$on('submit-next-' + props.slug + '-' + props.step, form1.handleSubmit)
  }, [])
  
  useEffect(() => {
    Object.keys(props.formData['step_' + props.step]).forEach(key => form1?.setFieldValue(key, props.formData['step_' + props.step][key]))
  }, [ props.formData['step_' + props.step] ])

  return (
    <form onSubmit={form1.handleSubmit} className={'flex flex-col gap-4 ' + props.className}>
      <TextField
        label="Employer" variant="standard" name='employer' fullWidth
        value={ form1.values.employer } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />
      <TextField
        label="Occupation" variant="standard" name='occupation' fullWidth
        value={ form1.values.occupation } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />
      <FormControl error={form1.touched.provide_work_address && Boolean(form1.errors.provide_work_address)}>
        <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>I am providing work address details below*</p>
        <RadioGroup value={ form1.values.provide_work_address } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='provide_work_address' orientation='horizontal'>
          <Radio label='Yes' value='true' />
          <Radio label='No' value='false' />
        </RadioGroup>
        {form1.touched.provide_work_address && Boolean(form1.errors.provide_work_address) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      {
        form1.values.provide_work_address == 'true' &&
          <>
            <FormControl error={form1.touched.address_work && Boolean(form1.errors.address_work)}>
              <TextField
                label="Address (work)*" variant="standard" name='address' fullWidth placeholder='Street Address, Apt#'
                value={ form1.values.address_work } onChange={ form1.handleChange } onBlur={form1.handleBlur}
              />
              {form1.touched.address_work && Boolean(form1.errors.address_work) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
            </FormControl>
            <FormControl error={form1.touched.city_work && Boolean(form1.errors.city_work)}>
              <TextField
                label="City (work)*" variant="standard" name='city' fullWidth
                value={ form1.values.city_work } onChange={ form1.handleChange } onBlur={form1.handleBlur}
              />
              {form1.touched.city_work && Boolean(form1.errors.city_work) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
            </FormControl>
            <FormControl error={form1.touched.state_work && Boolean(form1.errors.state_work)}>
              <TextField
                label="state (work)*" variant="standard" name='state' fullWidth placeholder='Texas'
                value={ form1.values.state_work } onChange={ form1.handleChange } onBlur={form1.handleBlur}
              />
              {form1.touched.state_work && Boolean(form1.errors.state_work) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
            </FormControl>
            <FormControl error={form1.touched.zip_work && Boolean(form1.errors.zip_work)}>
              <TextField
                label="ZIP (work)*" variant="standard" name='zip' fullWidth
                value={ form1.values.zip_work } onChange={ form1.handleChange } onBlur={form1.handleBlur}
              />
              {form1.touched.zip_work && Boolean(form1.errors.zip_work) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
            </FormControl>
          </>
      }
      <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>* Mandatory fields</p>
      <div className="flex">
        {props.showBack && <Button variant='plain' onClick={props.prevStep}>
          <ArrowBack />
          Back
        </Button>}
        <Button color='primary' type='submit' className='!ml-auto'>
          Next
          <ArrowForward />
        </Button>
      </div>
    </form>
  )
}