import React, { useState, useEffect } from 'react'
import { Radio, Button, Checkbox, RadioGroup, FormHelperText } from '@mui/joy'
import { ArrowForward, ArrowBack } from '@mui/icons-material'
import { useFormik } from 'formik'
import * as yup from 'yup'
import IFormProps from 'component/Form/IFormProps.ts'
import EventBus from '../../../../../bus.ts'
import { TextField, FormControl, Select, MenuItem, ListItemText } from '@mui/material'

export default (props: IFormProps) => {

  const ALERGIC_REACTION_OPTIONS = [
    'Aspirin', 'Ibuprofen', 'Acetaminophen', 'Codeine', 'Penicillin', 'Erythromycin', 'Tetracycline', 'Acrylic', 'Sulfa', 'Local anesthetic', 'Fluoride', 'Metals', 'Iodine', 'Barbiturates or sedatives', 'Latex', 'Other'
  ]
  
  const validationSchema = () => {
    if(!props.validation) return yup.object({})
    const obj = {}
    if(form1?.values.alergy == 'true') obj['alergy_options'] = yup.array().of(yup.string()).min(1, 'Required')
    if(form1?.values.has_other_medication == 'true') obj['other_medication'] = yup.string().required('Required')
    if(form1?.values.hospitalized == 'true') obj['hospitalized_reason'] = yup.string().required('Required')
    return yup.object(obj)
  }

  const form1 = useFormik({
    initialValues: props.formData['step_' + props.step],
    validationSchema: validationSchema,
    onSubmit: (values) => {
      try{
        props.setFormData({ ...props.formData, ['step_' + props.step]: values })
        props.nextStep()
      }catch(e){
        console.log(e)
      }
    },
  });

  useEffect(() => {
    EventBus.$on('submit-next-' + props.slug + '-' + props.step, form1.handleSubmit)
  }, [])
  
  useEffect(() => {
    Object.keys(props.formData['step_' + props.step]).forEach(key => form1?.setFieldValue(key, props.formData['step_' + props.step][key]))
  }, [ props.formData['step_' + props.step] ])

  return (
    <form onSubmit={form1.handleSubmit} className={'flex flex-col gap-4 ' + props.className}>
      <FormControl error={form1.touched.alergy && Boolean(form1.errors.alergy)}>
        <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Does your child have any allergies?*</p>
        <p className='mb-0 text-[0.7rem] text-[#000]/[0.6]'>{ ALERGIC_REACTION_OPTIONS.join(', ') }</p>
        <RadioGroup value={ form1.values.alergy } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='alergy' orientation='horizontal'>
          <Radio label='Yes' value='true' />
          <Radio label='No' value='false' />
        </RadioGroup>
        {form1.touched.alergy && Boolean(form1.errors.alergy) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      { form1.values.alergy == 'true' &&
      <FormControl error={form1.touched.alergy_options && Boolean(form1.errors.alergy_options)} variant='standard'>
        <Select name="alergy_options" multiple value={form1.values.alergy_options} renderValue={(selected) => selected.join(', ')}
          onChange={e => {
            const { target: { value } } = e
            form1.setFieldValue('alergy_options', typeof value === 'string' ? value.split(',') : value, true)
          }}
        >
          {ALERGIC_REACTION_OPTIONS.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={form1.values.alergy_options.indexOf(name) > -1} /> &nbsp;
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
        {form1.touched.alergy_options && Boolean(form1.errors.alergy_options) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>}
      {form1.values.alergy_options?.indexOf('Other') > -1 &&
      <TextField
        label="Please provide details here" variant="standard" name='alergy_option_other_reason' fullWidth
        value={ form1.values.alergy_option_other_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}

      <FormControl error={form1.touched.has_other_medication && Boolean(form1.errors.has_other_medication)}>
        <div className="flex gap-4 justify-between">
          <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Is your child currently taking any medications?</p>
          <RadioGroup value={ form1.values.has_other_medication } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='has_other_medication' orientation='horizontal'>
            <Radio label='Yes' value='true' />
            <Radio label='No' value='false' />
          </RadioGroup>
        </div>
        {form1.touched.has_other_medication && Boolean(form1.errors.has_other_medication) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      {
        form1.values.has_other_medication == 'true' &&
        <FormControl error={form1.touched.other_medication && Boolean(form1.errors.other_medication)}>
          <TextField
            label="List them and what they are treating*" variant="standard" name='other_medication' fullWidth
            value={ form1.values.other_medication } onChange={ form1.handleChange } onBlur={form1.handleBlur}
          />
          {form1.touched.other_medication && Boolean(form1.errors.other_medication) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
        </FormControl>
      }

      <FormControl error={form1.touched.hospitalized && Boolean(form1.errors.hospitalized)}>
        <div className="flex gap-4 justify-between">
          <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Has your child ever been hospitalized, had general anesthesia, or emergency room visits?</p>
          <RadioGroup value={ form1.values.hospitalized } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='hospitalized' orientation='horizontal'>
            <Radio label='Yes' value='true' />
            <Radio label='No' value='false' />
          </RadioGroup>
        </div>
        {form1.touched.hospitalized && Boolean(form1.errors.hospitalized) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      {
        form1.values.hospitalized == 'true' &&
        <FormControl error={form1.touched.hospitalized_reason && Boolean(form1.errors.hospitalized_reason)}>
          <TextField
            label="List them and what they are treating*" variant="standard" name='hospitalized_reason' fullWidth
            value={ form1.values.hospitalized_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
          />
          {form1.touched.hospitalized_reason && Boolean(form1.errors.hospitalized_reason) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
        </FormControl>
      }

      <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>* Mandatory fields</p>
      <div className="flex">
        {props.showBack && <Button variant='plain' onClick={props.prevStep}>
          <ArrowBack />
          Back
        </Button>}
        <Button color='primary' type='submit' className='!ml-auto'>
          Next
          <ArrowForward />
        </Button>
      </div>
    </form>
  )
}