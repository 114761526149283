import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import 'bootstrap/dist/css/bootstrap.css';

import {
  Button,
  Input,
  Form,
  Spin,
  message
} from 'antd';
import moment from 'moment';
import axios from "axios";

import Signature from './Signature'
import logo from '../../assets/images/logo.svg'
import classNames from "classnames/index";
import classes from './style.module.scss';
import { isHttpUrl, imageUrlToBase64 } from '../../helper/general';
import {useNavigate} from "react-router-dom";

const DinoDentForm = () => {
  const navigate = useNavigate();
  const [signatureUrl, setSignatureUrl] = useState(null);

  const { patient, formName } = useSelector((state) => state);
  const [ isLoading, setIsLoading ] = useState(true);
  const [ isSubmitting, setIsSubmitting ] = useState(false);
  const [ patientForm, setPatientForm ] = useState({});

  const [form] = Form.useForm();
  const submitBtnRef = useRef();
  const ref = useRef();

  const fetchForm = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_CARESUITE_APP_URL}/public/api/v1/patient_forms/show_by_form_name`,
      {
        params: {form_activation_id: patient?.form_activation_id}
      });
    setIsLoading(false);
    if (data) {
      setPatientForm(data);
      form.setFieldsValue({name: data?.form_data?.name});
      if (data?.form_data?.signature_url) {
        if (isHttpUrl(data?.form_data?.signature_url)) {
          imageUrlToBase64(data?.form_data?.signature_url).then((base64) => {
            setSignatureUrl(base64);
            form.setFieldsValue({ signature: base64 });
          });
        }
      }
    }
  };

  useEffect(() => {
    fetchForm();
  }, []);

  const onReset = () => {
    ref.current.handleClearSignature()
  };

  const uploadFile = async () => {
    try {
      const result = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/s3/upload`, { signature: signatureUrl, form_name: formName, patient_id: patient?.patient?.id});
      return result?.data;
    } catch (e) {
      message.error('Failed to upload the signature image to s3.');
      return false;
    }
  };

  const handleFinish = async (values) => {
    setIsSubmitting(true);
    console.log(values);
    const fileURL = await uploadFile();
    if (fileURL) {
      try {
        const payload = {
          form_activation_id: patient?.form_activation_id,
          patient_id: patient?.patient?.id,
          form_name: formName,
          form_data: {
            name: values?.name,
            signature_url: fileURL
          }
        };
        const { data } = patientForm?.id ? await axios.put(`${process.env.REACT_APP_CARESUITE_APP_URL}/public/api/v1/patient_forms/${patientForm?.id}`, payload) : await axios.post(`${process.env.REACT_APP_CARESUITE_APP_URL}/public/api/v1/patient_forms`, payload);
        setPatientForm(data);
        message.success('Form successfully submitted.');
      } catch (error) {
        message.error('Failed to submit');
      }
    }
    setIsSubmitting(false);
  };

  const handleFinishFailed = async (values) => {

  };

  return (!isLoading ?
    <div className="container">
      <div className="row">
        <div className="col-xs-12" style={{height: '25px'}}></div>
        <div className="col-sm-offset-3 col-sm-6"><img className="img-responsive" src={logo} style={{margin: '0 auto'}} /></div>
        <div className="col-xs-12" style={{height: '25px'}}></div>
      </div>
      <div className="row">
        <div className="col-sm-offset-1 col-sm-10" style={{marginBottom: '25px'}}>
          <h2>Dear Patient</h2>
          <p> General Dental Group is a <b>cash</b> practice with credit card facilities and we <b>do not</b> charge medical aid tariffs.
            Therefore, you first need to <b>settle</b> the account <b>immediately after the appointment</b> and then claim
            from your medical aid. </p>
          <p> It is important to <b>send the quotation to your medical aid</b> to confirm how
            much they will pay and what portion they will not cover.
            Please remember that you are <b>liable for the full amount</b>. </p>
          <p> A <b>50% deposit</b> needs to be settled before your child receives treatment under
            <b>general anesthesia of sedation</b>. The rest of the account is due 30 days after
            treatment. These patients need to come for <b>two weeks (free)</b> and <b>three month </b>follow-up
            appointments. </p>
          <p> If your child receives <b> three or more fillings</b> then he/she is considered <i> high
            risk to develop dental caries</i> and needs to come for four consecutive <b>three month</b> follow-up
            appointments. After the one year period, if no fillings had to be replaced or new
            cavities have developed, then we can assess him/her to come six monthly instead of three monthly. </p>
          <p> All other patients should come visits every <b>six months</b>. </p>
          <p> If a filling falls out, please contact us to replace it. I provide a <b>six month</b> guarantee
            on restorations done. However, you lose this guarantee if you miss your prescribed dental visits.
            I will not be liable for future dental treatment after this period has passed, however I will
            gladly provide the necessary treatment (at normal rates).</p> </div>
      </div>
      <div className="row">
        <div className="col-sm-offset-1 col-sm-10">
          <Form
            noValidate
            form={form}
            layout="vertical"
            onFinishFailed={handleFinishFailed}
            onFinish={handleFinish}
            requiredMark="optional"
            className="form-horizontal"
            size="large"
          >
            <div className="form-group">
              <label className="control-label col-sm-2" htmlFor="date"><b>Date:</b></label>
              <div className="col-sm-10">
                <p className="form-control-static" id="date">{moment(new Date()).format('ddd MMM DD YYYY')}</p>
              </div>
            </div>
            <div className="form-group">
              <label className="control-label col-sm-2" htmlFor="name"><b>Name (parent / legal guardian):</b></label>
              <div className="col-sm-10">
                <Form.Item
                  name={'name'}
                  rules={[
                    {
                      required: true,
                      validator: (_, value) => {
                        if (value) {
                          if (/\d/.test(value)) {
                            return Promise.reject('Name should not contain number');
                          } else {
                            return Promise.resolve();
                          }
                        } else {
                          return Promise.reject('Name is required');
                        }
                      },
                    },
                  ]}
                >
                  <Input placeholder={'Enter name'} />
                </Form.Item>
              </div>
            </div>
            <div className="form-group">
              <label className="control-label col-sm-2"><b>Signature:</b></label>
              <div className="col-sm-10">
                <Form.Item
                  style={{ marginBottom: '5px' }}
                  name="signature"
                  rules={[
                    {
                      required: true,
                      validator: (_, value) => {
                        if (!value || value?.length === 0) {
                          return Promise.reject(
                            new Error('Signature is required')
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  {/*  wrap custom component with div to make scrollingToField works */}
                  <div>
                    <Signature
                      form={form}
                      signature={signatureUrl}
                      setSignatureUrl={setSignatureUrl}
                      ref={ref}
                    />
                  </div>
                </Form.Item>
              </div>
            </div>
            {/* {!patient.isAdmin && */}
            {!false &&
            <div className="col-sm-offset-2 col-sm-10">
              <span className="pull-left">
                  <button type="button" className="btn btn-default" onClick={onReset}>Reset</button>
              </span>
              <span className="pull-right">
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className={classNames(classes.submitButton)}
                    ref={submitBtnRef}
                    loading={isSubmitting}
                  >
                    Submit
                  </Button>
                </Form.Item>
              </span>
            </div>
            }
          </Form>
        </div>
      </div>
      <div className="col-xs-12" style={{height: '25px'}}></div>
    </div>
      :
    <div className={classNames(classes.container)}>
      <Spin />
    </div>
  );
};

export default DinoDentForm;
