import React, { useState } from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { useIdleTimer } from 'react-idle-timer';
import { Modal, Button } from 'antd';
import moment from 'moment';
import { setSmsVerified, setOtpFailure } from '../../actions/authAction'

function IdleTimerModal({}) {

  const { isSmsVerified } = useSelector(state => state);
  const [isWarningModalVisible, setIsWarningModalVisible] = useState(false);

  const [timeLeft, setTimeLeft] = useState(300);
  const duration = moment.duration(timeLeft, 'seconds');
  const formattedTime = `${duration.minutes()}:${duration
    .seconds()
    .toString()
    .padStart(2, '0')}`;

  const [isSessionExpiredModalVisible, setIsSessionExpiredModalVisible] =
    useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const calculateTimeLeft = () => {
    setTimeLeft(Math.round(getRemainingTime() / 1000));
  };
  let warningTimer;

  const onPrompt = () => {
    if (isSessionExpiredModalVisible) {
      return;
    }
    if (isSmsVerified) {
      warningTimer = setInterval(calculateTimeLeft, 1000);
      setIsWarningModalVisible(true);
    }
  };

  const onIdle = () => {
    if (isSmsVerified) {
      setIsWarningModalVisible(false);
      clearInterval(warningTimer);
      dispatch(setOtpFailure());
      dispatch(setSmsVerified(false));
      navigate('/');
    }
  };

  const onContinueSession = () => {
    setIsWarningModalVisible(false);
    reset();
  };

  const { getRemainingTime, reset } = useIdleTimer({
    timeout: 1000 * 60 * 10,
    onPrompt,
    promptTimeout: 1000 * 60,
    onIdle,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange',
    ],
  });

  return (
    <>
      <Modal
        zIndex={999999}
        visible={isWarningModalVisible}
        closable={false}
        footer={[
          <Button
            type="primary"
            key="submit"
            htmlType="submit"
            onClick={onContinueSession}
          >
            Continue Session
          </Button>,
        ]}
      >
        <h2>Are you still here?</h2>
        <div>
          You will be logged out in {formattedTime}.
        </div>
      </Modal>
      <Modal
        zIndex={999999}
        title={[
          <h2 key={Math.random()}>
            Your session has expired
          </h2>,
        ]}
        visible={isSessionExpiredModalVisible}
        closable={false}
        footer={[
          <Button
            id="586aa69b-8a4a-4778-8602-025ef6a23b45"
            type="primary"
            key="submit"
            htmlType="submit"
            onClick={() => {
              dispatch(setOtpFailure());
              dispatch(setSmsVerified(false));
              navigate('/');
            }}
          >
            {'Ok'}
          </Button>,
        ]}
      >
        <p>Your session has expired due to inactivity. Please log in again.</p>
      </Modal>
    </>
  );
}

export const withRouter = (Component) => {
  const ComponentWithRouterProp = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return <Component {...props} router={{ location, navigate, params }} />;
  };

  return ComponentWithRouterProp;
}


export default IdleTimerModal;
