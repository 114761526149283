import { TextField } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { Button, FormControl, FormHelperText, Radio, RadioGroup } from '@mui/joy'
import { ArrowBack, ArrowForward } from '@mui/icons-material'
import IFormProps from 'component/Form/IFormProps.ts';
import { useFormik } from 'formik'
import * as yup from 'yup'
import EventBus from '../../../../../bus.ts';

export default (props: IFormProps) => {

  const validationSchema = () => {
    if(!props.validation) return yup.object({})
    if(form1 != undefined && form1.values.emergency_contact == 'true') return yup.object({
      full_name: yup.string().required('Required').nullable(),
      contact_phone: yup.string().required('Required').nullable(),
    })
    return yup.object({})
  }

  const form1 = useFormik({
    initialValues: props.formData['step_' + props.step],
    validationSchema: validationSchema,
    onSubmit: (values) => {
      try{
        props.setFormData({ ...props.formData, ['step_' + props.step]: values })
        props.nextStep()
      }catch(e){
        console.log(e)
      }
    },
  });

  useEffect(() => {
    EventBus.$on('submit-next-' + props.slug + '-' + props.step, form1.handleSubmit)
  }, [])
  
  useEffect(() => {
    Object.keys(props.formData['step_' + props.step]).forEach(key => form1?.setFieldValue(key, props.formData['step_' + props.step][key]))
  }, [ props.formData['step_' + props.step] ])

  return (
    <form onSubmit={form1.handleSubmit} className={'flex flex-col gap-4 ' + props.className}>
      <FormControl error={form1.touched.emergency_contact && Boolean(form1.errors.emergency_contact)}>
        <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>I am providing emergency contact details below*</p>
        <RadioGroup value={ form1.values.emergency_contact } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='emergency_contact' orientation='horizontal'>
          <Radio label='Yes' value='true' />
          <Radio label='No' value='false' />
        </RadioGroup>
        {form1.touched.emergency_contact && Boolean(form1.errors.emergency_contact) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      {
        form1.values.emergency_contact == 'true' &&
          <>
            <FormControl error={form1.touched.full_name && Boolean(form1.errors.full_name)}>
              <TextField
                label="Full Name*" variant="standard" name='full_name' fullWidth placeholder='Who should we contact?'
                value={ form1.values.full_name } onChange={ form1.handleChange } onBlur={form1.handleBlur}
              />
              {form1.touched.full_name && Boolean(form1.errors.full_name) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
            </FormControl>
            <FormControl error={form1.touched.contact_phone && Boolean(form1.errors.contact_phone)}>
              <TextField
                label="Contact phone number*" variant="standard" name='contact_phone' fullWidth
                value={ form1.values.contact_phone } onChange={ form1.handleChange } onBlur={form1.handleBlur}
              />
              {form1.touched.contact_phone && Boolean(form1.errors.contact_phone) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
            </FormControl>
            <FormControl>
              <TextField
                label="Relationship" variant="standard" name='relationship' fullWidth placeholder='Relationship to patient'
                value={ form1.values.relationship } onChange={ form1.handleChange } onBlur={form1.handleBlur}
              />
            </FormControl>
          </>
      }
      <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>* Mandatory fields</p>
      <div className="flex">
        {props.showBack && <Button variant='plain' onClick={props.prevStep}>
          <ArrowBack />
          Back
        </Button>}
        <Button color='primary' type='submit' className='!ml-auto'>
          Next
          <ArrowForward />
        </Button>
      </div>
    </form>
  )
}