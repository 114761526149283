import { TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Button, Checkbox, FormControl, FormHelperText, Radio, RadioGroup } from '@mui/joy'
import { ArrowBack, ArrowForward } from '@mui/icons-material'
import { Dayjs } from 'dayjs'
import IFormProps from 'component/Form/IFormProps.ts';
import { useFormik } from 'formik'
import * as yup from 'yup'
import EventBus from '../../../../../bus.ts';

export default (props: IFormProps) => {

  const validationSchema = () => {
    if(!props.validation) return yup.object({})
    return yup.object({
      chief_concern: yup.string().required('Required'),
      general_health: yup.string().required('Required'),
      significant_condition: yup.string().required('Required'),
      prescribed_drugs: yup.string().required('Required'),
      tonsil: yup.string().required('Required'),
      nasal_airway: yup.string().required('Required'),
      habits: yup.string().required('Required'),
      trauma: yup.string().required('Required'),
      prev_orthodic_treatment: yup.string().required('Required'),
      temporomandibular_joint_exam: yup.string().required('Required'),
      vertical_face_height: yup.string().required('Required'),
      bilateral: yup.string().required('Required'),
      lips_position_at_rest: yup.string().required('Required'),
      gummy_smile: yup.string().required('Required'),
      profile: yup.string().required('Required'),
      stage_of_dentition: yup.string().required('Required'),
      periodontal_status: yup.string().required('Required'),
      restorative_status: yup.string().required('Required'),
      oral_hygiene: yup.string().required('Required'),
      vertical: yup.string().required('Required'),
      dental_midlines_to_face: yup.string().required('Required'),
      posterior_crossbite: yup.string().required('Required'),
      asymmetry_in_dental_arches: yup.string().required('Required'),
      right_moral: yup.string().required('Required'),
      angle_classification: yup.string().required('Required'),
      incisor_overjet: yup.string().required('Required'),
    })
  }

  const form1 = useFormik({
    initialValues: props.formData['step_' + props.step],
    validationSchema: validationSchema,
    onSubmit: (values) => {
      try{
        props.setFormData({ ...props.formData, ['step_' + props.step]: values })
        props.nextStep()
      }catch(e){
        console.log(e)
      }
    },
  });

  useEffect(() => {
    EventBus.$on('submit-next-' + props.slug + '-' + props.step, form1.handleSubmit)
  }, [])
  
  useEffect(() => {
    Object.keys(props.formData['step_' + props.step]).forEach(key => form1?.setFieldValue(key, props.formData['step_' + props.step][key]))
  }, [ props.formData['step_' + props.step] ])

  return (
    <form onSubmit={form1.handleSubmit} className={'flex flex-col gap-4 ' + props.className}>
      <FormControl error={form1.touched.chief_concern && Boolean(form1.errors.chief_concern)}>
        <TextField
          label="1. Chief Concern*" variant="standard" name='chief_concern' fullWidth placeholder='Type here'
          value={ form1.values.chief_concern } onChange={ form1.handleChange } onBlur={form1.handleBlur}
        />
        {form1.touched.chief_concern && Boolean(form1.errors.chief_concern) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      
      <p className="text-[#1d3243] text-[15px] font-bold mb-0 mt-4">2. Medical History and Airway Exam</p>
      <FormControl error={form1.touched.general_health && Boolean(form1.errors.general_health)}>
        <TextField
          label="a. General health*" variant="standard" name='general_health' fullWidth placeholder='Type here'
          value={ form1.values.general_health } onChange={ form1.handleChange } onBlur={form1.handleBlur}
        />
        {form1.touched.general_health && Boolean(form1.errors.general_health) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      <FormControl error={form1.touched.significant_condition && Boolean(form1.errors.significant_condition)}>
        <TextField
          label="b. Significant conditions (e.g. requiring antibiotic premedication)*" variant="standard" name='significant_condition' fullWidth placeholder='Type here'
          value={ form1.values.significant_condition } onChange={ form1.handleChange } onBlur={form1.handleBlur}
        />
        {form1.touched.significant_condition && Boolean(form1.errors.significant_condition) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      <FormControl error={form1.touched.prescribed_drugs && Boolean(form1.errors.prescribed_drugs)}>
        <TextField
          label="c. Prescribed drugs*" variant="standard" name='prescribed_drugs' fullWidth placeholder='Type here'
          value={ form1.values.prescribed_drugs } onChange={ form1.handleChange } onBlur={form1.handleBlur}
        />
        {form1.touched.prescribed_drugs && Boolean(form1.errors.prescribed_drugs) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      <FormControl error={form1.touched.tonsil && Boolean(form1.errors.tonsil)}>
        <TextField
          label="d. Tonsils and adenoids*" variant="standard" name='tonsil' fullWidth placeholder='Type here'
          value={ form1.values.tonsil } onChange={ form1.handleChange } onBlur={form1.handleBlur}
        />
        <p className='mb-0 text-[0.7rem] text-[#000]/[0.6]'>Normal, enlarged</p>
        {form1.touched.tonsil && Boolean(form1.errors.tonsil) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      <FormControl error={form1.touched.nasal_airway && Boolean(form1.errors.nasal_airway)}>
        <TextField
          label="e. Nasal airway*" variant="standard" name='nasal_airway' fullWidth placeholder='Type here'
          value={ form1.values.nasal_airway } onChange={ form1.handleChange } onBlur={form1.handleBlur}
        />
        <p className='mb-0 text-[0.7rem] text-[#000]/[0.6]'>Open, obstructed, mouth breathing</p>
        {form1.touched.nasal_airway && Boolean(form1.errors.nasal_airway) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>

      <p className="text-[#1d3243] text-[15px] font-bold mb-0 mt-4">3. Dental History</p>
      <FormControl error={form1.touched.habits && Boolean(form1.errors.habits)}>
        <TextField
          label="a. Habits*" variant="standard" name='habits' fullWidth placeholder='Type here'
          value={ form1.values.habits } onChange={ form1.handleChange } onBlur={form1.handleBlur}
        />
        <p className='mb-0 text-[0.7rem] text-[#000]/[0.6]'>Finger, tongue, lip, bruxism, musical instruments</p>
        {form1.touched.habits && Boolean(form1.errors.habits) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      <FormControl error={form1.touched.trauma && Boolean(form1.errors.trauma)}>
        <TextField
          label="b. Trauma to face and teeth*" variant="standard" name="trauma" fullWidth placeholder='Type here'
          value={ form1.values.trauma } onChange={ form1.handleChange } onBlur={form1.handleBlur}
        />
        {form1.touched.trauma && Boolean(form1.errors.trauma) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      <FormControl error={form1.touched.prev_orthodic_treatment && Boolean(form1.errors.prev_orthodic_treatment)}>
        <TextField
          label="c. Previous orthodontic treatment*" variant="standard" name="prev_orthodic_treatment" fullWidth placeholder='Type here'
          value={ form1.values.prev_orthodic_treatment } onChange={ form1.handleChange } onBlur={form1.handleBlur}
        />
        {form1.touched.prev_orthodic_treatment && Boolean(form1.errors.prev_orthodic_treatment) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      <FormControl error={form1.touched.temporomandibular_joint_exam && Boolean(form1.errors.temporomandibular_joint_exam)}>
        <TextField
          label="4. Temporomandibular Joint Exam*" variant="standard" name='temporomandibular_joint_exam' fullWidth placeholder='Type here'
          value={ form1.values.temporomandibular_joint_exam } onChange={ form1.handleChange } onBlur={form1.handleBlur}
        />
        <p className='mb-0 text-[0.7rem] text-[#000]/[0.6]'>Symptoms, pain, history</p>
        {form1.touched.temporomandibular_joint_exam && Boolean(form1.errors.temporomandibular_joint_exam) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      
      <p className="text-[#1d3243] text-[15px] font-bold mb-0 mt-4">5. Facial Form</p>
      <p className="text-[#1d3243] text-[15px] mb-0 mt-4">a. Frontal:</p>
      <FormControl error={form1.touched.vertical_face_height && Boolean(form1.errors.vertical_face_height)}>
        <TextField
          label="i. Vertical: Face height*" variant="standard" name='vertical_face_height' fullWidth placeholder='Type here'
          value={ form1.values.vertical_face_height } onChange={ form1.handleChange } onBlur={form1.handleBlur}
        />
        <p className='mb-0 text-[0.7rem] text-[#000]/[0.6]'>Normal, long, short</p>
        {form1.touched.vertical_face_height && Boolean(form1.errors.vertical_face_height) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      <FormControl error={form1.touched.bilateral && Boolean(form1.errors.bilateral)}>
        <TextField
          label="ii. Bilateral*" variant="standard" name='bilateral' fullWidth placeholder='Type here'
          value={ form1.values.bilateral } onChange={ form1.handleChange } onBlur={form1.handleBlur}
        />
        <p className='mb-0 text-[0.7rem] text-[#000]/[0.6]'>Symmetry, asymmetry</p>
        {form1.touched.bilateral && Boolean(form1.errors.bilateral) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      <FormControl error={form1.touched.lips_position_at_rest && Boolean(form1.errors.lips_position_at_rest)}>
        <TextField
          label="iii. Lips: Position at rest*" variant="standard" name='lips_position_at_rest' fullWidth placeholder='Type here'
          value={ form1.values.lips_position_at_rest } onChange={ form1.handleChange } onBlur={form1.handleBlur}
        />
        <p className='mb-0 text-[0.7rem] text-[#000]/[0.6]'>Touching, apart(mm)</p>
        {form1.touched.lips_position_at_rest && Boolean(form1.errors.lips_position_at_rest) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      <FormControl error={form1.touched.gummy_smile && Boolean(form1.errors.gummy_smile)}>
        <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>iv. Gummy smile*</p>
        <RadioGroup value={ form1.values.gummy_smile } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='gummy_smile' orientation='horizontal'>
          <Radio label='Yes' value='true' />
          <Radio label='No' value='false' />
        </RadioGroup>
        {form1.touched.gummy_smile && Boolean(form1.errors.gummy_smile) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      <FormControl error={form1.touched.profile && Boolean(form1.errors.profile)}>
        <TextField
          label="b. Profile*" variant="standard" name='profile' fullWidth placeholder='Type here'
          value={ form1.values.profile } onChange={ form1.handleChange } onBlur={form1.handleBlur}
        />
        <p className='mb-0 text-[0.7rem] text-[#000]/[0.6]'>Straight, convex, concave</p>
        {form1.touched.profile && Boolean(form1.errors.profile) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>

      <p className="text-[#1d3243] text-[15px] font-bold mb-0 mt-4">6. Dentition</p>
      <FormControl error={form1.touched.stage_of_dentition && Boolean(form1.errors.stage_of_dentition)}>
        <TextField
          label="a. Stage of dentition*" variant="standard" name='stage_of_dentition' fullWidth placeholder='Type here'
          value={ form1.values.stage_of_dentition } onChange={ form1.handleChange } onBlur={form1.handleBlur}
        />
        <p className='mb-0 text-[0.7rem] text-[#000]/[0.6]'>Deciduous, mixed(early), late, permanent</p>
        {form1.touched.stage_of_dentition && Boolean(form1.errors.stage_of_dentition) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      <FormControl error={form1.touched.periodontal_status && Boolean(form1.errors.periodontal_status)}>
        <TextField
          label="b. Periodontal status: (All adults must have recent periodontal probings)*" variant="standard" name='periodontal_status' fullWidth placeholder='Type here'
          value={ form1.values.periodontal_status } onChange={ form1.handleChange } onBlur={form1.handleBlur}
        />
        <p className='mb-0 text-[0.7rem] text-[#000]/[0.6]'>Gingival recession, abnormal frenum</p>
        {form1.touched.periodontal_status && Boolean(form1.errors.periodontal_status) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      <FormControl error={form1.touched.restorative_status && Boolean(form1.errors.restorative_status)}>
        <TextField
          label="c. Restorative status*" variant="standard" name='restorative_status' fullWidth placeholder='Type here'
          value={ form1.values.restorative_status } onChange={ form1.handleChange } onBlur={form1.handleBlur}
        />
        <p className='mb-0 text-[0.7rem] text-[#000]/[0.6]'>Caries, endodontics, prosthetic restorations</p>
        {form1.touched.restorative_status && Boolean(form1.errors.restorative_status) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      <FormControl error={form1.touched.oral_hygiene && Boolean(form1.errors.oral_hygiene)}>
        <TextField
          label="d. Oral hygiene*" variant="standard" name='oral_hygiene' fullWidth placeholder='Type here'
          value={ form1.values.oral_hygiene } onChange={ form1.handleChange } onBlur={form1.handleBlur}
        />
        <p className='mb-0 text-[0.7rem] text-[#000]/[0.6]'>Good, poor, white spots</p>
        {form1.touched.oral_hygiene && Boolean(form1.errors.oral_hygiene) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      <FormControl error={form1.touched.vertical && Boolean(form1.errors.vertical)}>
        <TextField
          label="e. Vertical*" variant="standard" name='vertical' fullWidth placeholder='Type here'
          value={ form1.values.vertical } onChange={ form1.handleChange } onBlur={form1.handleBlur}
        />
        <p className='mb-0 text-[0.7rem] text-[#000]/[0.6]'>Overbite (%), anterior open bite (mm), posterior open bite (mm)</p>
        {form1.touched.vertical && Boolean(form1.errors.vertical) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>

      <p className="text-[#1d3243] text-[15px] mb-0 mt-4">6. Dentition</p>

      <FormControl error={form1.touched.dental_midlines_to_face && Boolean(form1.errors.dental_midlines_to_face)}>
        <TextField
          label="i. Dental midlines to face (mm)*" variant="standard" name='dental_midlines_to_face' fullWidth placeholder='Type here'
          value={ form1.values.dental_midlines_to_face } onChange={ form1.handleChange } onBlur={form1.handleBlur}
        />
        <p className='mb-0 text-[0.7rem] text-[#000]/[0.6]'>Upper, lower</p>
        {form1.touched.dental_midlines_to_face && Boolean(form1.errors.dental_midlines_to_face) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      <FormControl error={form1.touched.posterior_crossbite && Boolean(form1.errors.posterior_crossbite)}>
        <TextField
          label="ii. Posterior Crossbite*" variant="standard" name='posterior_crossbite' fullWidth placeholder='Type here'
          value={ form1.values.posterior_crossbite } onChange={ form1.handleChange } onBlur={form1.handleBlur}
        />
        <p className='mb-0 text-[0.7rem] text-[#000]/[0.6]'>Unilateral, bilateral; U/L molar inclination: lingual, buccal, intermolar with difference(mm)</p>
        {form1.touched.posterior_crossbite && Boolean(form1.errors.posterior_crossbite) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      <FormControl error={form1.touched.asymmetry_in_dental_arches && Boolean(form1.errors.asymmetry_in_dental_arches)}>
        <TextField
          label="iii. Asymmetry in dental arches*" variant="standard" name='asymmetry_in_dental_arches' fullWidth placeholder='Type here'
          value={ form1.values.asymmetry_in_dental_arches } onChange={ form1.handleChange } onBlur={form1.handleBlur}
        />
        {form1.touched.asymmetry_in_dental_arches && Boolean(form1.errors.asymmetry_in_dental_arches) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>

      <p className="text-[#1d3243] text-[15px] mb-0 mt-4">g. Anterposterior</p>
      
      <FormControl error={form1.touched.right_moral && Boolean(form1.errors.right_moral)}>
        <TextField
          label="i. Right molar, right canine, left canine, left molar*" variant="standard" name='right_moral' fullWidth placeholder='Type here'
          value={ form1.values.right_moral } onChange={ form1.handleChange } onBlur={form1.handleBlur}
        />
        {form1.touched.right_moral && Boolean(form1.errors.right_moral) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      
      <p className="text-[#1d3243] text-[15px] mb-0 mt-4">ii. Choices: III, SI (Super I), I, E, II</p>
      
      <FormControl error={form1.touched.angle_classification && Boolean(form1.errors.angle_classification)}>
        <TextField
          label="1. Angle classification*" variant="standard" name='angle_classification' fullWidth placeholder='Type here'
          value={ form1.values.angle_classification } onChange={ form1.handleChange } onBlur={form1.handleBlur}
        />
        <p className='mb-0 text-[0.7rem] text-[#000]/[0.6]'>Class I, class II-1, class II-2, class III</p>
        {form1.touched.angle_classification && Boolean(form1.errors.angle_classification) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      <FormControl error={form1.touched.incisor_overjet && Boolean(form1.errors.incisor_overjet)}>
        <TextField
          label="2. Incisor overjet (mm)*" variant="standard" name='incisor_overjet' fullWidth placeholder='Type here'
          value={ form1.values.incisor_overjet } onChange={ form1.handleChange } onBlur={form1.handleBlur}
        />
        <p className='mb-0 text-[0.7rem] text-[#000]/[0.6]'>edge to edge, anterior crossbite</p>
        {form1.touched.incisor_overjet && Boolean(form1.errors.incisor_overjet) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>

      <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>* Mandatory fields</p>
      <div className="flex">
        {props.showBack && <Button variant='plain' onClick={props.prevStep}>
          <ArrowBack />
          Back
        </Button>}
        <Button color='primary' type='submit' className='!ml-auto'>
          Next
          <ArrowForward />
        </Button>
      </div>
    </form>
  )
}