import { Card } from '@mui/material'
import React, { useEffect, useState } from 'react'
import FormButtons from '../../FormFabs.tsx'
import PrimaryInsuranceInformation from './steps/PrimaryInsuranceInformation.tsx'
import SecondaryInsuranceInformation from './steps/SecondaryInsuranceInformation.tsx'
import Header from '../../Header.tsx'

export default () => {
  const SLUG = 'medical-insurance-preview'
  const MAX_STEPS = 2
  const [ step, setStep ] = useState(1)
  const [ validation, setValidation ] = useState(false)
  
  const nextStep = () => {
    if(step < MAX_STEPS) setStep(step + 1)
  }
  const prevStep = () => {
    if(step > 1) setStep(step - 1)
  }

  const [ formData, setFormData ] = useState({
    step_1: {
      dental_insurance: '',
      update_card_photo: '',
      primary_insurance_front: '',
      primary_insurance_back: '',
      relation_with_insurance_holder: '',
      policy_holder_name: '',
      policy_holder_dob: '',
      policy_holder_ssn: '',
      policy_holder_address: '',
      policy_holder_city: '',
      policy_holder_state: '',
      policy_holder_zip: '',
      policy_holder_employer: '',
      insurance_company: '',
      id_number: '',
      group_number: '',
      phone_in_insurance: '',
      address_in_insurance: '',
    },
    step_2: {
      secondary_dental_insurance: '',
      update_card_photo: '',
      secondary_insurance_front: '',
      secondary_insurance_back: '',
      relation_with_insurance_holder: '',
      policy_holder_name: '',
      policy_holder_dob: '',
      policy_holder_ssn: '',
      policy_holder_address: '',
      policy_holder_city: '',
      policy_holder_state: '',
      policy_holder_zip: '',
      policy_holder_employer: '',
      insurance_company: '',
      id_number: '',
      group_number: '',
      phone_in_insurance: '',
      address_in_insurance: '',
    },
  })

  return (
    <>
      <Header title='Medical Insurance Form' />
      <div className='max-w-[1000px] mx-auto py-12'>
        <div className="flex gap-4 flex-wrap justify-between items-center">
          <p className="text-[#1d3243] font-bold text-[20px]">
            {
            step == 1 ? <span>Primary Insurance Information</span>
            :step == 2 ? <span>Secondary Insurance Information</span>
            :<span></span>
            }
          </p>
          <p className="text-[#1d3243] font-bold text-[16px]">Step {step}/{MAX_STEPS}</p>
        </div>
        <Card className='p-8 flex flex-col gap-4' elevation={3}>
          <PrimaryInsuranceInformation className={`${step == 1 ? 'flex' : 'hidden'}`} step={1} prevStep={prevStep} nextStep={nextStep} validation={validation} slug={SLUG} formData={formData} setFormData={setFormData} />
          <SecondaryInsuranceInformation className={`${step == 2 ? 'flex' : 'hidden'}`} showBack step={2} prevStep={prevStep} nextStep={nextStep} validation={validation} slug={SLUG} formData={formData} setFormData={setFormData} />
          {!validation && <p className='mb-0 text-[1rem] text-[#ff6e19] text-center'>PREVIEW MODE - form validation disabled!</p>}
        </Card>
      </div>
      <FormButtons prevStep={prevStep} nextStep={nextStep} step={step} validation={validation} setValidation={setValidation} slug={SLUG} maxStep={MAX_STEPS} />
    </>
  )
}