import React, { useState, useEffect } from 'react'
import { Radio, Button, Checkbox, RadioGroup, FormHelperText } from '@mui/joy'
import { ArrowForward, ArrowBack } from '@mui/icons-material'
import { useFormik } from 'formik'
import * as yup from 'yup'
import IFormProps from 'component/Form/IFormProps.ts'
import EventBus from '../../../../../bus.ts'
import { TextField, Select, MenuItem, FormControl, ListItemText } from '@mui/material'

export default (props: IFormProps) => {

  const OTHER_MEDICAL_ISSUES = ['ADD/ADHD', 'AIDS/HIV', 'Anemia', 'Anxiety disorder', 'Arthritis', 'Asthma', 'Autism', 'Bleeding/transfusions', 'Blood dyscrasias', 'Cancer/tumors', 'Cerebral palsy', 'Cleft lip/palate', 'Congenital birth defects', 'Diabetes', 'Endocrine/growth', 'Eyesight', 'Frequent infections', 'Heart Disease', 'Heart murmur', 'Hepatitis', 'Kidney disease', 'Liver/GI disease', 'Mental delays', 'Personality/social disorder', 'Physical delays', 'Recurrent headaches', 'Recurrent herpes/fever blisters', 'Rheumatic fever', 'Seizures', 'Sickle cell disease/trait', 'Significant injuries', 'Snoring', 'Speech/hearing', 'Spina bifida', 'Tonsil/adenoid problems', 'Tuberculosis', 'Other']
  const DIGESTIVE_ISSUES = ['Gastroesophageal reflux disease', 'Other']
  const HEART_ISSUES = ['Heart Arrhythmias', 'Heart Failure', 'Heart Attack', 'Stroke', 'High Blood Pressure', 'Artificial Heart Valve' ,'Other']
  const NEUROLOGICAL_ISSUES = ['Epilepsy', 'Seizures', 'Stroke', 'Migraines/severe headaches', "Parkinson's Disease", "Alzheimer's or Dementia" ,'Other']
  const BREATHING_ISSUES = ['Asthama', 'Chronic obstructive pulmonary disease (COPD)', 'Tuberculosis', 'Sleep Apnea' ,'Other']
  const AUTOIMMUNE_ISSUES = ['Arthritis', 'Systemic lupus erythematosus', 'Diabetes' ,'Other']

  const validationSchema = () => {
    if(!props.validation) return yup.object({})
    const obj = {}
    if(form1?.values.antibiotic == 'true') obj['antibiotic_reason'] = yup.string().required('Required')
    if(form1?.values.other_medical_issue == 'true') obj['antibiotic_reason'] = yup.string().required('Required')
    return yup.object(obj)
  }

  const form1 = useFormik({
    initialValues: props.formData['step_' + props.step],
    validationSchema: validationSchema,
    onSubmit: (values) => {
      try{
        props.setFormData({ ...props.formData, ['step_' + props.step]: values })
        props.nextStep()
      }catch(e){
        console.log(e)
      }
    },
  });

  useEffect(() => {
    EventBus.$on('submit-next-' + props.slug + '-' + props.step, form1.handleSubmit)
  }, [])
  
  useEffect(() => {
    Object.keys(props.formData['step_' + props.step]).forEach(key => form1?.setFieldValue(key, props.formData['step_' + props.step][key]))
  }, [ props.formData['step_' + props.step] ])

  return (
    <form onSubmit={form1.handleSubmit} className={'flex flex-col gap-4 ' + props.className}>
      <FormControl error={form1.touched.due_for_any_vaccinations && Boolean(form1.errors.due_for_any_vaccinations)}>
        <div className="flex gap-4 justify-between">
          <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Is your child past due for any vaccinations?*</p>
          <RadioGroup value={ form1.values.due_for_any_vaccinations } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='due_for_any_vaccinations' orientation='horizontal'>
            <Radio label='Yes' value='true' />
            <Radio label='No' value='false' />
          </RadioGroup>
        </div>
        {form1.touched.due_for_any_vaccinations && Boolean(form1.errors.due_for_any_vaccinations) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      {form1.values.due_for_any_vaccinations == 'true' &&
      <TextField
        label="Please provide details here*" variant="standard" name='due_for_any_vaccinations_reason' fullWidth
        value={ form1.values.due_for_any_vaccinations_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}
      <FormControl error={form1.touched.antibiotic && Boolean(form1.errors.antibiotic)}>
        <div className="flex gap-4 justify-between">
          <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Have you ever been told that your child needs to take antibiotics before dental treatment?*</p>
          <RadioGroup value={ form1.values.antibiotic } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='antibiotic' orientation='horizontal'>
            <Radio label='Yes' value='true' />
            <Radio label='No' value='false' />
          </RadioGroup>
        </div>
        {form1.touched.antibiotic && Boolean(form1.errors.antibiotic) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      {form1.values.antibiotic == 'true' &&
      <TextField
        label="Please provide details here*" variant="standard" name='antibiotic_reason' fullWidth
        value={ form1.values.antibiotic_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}
      <FormControl error={form1.touched.difficulty_at_birth && Boolean(form1.errors.difficulty_at_birth)}>
        <div className="flex gap-4 justify-between">
          <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Were there any difficulties at birth?*</p>
          <RadioGroup value={ form1.values.difficulty_at_birth } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='difficulty_at_birth' orientation='horizontal'>
            <Radio label='Yes' value='true' />
            <Radio label='No' value='false' />
          </RadioGroup>
        </div>
        {form1.touched.difficulty_at_birth && Boolean(form1.errors.difficulty_at_birth) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      {form1.values.difficulty_at_birth == 'true' &&
      <TextField
        label="Please provide details here*" variant="standard" name='difficulty_at_birth_reason' fullWidth
        value={ form1.values.difficulty_at_birth_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}

      <FormControl error={form1.touched.other_medical_issue && Boolean(form1.errors.other_medical_issue)}>
        <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Is your child currently being treated for, or has a history of any medical conditions?*</p>
        <p className='mb-0 text-[0.7rem] text-[#000]/[0.6]'>{ OTHER_MEDICAL_ISSUES.join(', ') }</p>
        <RadioGroup value={ form1.values.other_medical_issue } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='other_medical_issue' orientation='horizontal'>
          <Radio label='Yes' value='true' />
          <Radio label='No' value='false' />
        </RadioGroup>
        {form1.touched.other_medical_issue && Boolean(form1.errors.other_medical_issue) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      { form1.values.other_medical_issue == 'true' &&
      <FormControl error={form1.touched.other_medical_issue_options && Boolean(form1.errors.other_medical_issue_options)} variant='standard'>
        <Select name="other_medical_issue_options" multiple value={form1.values.other_medical_issue_options} renderValue={(selected) => selected.join(', ')}
          onChange={e => {
            const { target: { value } } = e
            form1.setFieldValue('other_medical_issue_options', typeof value === 'string' ? value.split(',') : value, true)
          }}
        >
          {OTHER_MEDICAL_ISSUES.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={form1.values.other_medical_issue_options.indexOf(name) > -1} /> &nbsp;
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
        {form1.touched.other_medical_issue_options && Boolean(form1.errors.other_medical_issue_options) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>}
      {form1.values.other_medical_issue_options?.indexOf('Other') > -1 &&
      <TextField
        label="Please provide details here" variant="standard" name='other_medical_issue_option_other_reason' fullWidth
        value={ form1.values.other_medical_issue_option_other_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}
      
      <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>* Mandatory fields</p>
      <div className="flex">
        {props.showBack && <Button variant='plain' onClick={props.prevStep}>
          <ArrowBack />
          Back
        </Button>}
        <Button color='primary' type='submit' className='!ml-auto'>
          Next
          <ArrowForward />
        </Button>
      </div>
    </form>
  )
}