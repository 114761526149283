import React, { useState, useEffect } from 'react'
import { Radio, Button, Checkbox, RadioGroup, FormControl, FormHelperText } from '@mui/joy'
import { ArrowForward, ArrowBack } from '@mui/icons-material'
import { useFormik } from 'formik'
import * as yup from 'yup'
import IFormProps from 'component/Form/IFormProps.ts'
import EventBus from '../../../../../bus.ts';
import { TextField } from '@mui/material'

export default (props: IFormProps) => {

  const validationSchema = () => {
    if(!props.validation) return yup.object({})
    return yup.object({
      agree: yup.boolean().isTrue().required('Required'),
    })
  }

  const form1 = useFormik({
    initialValues: props.formData['step_' + props.step],
    validationSchema: validationSchema,
    onSubmit: (values) => {
      try{
        props.setFormData({ ...props.formData, ['step_' + props.step]: values })
        props.nextStep()
      }catch(e){
        console.log(e)
      }
    },
  });

  useEffect(() => {
    EventBus.$on('submit-next-' + props.slug + '-' + props.step, form1.handleSubmit)
  }, [])
  
  useEffect(() => {
    Object.keys(props.formData['step_' + props.step]).forEach(key => form1?.setFieldValue(key, props.formData['step_' + props.step][key]))
  }, [ props.formData['step_' + props.step] ])

  return (
    <form onSubmit={form1.handleSubmit} className={'flex flex-col gap-4 ' + props.className}>
      <p className="text-[#1d3243] text-[15px]">COVID-19 Pandemic Emergency Dental Treatment Consent Form</p>
      <p className="text-[#1d3243] text-[15px]">I, {'patient_name'}, knowingly and willingly consent to have dental treatment completed during the COVID-19 pandemic.</p>
      <p className="text-[#1d3243] text-[15px]">I understand the COVID-19 virus has a long incubation period during which carriers of the virus may not show symptoms and still be highly contagious. It is impossible to determine who has it and who does not give the current limits in virus testing.</p>
      <p className="text-[#1d3243] text-[15px]">Dental procedures create water spray which is how the disease is spread. The ultra-fine nature of the spray can linger in the air for minutes to sometimes hours, which can transmit the COVID-19 virus.</p>
      <ul>
        <li>I understand that due to the frequency of visits of other dental patients, the characteristics of the virus, and the characteristics of dental procedures, that I have an elevated risk of contracting the virus simply by being in a dental office. {'patient_initials'}</li>
      </ul>
      <p className="text-[#1d3243] text-[15px]">I confirm that I am not presenting any of the following symptoms of COVID-19 listed below:</p>
      <ul className='list-disc pl-4'>
        <li>Fever</li>
        <li>Shortness of Breath</li>
        <li>Dry Cough</li>
        <li>Runny Nose</li>
        <li>Sore Throat</li>
        <li>Loss of taste or smell</li>
        <li>{'patient_initials'}</li>
      </ul>
      <p className="text-[#1d3243] text-[15px]">I understand that air travel significantly increases my risk of contracting and transmitting the COVID-19 virus. And the CDC recommends social distancing of at least 6 feet for a period of 14 days to anyone who has, and this is not possible with dentistry. {'patient_initials'}</p>
      <ul className='list-disc pl-4'>
        <li>I verify that I have not traveled outside the United States in the past 14 days to countries that have been affected by COVID-19. {'patient_initials'}</li>
        <li>I verify that I have not traveled domestically within the United States by commercial airline, bus, or train within the past 14 days. {'patient_initials'}</li>
      </ul>
      <FormControl error={form1.touched.agree && Boolean(form1.errors.agree)}>
        <Checkbox label='I confirm and agree*' name='agree' onChange={ form1.handleChange } checked={ form1.values.agree } />
        {form1.touched.agree && Boolean(form1.errors.agree) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>* Mandatory fields</p>
      <div className="flex">
        {props.showBack && <Button variant='plain' onClick={props.prevStep}>
          <ArrowBack />
          Back
        </Button>}
        <Button color='primary' type='submit' className='!ml-auto'>
          Next
          <ArrowForward />
        </Button>
      </div>
    </form>
  )
}