import React from 'react'

export default () => {
  return (
    <div className={'flex flex-col gap-4 '}>
      <p className="text-[#1d3243] text-[15px]">Privacy Policy</p>
      <p className="text-[#1d3243] text-[15px]">CLIENT RIGHTS AND HIPAA AUTHORIZATIONS</p>
      <p className="text-[#1d3243] text-[15px]">The following specifies your rights about this authorization under the Health Insurance Portability and Accountability Act of 1996, as amended from time to time (“HIPAA”).</p>
      <p className="text-[#1d3243] text-[15px]">1. Tell your provider if you do not understand this authorization, and the provider will explain it to you.</p>
      <p className="text-[#1d3243] text-[15px]">2. You have the right to revoke or cancel this authorization at any time, except: (a) to the extent information has already been shared based on this authorization; or (b) this authorization was obtained as a condition of obtaining insurance coverage. To revoke or cancel this authorization, you must submit your request in writing to provider at the following address (insert address of provider):</p>
      <p className="text-[#1d3243] text-[15px]">3. You may refuse to sign this authorization. Your refusal to sign will not affect your ability to obtain treatment, payment, enrollment or your eligibility for benefits. However, you may be required to complete this authorization form before receiving treatment if you have authorized your provider to disclose information about you to a third party. If you refuse to sign this authorization, and you have authorized your provider to disclose information about you to a third party, your provider has the right to decide not to treat you or accept you as a patient in their practice.</p>
      <p className="text-[#1d3243] text-[15px]">4. Once the information about you leaves this office according to the terms of this authorization, this office has no control over how it will be used by the recipient. You need to be aware that at that point your information may no longer be protected by HIPAA. If the person or entity receiving this information is not a health care provider or health plan covered by federal privacy regulations, the information described above may be disclosed to other individuals or institutions and no longer protected by these regulations.</p>
      <p className="text-[#1d3243] text-[15px]">5. You may inspect or copy the protected dental information to be used or disclosed under this authorization. You do not have the right of access to the following protected dental information: psychotherapy notes, information compiled for legal proceedings, laboratory results to which the Clinical Laboratory Improvement Act (“CLIA”) prohibits access, or information held by certain research laboratories. In addition, our provider my deny access if the provider reasonably believes access could cause harm to you or another individual. If access is denied, you may request to have a licensed health care professional for a second opinion at your expense.</p>
      <p className="text-[#1d3243] text-[15px]">6. If this office initiated this authorization, you must receive a copy of the signed authorization.</p>
      <p className="text-[#1d3243] text-[15px]">7. Special Instructions for completing this authorization for the use and disclosure of Psychotherapy Notes. HIPAA provides special protections to certain medical records known as “Psychotherapy Notes.” All Psychotherapy Notes recorded on any medium by a mental health professional (such as a psychologist or psychiatrist) must be kept by the author and filed separate from the rest of the client’s medical records to maintain a higher standard of protection. “Psychotherapy Notes” are defined under HIPAA as notes recorded by a health care provider who is a mental health professional documenting or analyzing the contents of conversation during a private counseling session or a group, joint or family counseling session and that are separate from the rest of the individual’s medical records. Excluded from the “Psychotherapy Notes” definition are the following: (a) medication prescription and monitoring, (b) counseling session start and stop times, (c) the modalities and frequencies of treatment furnished, (d) the results of clinical tests, and (e) any summary of: diagnosis, functional status, the treatment plan, symptoms, prognosis, and progress to date. Except for limited circumstances set forth in HIPAA, in order for a medical provider to release “Psychotherapy Notes” to a third party, the client who is the subject of the Psychotherapy Notes must sign this authorization to specifically allow for the release of Psychotherapy Notes. Such authorization must be separate from an authorization to release other dental records.</p>
      <p className="text-[#1d3243] text-[15px]">8. You have a right to an accounting of the disclosures of your protected dental information by provider or its business associates. The maximum disclosure accounting period is the six years immediately preceding the accounting request. The provider is not required to provide an accounting for disclosures: (a) for treatment, payment, or dental care operations; (b) to you or your personal representative; (c) for notification of or to persons involved in an individual’s dental care or payment for dental care, for disaster relief, or for facility directories; (d) pursuant to an authorization; (e) of a limited data set; (f) for national security or intelligence purposes; (g) to correctional institutions or law enforcement officials for certain purposes regarding inmates or individuals in lawful custody; or (h) incident to otherwise permitted or required uses or disclosures. Accounting for disclosures to dental oversight agencies and law enforcement officials must be temporarily suspended on their written representation that an accounting would likely impede their activities.</p>
    </div>
  )
}