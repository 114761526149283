import React, { useState, useEffect } from 'react'
import { Radio, Button, Checkbox, RadioGroup, FormControl, FormHelperText } from '@mui/joy'
import { ArrowForward, ArrowBack } from '@mui/icons-material'
import { useFormik } from 'formik'
import * as yup from 'yup'
import IFormProps from 'component/Form/IFormProps.ts'
import EventBus from '../../../../../bus.ts';
import { TextField } from '@mui/material'

export default (props: IFormProps) => {

  const validationSchema = () => {
    if(!props.validation) return yup.object({})
    return yup.object({
      fever: yup.string().required('Required'),
      short_breath: yup.string().required('Required'),
      cough: yup.string().required('Required'),
      flu: yup.string().required('Required'),
      loss_of_taste: yup.string().required('Required'),
      contact_in_covid_patient: yup.string().required('Required'),
      heart_disease: yup.string().required('Required'),
      traveled_covid_region: yup.string().required('Required'),
      agree: yup.boolean().isTrue().required('Required'),
    })
  }

  const form1 = useFormik({
    initialValues: props.formData['step_' + props.step],
    validationSchema: validationSchema,
    onSubmit: (values) => {
      try{
        props.setFormData({ ...props.formData, ['step_' + props.step]: values })
        props.nextStep()
      }catch(e){
        console.log(e)
      }
    },
  });

  useEffect(() => {
    EventBus.$on('submit-next-' + props.slug + '-' + props.step, form1.handleSubmit)
  }, [])
  
  useEffect(() => {
    Object.keys(props.formData['step_' + props.step]).forEach(key => form1?.setFieldValue(key, props.formData['step_' + props.step][key]))
  }, [ props.formData['step_' + props.step] ])

  return (
    <form onSubmit={form1.handleSubmit} className={'flex flex-col gap-4 ' + props.className}>
      <p className="text-[#1d3243] text-[15px]">COVID 19 Questionnaire</p>
      <p className="text-[#1d3243] text-[15px]">This patient disclosure form seeks information from you that we must consider before making treatment decisions in the circumstance of the COVID‐19 virus.</p>
      <p className="text-[#1d3243] text-[15px]">A weak or compromised immune system (including, but not limited to, conditions like diabetes, asthma, COPD, cancer treatment, radiation, chemotherapy, and any prior or current disease or medical condition), can put you at greater risk for contracting COVID‐19. Please disclose to us any condition that compromises your immune system and understand that we may ask you to consider rescheduling treatment after discussing any such conditions with us.</p>
      <p className="text-[#1d3243] text-[15px]">It is also important that you disclose to this office any indication of having been exposed to COVID‐19, or whether you have experienced any signs or symptoms associated with the COVID‐19 virus.</p>
      <FormControl error={form1.touched.fever && Boolean(form1.errors.fever)}>
        <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Do you have a fever or have you felt hot or feverish recently (14-21 days)?*</p>
        <RadioGroup value={ form1.values.fever } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='fever' orientation='horizontal'>
          <Radio label='Yes' value='true' />
          <Radio label='No' value='false' />
        </RadioGroup>
        {form1.touched.fever && Boolean(form1.errors.fever) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      { form1.values.fever == 'true' &&
        <TextField
          label="Type in details here" variant="standard" name='fever_reason' fullWidth
          value={ form1.values.fever_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
        />
      }
      <FormControl error={form1.touched.short_breath && Boolean(form1.errors.short_breath)}>
        <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Are you having shortness of breath or other difficulties breathing?*</p>
        <RadioGroup value={ form1.values.short_breath } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='short_breath' orientation='horizontal'>
          <Radio label='Yes' value='true' />
          <Radio label='No' value='false' />
        </RadioGroup>
        {form1.touched.short_breath && Boolean(form1.errors.short_breath) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      { form1.values.short_breath == 'true' &&
        <TextField
          label="Type in details here" variant="standard" name='short_breath_reason' fullWidth
          value={ form1.values.short_breath_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
        />
      }
      <FormControl error={form1.touched.cough && Boolean(form1.errors.cough)}>
        <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Do you have a cough?*</p>
        <RadioGroup value={ form1.values.cough } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='cough' orientation='horizontal'>
          <Radio label='Yes' value='true' />
          <Radio label='No' value='false' />
        </RadioGroup>
        {form1.touched.cough && Boolean(form1.errors.cough) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      { form1.values.cough == 'true' &&
        <TextField
          label="Type in details here" variant="standard" name='cough_reason' fullWidth
          value={ form1.values.cough_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
        />
      }
      <FormControl error={form1.touched.flu && Boolean(form1.errors.flu)}>
        <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Any other flu-like symptoms, such as gastrointestinal upset, headache or fatigue?*</p>
        <RadioGroup value={ form1.values.flu } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='flu' orientation='horizontal'>
          <Radio label='Yes' value='true' />
          <Radio label='No' value='false' />
        </RadioGroup>
        {form1.touched.flu && Boolean(form1.errors.flu) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      { form1.values.flu == 'true' &&
        <TextField
          label="Type in details here" variant="standard" name='flu_reason' fullWidth
          value={ form1.values.flu_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
        />
      }
      <FormControl error={form1.touched.loss_of_taste && Boolean(form1.errors.loss_of_taste)}>
        <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Have you experienced recent loss of taste or smell?*</p>
        <RadioGroup value={ form1.values.loss_of_taste } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='loss_of_taste' orientation='horizontal'>
          <Radio label='Yes' value='true' />
          <Radio label='No' value='false' />
        </RadioGroup>
        {form1.touched.loss_of_taste && Boolean(form1.errors.loss_of_taste) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      { form1.values.loss_of_taste == 'true' &&
        <TextField
          label="Type in details here" variant="standard" name='loss_of_taste_reason' fullWidth
          value={ form1.values.loss_of_taste_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
        />
      }
      <FormControl error={form1.touched.contact_in_covid_patient && Boolean(form1.errors.contact_in_covid_patient)}>
        <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Are you in contact with any laboratory confirmed COVID-19 positive patients?*</p>
        <RadioGroup value={ form1.values.contact_in_covid_patient } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='contact_in_covid_patient' orientation='horizontal'>
          <Radio label='Yes' value='true' />
          <Radio label='No' value='false' />
        </RadioGroup>
        {form1.touched.contact_in_covid_patient && Boolean(form1.errors.contact_in_covid_patient) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      { form1.values.contact_in_covid_patient == 'true' &&
        <TextField
          label="Type in details here" variant="standard" name='contact_in_covid_patient_reason' fullWidth
          value={ form1.values.contact_in_covid_patient_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
        />
      }
      <FormControl error={form1.touched.heart_disease && Boolean(form1.errors.heart_disease)}>
        <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Do you have heart disease, lung disease, kidney disease, diabetes or any auto-immune disorders?*</p>
        <RadioGroup value={ form1.values.heart_disease } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='heart_disease' orientation='horizontal'>
          <Radio label='Yes' value='true' />
          <Radio label='No' value='false' />
        </RadioGroup>
        {form1.touched.heart_disease && Boolean(form1.errors.heart_disease) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      { form1.values.heart_disease == 'true' &&
        <TextField
          label="Type in details here" variant="standard" name='heart_disease_reason' fullWidth
          value={ form1.values.heart_disease_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
        />
      }
      <FormControl error={form1.touched.traveled_covid_region && Boolean(form1.errors.traveled_covid_region)}>
        <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Have you traveled in the past 14 days to any regions affected by COVID-19?*</p>
        <RadioGroup value={ form1.values.traveled_covid_region } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='traveled_covid_region' orientation='horizontal'>
          <Radio label='Yes' value='true' />
          <Radio label='No' value='false' />
        </RadioGroup>
        {form1.touched.traveled_covid_region && Boolean(form1.errors.traveled_covid_region) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      { form1.values.traveled_covid_region == 'true' &&
        <TextField
          label="Type in details here" variant="standard" name='traveled_covid_region_reason' fullWidth
          value={ form1.values.traveled_covid_region_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
        />
      }
      <p className="text-[#1d3243] text-[15px]">I consent that the information above is correct</p>
      <p className="text-[#1d3243] text-[15px]">I fully understand and acknowledge the above information, risks, and cautions regarding a compromised immune system and have disclosed to my provider any conditions in my health history which may result in a compromised immune system.</p>
      <p className="text-[#1d3243] text-[15px]">By signing this document, I acknowledge that the answers I have provided above are true and accurate </p>
      <FormControl error={form1.touched.agree && Boolean(form1.errors.agree)}>
        <Checkbox label='I confirm and agree*' name='agree' onChange={ form1.handleChange } checked={ form1.values.agree } />
        {form1.touched.agree && Boolean(form1.errors.agree) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>* Mandatory fields</p>
      <div className="flex">
        {props.showBack && <Button variant='plain' onClick={props.prevStep}>
          <ArrowBack />
          Back
        </Button>}
        <Button color='primary' type='submit' className='!ml-auto'>
          Next
          <ArrowForward />
        </Button>
      </div>
    </form>
  )
}