import React from "react";
// import 'bootstrap/dist/css/bootstrap.css';
import {
  Button,
  Input,
  Form,
} from 'antd';
import moment from 'moment';

import Signature from './Signature'
import logo from '../../assets/images/logo.svg'
import classNames from "classnames/index";
import classes from './style.module.scss';

const DinoDentFormPreview = () => {

  return (
    <div className="container">
      <div className="row">
        <div className="col-xs-12" style={{height: '25px'}}></div>
        <div className="col-sm-offset-3 col-sm-6"><img className="img-responsive" src={logo} style={{margin: '0 auto'}} /></div>
        <div className="col-xs-12" style={{height: '25px'}}></div>
      </div>
      <div className="row">
        <div className="col-sm-offset-1 col-sm-10" style={{marginBottom: '25px'}}>
          <h2>Dear Patient</h2>
          <p> General Dental Group is a <b>cash</b> practice with credit card facilities and we <b>do not</b> charge medical aid tariffs.
            Therefore, you first need to <b>settle</b> the account <b>immediately after the appointment</b> and then claim
            from your medical aid. </p>
          <p> It is important to <b>send the quotation to your medical aid</b> to confirm how
            much they will pay and what portion they will not cover.
            Please remember that you are <b>liable for the full amount</b>. </p>
          <p> A <b>50% deposit</b> needs to be settled before your child receives treatment under
            <b>general anesthesia of sedation</b>. The rest of the account is due 30 days after
            treatment. These patients need to come for <b>two weeks (free)</b> and <b>three month </b>follow-up
            appointments. </p>
          <p> If your child receives <b> three or more fillings</b> then he/she is considered <i> high
            risk to develop dental caries</i> and needs to come for four consecutive <b>three month</b> follow-up
            appointments. After the one year period, if no fillings had to be replaced or new
            cavities have developed, then we can assess him/her to come six monthly instead of three monthly. </p>
          <p> All other patients should come visits every <b>six months</b>. </p>
          <p> If a filling falls out, please contact us to replace it. I provide a <b>six month</b> guarantee
            on restorations done. However, you lose this guarantee if you miss your prescribed dental visits.
            I will not be liable for future dental treatment after this period has passed, however I will
            gladly provide the necessary treatment (at normal rates).</p> </div>
      </div>
      <div className="row">
        <div className="col-sm-offset-1 col-sm-10">
          <Form
            noValidate
            layout="vertical"
            requiredMark="optional"
            className="form-horizontal"
            size="large"
          >
            <div className="form-group">
              <label className="control-label col-sm-2" htmlFor="date"><b>Date:</b></label>
              <div className="col-sm-10">
                <p className="form-control-static" id="date">{moment(new Date()).format('ddd MMM DD YYYY')}</p>
              </div>
            </div>
            <div className="form-group">
              <label className="control-label col-sm-2" htmlFor="name"><b>Name (parent / legal guardian):</b></label>
              <div className="col-sm-10">
                <Form.Item
                  name={'name'}
                >
                  <Input placeholder={'Enter name'} />
                </Form.Item>
              </div>
            </div>
            <div className="form-group">
              <label className="control-label col-sm-2"><b>Signature:</b></label>
              <div className="col-sm-10">
                <Form.Item
                  style={{ marginBottom: '5px' }}
                  name="signature"
                >
                  {/*  wrap custom component with div to make scrollingToField works */}
                  <div>
                    <Signature
                      form={null}
                      signature={null}
                      setSignatureUrl={() => {}}
                    />
                  </div>
                </Form.Item>
              </div>
            </div>
            <div className="col-sm-offset-2 col-sm-10">
              <span className="pull-left">
                  <button type="button" className="btn btn-default">Reset</button>
              </span>
              <span className="pull-right">
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className={classNames(classes.submitButton)}
                  >
                    Submit
                  </Button>
                </Form.Item>
              </span>
            </div>
          </Form>
        </div>
      </div>
      <div className="col-xs-12" style={{height: '25px'}}></div>
    </div>
  );
};

export default DinoDentFormPreview;
