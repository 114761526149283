import React from 'react';
import { Fab } from '@mui/material'
import { DoneAll, NotInterested, ArrowBack, ArrowForward, PictureAsPdf, MoreVert } from '@mui/icons-material'
import EventBus from '../../bus.ts'

interface IProps {
  step?: number,
  nextStep: any,
  prevStep: any,
  className?: any,
  validation: boolean,
  setValidation: any,
  slug: string,
  maxStep?: number,
}

export default ({ step, nextStep, prevStep, className, validation, setValidation, slug, maxStep }:IProps) => {
  const onCLickValidation = () => {
    if(validation) EventBus.$dispatch('toast-success', 'Disabled Form Validation')
    else EventBus.$dispatch('toast-success', 'Enabled Form Validation')
    setValidation(!validation)
  }
  const subitNext = () => {
    EventBus.$dispatch('submit-next-'+ slug + '-' + step, {})
  }
  return (
    <>
      <div className="fixed bottom-0 right-0 flex flex-col items-end gap-4 p-4">
        <Fab onClick={onCLickValidation} color={validation ? 'error' : 'success'}>
          { validation ? <NotInterested /> : <DoneAll /> }
        </Fab>
        <Fab color='success'>
          <PictureAsPdf />
        </Fab>
        <div className='flex gap-4'>
          <Fab onClick={prevStep} className={ step > 1 ? '!bg-white' : '' }>
            <ArrowBack />
          </Fab>
          <Fab onClick={subitNext} className={ step < maxStep ? '!bg-white' : '' }>
            <ArrowForward />
          </Fab>
          <Fab className='!bg-white'>
            <MoreVert />
          </Fab>
        </div>
      </div>
    </>
  )
}