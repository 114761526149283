import { Alert } from '@mui/material'
import React, { useState } from "react"
import EventBus from '../bus.ts'

export default () => {
  const [ messages, setMessages ] = useState<any>([])
  const clearMessage = (id) => {
    setMessages(messages.filter(m => m.id != id))
  }
  const setMessage = (type, msg) => {
    const id = Date.now()
    setMessages(messages.concat([{
      id, type,
      message: msg
    }]))
    setTimeout(() => {
      clearMessage(id)
    }, 1000)
  }
  EventBus.$on('toast-error', (msg) => setMessage('error', msg))
  EventBus.$on('toast-success', (msg) => setMessage('success', msg))
  EventBus.$on('toast-warning', (msg) => setMessage('warning', msg))
  EventBus.$on('toast-info', (msg) => setMessage('info', msg))
  return (
    <div className="fixed top-24 right-4 flex flex-col gap-4">
      {
        messages.map(msg => <Alert key={msg.id} variant="filled" severity={msg.type}>{ msg.message }</Alert>)
      }
    </div>
  )
}