import { Card } from '@mui/material'
import React, { useEffect, useState } from 'react'
import FormButtons from '../../FormFabs.tsx'
import GeneralHealthInformation from './steps/GeneralHealthInformation.tsx'
import MedicalCondition from './steps/MedicalCondition.tsx'
import Signature from './steps/Signature.tsx'
import HealthProvider from './steps/HealthProvider.tsx'
import Header from '../../Header.tsx'

export default () => {
  const SLUG = 'health-history-minor-preview'
  const MAX_STEPS = 4
  const [ step, setStep ] = useState(1)
  const [ validation, setValidation ] = useState(false)
  
  const nextStep = () => {
    if(step < MAX_STEPS) setStep(step + 1)
  }
  const prevStep = () => {
    if(step > 1) setStep(step - 1)
  }

  const [ formData, setFormData ] = useState({
    step_1: {
      name_of_current_physician: '',
      phone_of_current_physician: '',
      address_of_current_physician: '',
      preferred_pharmacy: '',
      date_of_last_exam: '',
    },
    step_2: {
      alergy: '',
      alergy_options: [],
      alergy_option_reason: '',
      has_other_medication: '',
      other_medication: '',
      hospitalized: '',
      hospitalized_reason: '',
    },
    step_3: {
      due_for_any_vaccinations: '',
      due_for_any_vaccinations_reason: '',
      antibiotic: '',
      antibiotic_reason: '',
      difficulty_at_birth: '',
      difficulty_at_birth_reason: '',
      other_medical_issue: '',
      other_medical_issue_options: '',
      other_medical_issue_option_other_reason: '',
    },
    step_4: {
      sign_as: '',
      signature: '',
    },
  })

  return (
    <>
      <Header title='Health History' />
      <div className='max-w-[1000px] mx-auto py-12'>
        <div className="flex gap-4 flex-wrap justify-between items-center">
          <p className="text-[#1d3243] font-bold text-[20px]">
            {
            step == 1 ? <span>Healthcare Provider</span>
            :step == 2 ? <span>General Health Information</span>
            :step == 3 ? <span>Medical Conditions</span>
            :step == 4 ? <span>Emergency Contact Information</span>
            :step == 5 ? <span>Work Information</span>
            :<span></span>
            }
          </p>
          <p className="text-[#1d3243] font-bold text-[16px]">Step {step}/{MAX_STEPS}</p>
        </div>
        <Card className='p-8 flex flex-col gap-4' elevation={3}>
          <HealthProvider className={`${step == 1 ? 'flex' : 'hidden'}`} step={1} prevStep={prevStep} nextStep={nextStep} validation={validation} slug={SLUG} formData={formData} setFormData={setFormData} />
          <GeneralHealthInformation className={`${step == 2 ? 'flex' : 'hidden'}`} showBack step={2} prevStep={prevStep} nextStep={nextStep} validation={validation} slug={SLUG} formData={formData} setFormData={setFormData} />
          <MedicalCondition className={`${step == 3 ? 'flex' : 'hidden'}`} showBack step={3} prevStep={prevStep} nextStep={nextStep} validation={validation} slug={SLUG} formData={formData} setFormData={setFormData} />
          <Signature className={`${step == 4 ? 'flex' : 'hidden'}`} showBack step={4} prevStep={prevStep} nextStep={nextStep} validation={validation} slug={SLUG} formData={formData} setFormData={setFormData} />
          {!validation && <p className='mb-0 text-[1rem] text-[#ff6e19] text-center'>PREVIEW MODE - form validation disabled!</p>}
        </Card>
      </div>
      <FormButtons prevStep={prevStep} nextStep={nextStep} step={step} validation={validation} setValidation={setValidation} slug={SLUG} maxStep={MAX_STEPS} />
    </>
  )
}