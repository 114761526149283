import React, { useState, useEffect } from 'react'
import { Radio, Button, Checkbox, RadioGroup, FormHelperText } from '@mui/joy'
import { ArrowForward, ArrowBack } from '@mui/icons-material'
import { useFormik } from 'formik'
import * as yup from 'yup'
import IFormProps from 'component/Form/IFormProps.ts'
import EventBus from '../../../../../bus.ts'
import { TextField, Select, MenuItem, FormControl, ListItemText } from '@mui/material'

export default (props: IFormProps) => {

  const PAIN_MEDICATIONS = ['Acetaminophen', 'Aspirin', 'Codeine', 'Demerol (Meperidine)', 'Hydrocodone (Vicodin/Lortan/Norco)', 'Ibuprofen', 'Percocet (Oxycodone)', 'Ultram (Tramadol)', 'Other']
  const ANXIETY_MEDICATIONS = ['Adderall', 'Cymbalta (Duloxetine)', 'Neurontin (Gabapentin)', 'Xanax (Alprazolam)', 'Ambien (Zolpidem)', 'Effexor (Venlafaxine)', 'Oleptro (Trazodone)', 'Wellbutrin (Buproprion)', 'Celexa (Citalopram)', 'Lexapro (Escitalopram)', 'Prozac (Fluoxetine)', 'Zoloft (Sertraline)', 'Other']
  const DIABETES_MEDICATIONS = ['Avapro (Irbesartan)', 'Crestor (Rosuvastatin)', 'Lipitor (Atorvastatin Calcium)', 'Metformin (Glucophage)', 'Plavix (Clopidogrel)', 'Tenormin (Atenolol)', 'Zestoretic (Lisinopril)', 'Coreg (Carvedilol)', 'Klor-Con (Potassium Chloride)', 'Lopressor (Metoprolol)', 'Microzide (Hydrochlorothiazide)', 'Pravachol (Pravastatin)', 'Toprol XL (Metoprolol)', 'Zocor (Simvastatin)', 'Coumadin (Warfarin)', 'Lasix (Furosemide)', 'Losartan (Cozaar)', 'Norvasc (Amlodipine)', 'Prinivil (Lisinopril)', 'Tricor (Fenofibrate)', 'Other']
  const ASTHAMA_MEDICATIONS = ['Allegra (Fexofenadine)', 'Claritin, Alavert (Loratadine)', 'Flonase (Fluticasone)', 'Singulair (Montelukast)', 'Zyrtec (Cetirizine)', 'Ventolin (Albuterol Inhaler)', 'Tavist (Clemastine)', 'Benadryl (Diphenhydramine)', 'Astelin (Azelastine)', 'Clarinex', 'Other']
  const ANTIBIOTICS = ['Azithromycin', 'Amoxicillin', 'Clindamycin', 'Cephalexin', 'Ciprofloxacin', 'Doxycycline', 'Tetracycline', 'Levofloxacin', 'Metronidazole', 'Other']

  const validationSchema = () => {
    if(!props.validation) return yup.object({})
    const obj = {}
    if(form1?.values.pain_medication == 'true') obj['pain_medication_options'] = yup.array().of(yup.string()).min(1, 'Required')
    if(form1?.values.anxiety_medication == 'true') obj['anxiety_medication_options'] = yup.array().of(yup.string()).min(1, 'Required')
    if(form1?.values.diabetes_medication == 'true') obj['diabetes_medication_options'] = yup.array().of(yup.string()).min(1, 'Required')
    if(form1?.values.asthma_medication == 'true') obj['asthma_medication_options'] = yup.array().of(yup.string()).min(1, 'Required')
    if(form1?.values.antibiotics == 'true') obj['antibiotics_options'] = yup.array().of(yup.string()).min(1, 'Required')
    if(form1?.values.has_other_medication == 'true') obj['other_medication'] = yup.string().required('Required')
    return yup.object(obj)
  }

  const form1 = useFormik({
    initialValues: props.formData['step_' + props.step],
    validationSchema: validationSchema,
    onSubmit: (values) => {
      try{
        props.setFormData({ ...props.formData, ['step_' + props.step]: values })
        props.nextStep()
      }catch(e){
        console.log(e)
      }
    },
  });

  useEffect(() => {
    EventBus.$on('submit-next-' + props.slug + '-' + props.step, form1.handleSubmit)
  }, [])
  
  useEffect(() => {
    Object.keys(props.formData['step_' + props.step]).forEach(key => form1?.setFieldValue(key, props.formData['step_' + props.step][key]))
  }, [ props.formData['step_' + props.step] ])

  return (
    <form onSubmit={form1.handleSubmit} className={'flex flex-col gap-4 ' + props.className}>
      <p className='mb-0 text-[1rem] text-[#000] text-center font-bold'>Please check all medications you are currently taking</p>
      <FormControl error={form1.touched.pain_medication && Boolean(form1.errors.pain_medication)}>
        <div className="flex gap-4 justify-between">
          <div>
            <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Are you taking any pain medications?</p>
            <p className='mb-0 text-[0.7rem] text-[#000]/[0.6]'>Acetaminophen, Aspirin, Codeine, Demerol (Meperidine), Hydrocodone (Vicodin/Lortan/Norco), Ibuprofen, Percocet (Oxycodone), Ultram (Tramadol), Other...</p>
          </div>
          <RadioGroup value={ form1.values.pain_medication } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='pain_medication' orientation='horizontal'>
            <Radio label='Yes' value='true' />
            <Radio label='No' value='false' />
          </RadioGroup>
        </div>
        {form1.touched.pain_medication && Boolean(form1.errors.pain_medication) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      { form1.values.pain_medication == 'true' &&
      <FormControl error={form1.touched.pain_medication_options && Boolean(form1.errors.pain_medication_options)} variant='standard'>
        <Select name="pain_medication_options" multiple value={form1.values.pain_medication_options} renderValue={(selected) => selected.join(', ')}
          onChange={e => {
            const { target: { value } } = e
            form1.setFieldValue('pain_medication_options', typeof value === 'string' ? value.split(',') : value, true)
          }}
        >
          {PAIN_MEDICATIONS.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={form1.values.pain_medication_options.indexOf(name) > -1} /> &nbsp;
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
        {form1.touched.pain_medication_options && Boolean(form1.errors.pain_medication_options) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>}
      {form1.values.pain_medication_options?.indexOf('Other') > -1 &&
      <TextField
        label="Please provide details here" variant="standard" name='pain_medication_option_reason' fullWidth
        value={ form1.values.pain_medication_option_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}

      <FormControl error={form1.touched.anxiety_medication && Boolean(form1.errors.anxiety_medication)}>
        <div className="flex gap-4 justify-between">
          <div>
            <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Are you taking any Antidepressants or Anxiety medications?</p>
            <p className='mb-0 text-[0.7rem] text-[#000]/[0.6]'>Adderall, Cymbalta (Duloxetine), Neurontin (Gabapentin), Xanax (Alprazolam), Ambien (Zolpidem), Effexor (Venlafaxine), Oleptro (Trazodone), Wellbutrin (Buproprion), Celexa (Citalopram), Lexapro (Escitalopram), Prozac (Fluoxetine), Zoloft (Sertraline), Other...</p>
          </div>
          <RadioGroup value={ form1.values.anxiety_medication } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='anxiety_medication' orientation='horizontal'>
            <Radio label='Yes' value='true' />
            <Radio label='No' value='false' />
          </RadioGroup>
        </div>
        {form1.touched.anxiety_medication && Boolean(form1.errors.anxiety_medication) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      { form1.values.anxiety_medication == 'true' &&
      <FormControl error={form1.touched.anxiety_medication_options && Boolean(form1.errors.anxiety_medication_options)} variant='standard'>
        <Select name="anxiety_medication_options" multiple value={form1.values.anxiety_medication_options} renderValue={(selected) => selected.join(', ')}
          onChange={e => {
            const { target: { value } } = e
            form1.setFieldValue('anxiety_medication_options', typeof value === 'string' ? value.split(',') : value, true)
          }}
        >
          {ANXIETY_MEDICATIONS.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={form1.values.anxiety_medication_options.indexOf(name) > -1} /> &nbsp;
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
        {form1.touched.anxiety_medication_options && Boolean(form1.errors.anxiety_medication_options) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>}
      {form1.values.anxiety_medication_options?.indexOf('Other') > -1 &&
      <TextField
        label="Please provide details here" variant="standard" name='anxiety_medication_option_reason' fullWidth
        value={ form1.values.anxiety_medication_option_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}

      <FormControl error={form1.touched.diabetes_medication && Boolean(form1.errors.diabetes_medication)}>
        <div className="flex gap-4 justify-between">
          <div>
            <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Are you taking any Diabetes, Cholesterol, or Blood Pressure medications?</p>
            <p className='mb-0 text-[0.7rem] text-[#000]/[0.6]'>Avapro (Irbesartan), Crestor (Rosuvastatin), Lipitor (Atorvastatin Calcium), Metformin (Glucophage), Plavix (Clopidogrel), Tenormin (Atenolol), Zestoretic (Lisinopril), Coreg (Carvedilol), Klor-Con (Potassium Chloride), Lopressor (Metoprolol), Microzide (Hydrochlorothiazide), Pravachol (Pravastatin), Toprol XL (Metoprolol), Zocor (Simvastatin), Coumadin (Warfarin), Lasix (Furosemide), Losartan (Cozaar), Norvasc (Amlodipine), Prinivil (Lisinopril), Tricor (Fenofibrate), Other...</p>
          </div>
          <RadioGroup value={ form1.values.diabetes_medication } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='diabetes_medication' orientation='horizontal'>
            <Radio label='Yes' value='true' />
            <Radio label='No' value='false' />
          </RadioGroup>
        </div>
        {form1.touched.diabetes_medication && Boolean(form1.errors.diabetes_medication) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      { form1.values.diabetes_medication == 'true' &&
      <FormControl error={form1.touched.diabetes_medication_options && Boolean(form1.errors.diabetes_medication_options)} variant='standard'>
        <Select name="diabetes_medication_options" multiple value={form1.values.diabetes_medication_options} renderValue={(selected) => selected.join(', ')}
          onChange={e => {
            const { target: { value } } = e
            form1.setFieldValue('diabetes_medication_options', typeof value === 'string' ? value.split(',') : value, true)
          }}
        >
          {DIABETES_MEDICATIONS.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={form1.values.diabetes_medication_options.indexOf(name) > -1} /> &nbsp;
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
        {form1.touched.diabetes_medication_options && Boolean(form1.errors.diabetes_medication_options) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>}
      {form1.values.diabetes_medication_options?.indexOf('Other') > -1 &&
      <TextField
        label="Please provide details here" variant="standard" name='diabetes_medication_option_reason' fullWidth
        value={ form1.values.diabetes_medication_option_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}

      <FormControl error={form1.touched.asthma_medication && Boolean(form1.errors.asthma_medication)}>
        <div className="flex gap-4 justify-between">
          <div>
            <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Are you taking any Allergy or Asthma medications?</p>
            <p className='mb-0 text-[0.7rem] text-[#000]/[0.6]'>Allegra (Fexofenadine), Claritin, Alavert (Loratadine), Flonase (Fluticasone), Singulair (Montelukast), Zyrtec (Cetirizine), Ventolin (Albuterol Inhaler), Tavist (Clemastine), Benadryl (Diphenhydramine), Astelin (Azelastine), Clarinex, Other...</p>
          </div>
          <RadioGroup value={ form1.values.asthma_medication } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='asthma_medication' orientation='horizontal'>
            <Radio label='Yes' value='true' />
            <Radio label='No' value='false' />
          </RadioGroup>
        </div>
        {form1.touched.asthma_medication && Boolean(form1.errors.asthma_medication) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      { form1.values.asthma_medication == 'true' &&
      <FormControl error={form1.touched.asthma_medication_options && Boolean(form1.errors.asthma_medication_options)} variant='standard'>
        <Select name="asthma_medication_options" multiple value={form1.values.asthma_medication_options} renderValue={(selected) => selected.join(', ')}
          onChange={e => {
            const { target: { value } } = e
            form1.setFieldValue('asthma_medication_options', typeof value === 'string' ? value.split(',') : value, true)
          }}
        >
          {ASTHAMA_MEDICATIONS.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={form1.values.asthma_medication_options.indexOf(name) > -1} /> &nbsp;
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
        {form1.touched.asthma_medication_options && Boolean(form1.errors.asthma_medication_options) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>}
      {form1.values.asthma_medication_options?.indexOf('Other') > -1 &&
      <TextField
        label="Please provide details here" variant="standard" name='asthma_medication_option_reason' fullWidth
        value={ form1.values.asthma_medication_option_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}

      <FormControl error={form1.touched.antibiotics && Boolean(form1.errors.antibiotics)}>
        <div className="flex gap-4 justify-between">
          <div>
            <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Are you taking any Antibiotics?</p>
            <p className='mb-0 text-[0.7rem] text-[#000]/[0.6]'>Azithromycin, Amoxicillin, Clindamycin, Cephalexin, Ciprofloxacin, Doxycycline, Tetracycline, Levofloxacin, Metronidazole, Other...</p>
          </div>
          <RadioGroup value={ form1.values.antibiotics } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='antibiotics' orientation='horizontal'>
            <Radio label='Yes' value='true' />
            <Radio label='No' value='false' />
          </RadioGroup>
        </div>
        {form1.touched.antibiotics && Boolean(form1.errors.antibiotics) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      { form1.values.antibiotics == 'true' &&
      <FormControl error={form1.touched.antibiotics_options && Boolean(form1.errors.antibiotics_options)} variant='standard'>
        <Select name="antibiotics_options" multiple value={form1.values.antibiotics_options} renderValue={(selected) => selected.join(', ')}
          onChange={e => {
            const { target: { value } } = e
            form1.setFieldValue('antibiotics_options', typeof value === 'string' ? value.split(',') : value, true)
          }}
        >
          {ANTIBIOTICS.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={form1.values.antibiotics_options.indexOf(name) > -1} /> &nbsp;
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
        {form1.touched.antibiotics_options && Boolean(form1.errors.antibiotics_options) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>}
      {form1.values.antibiotics_options?.indexOf('Other') > -1 &&
      <TextField
        label="Please provide details here" variant="standard" name='antibiotics_option_reason' fullWidth
        value={ form1.values.antibiotics_option_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}

      <FormControl error={form1.touched.has_other_medication && Boolean(form1.errors.has_other_medication)}>
        <div className="flex gap-4 justify-between">
          <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Are you currently taking any other medications or dietary supplements?</p>
          <RadioGroup value={ form1.values.has_other_medication } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='has_other_medication' orientation='horizontal'>
            <Radio label='Yes' value='true' />
            <Radio label='No' value='false' />
          </RadioGroup>
        </div>
        {form1.touched.has_other_medication && Boolean(form1.errors.has_other_medication) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      {
        form1.values.has_other_medication == 'true' &&
        <FormControl error={form1.touched.other_medication && Boolean(form1.errors.other_medication)}>
          <TextField
            label="List them and what they are treating*" variant="standard" name='other_medication' fullWidth
            value={ form1.values.other_medication } onChange={ form1.handleChange } onBlur={form1.handleBlur}
          />
          {form1.touched.other_medication && Boolean(form1.errors.other_medication) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
        </FormControl>
      }
      <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>* Mandatory fields</p>
      <div className="flex">
        {props.showBack && <Button variant='plain' onClick={props.prevStep}>
          <ArrowBack />
          Back
        </Button>}
        <Button color='primary' type='submit' className='!ml-auto'>
          Next
          <ArrowForward />
        </Button>
      </div>
    </form>
  )
}