import { Card } from '@mui/material'
import React, { useEffect, useState } from 'react'
import FormButtons from '../../FormFabs.tsx'
import Form from './steps/Form.tsx'
import axios from 'axios'
import { useSelector } from 'react-redux'

export default () => {
  const SLUG = 'update-profile-photo'
  const MAX_STEPS = 1
  const [ step, setStep ] = useState(1)
  const [ validation, setValidation ] = useState(false)
  const [ submitting, setSubmitting ] = useState(false)
  const [ fetching, setFetching ] = useState(true)
  const { patient, formName } = useSelector((state) => state)
  const [ patientForm, setPatientForm ] = useState({})
  
  const nextStep = () => {
    if(step < MAX_STEPS) setStep(step + 1)
    else submitForm()
  }
  const prevStep = () => {
    if(step > 1) setStep(step - 1)
  }

  const [ formData, setFormData ] = useState({
    step_1: {
      photo: ''
    },
  })

  const submitForm = async () => {
    if(submitting) return
    setSubmitting(true)
    try{
      const payload = {
        form_activation_id: patient?.form_activation_id,
        patient_id: patient?.patient?.id,
        form_name: formName,
        form_data: formData
      };
      const { data } = patientForm?.id ? await axios.put(`${process.env.REACT_APP_CARESUITE_APP_URL}/public/api/v1/patient_forms/${patientForm?.id}`, payload) : await axios.post(`${process.env.REACT_APP_CARESUITE_APP_URL}/public/api/v1/patient_forms`, payload);
      setPatientForm(data)
    }catch(e){
      console.log(e)
    }
    setSubmitting(false)
  }

  const fetchData = async () => {
    console.log(patient)
    const { data } = await axios.get(`${process.env.REACT_APP_CARESUITE_APP_URL}/public/api/v1/patient_forms/show_by_form_name`,{
      params: { form_activation_id: patient?.form_activation_id }
    });
    if(data){
      setPatientForm(data)
      setFormData(data.form_data)
    }
    setFetching(false)
  }
  
  useEffect(() => { fetchData() }, []);
  return (
    <>
      <div className='max-w-[1000px] mx-auto py-12'>
        <div className="flex gap-4 flex-wrap justify-between items-center">
          <p className="text-[#1d3243] font-bold text-[20px]"></p>
          <p className="text-[#1d3243] font-bold text-[16px]">Step {step}/{MAX_STEPS}</p>
        </div>
        <Card className='p-8 flex flex-col gap-4' elevation={3}>
          <Form className={`${step == 1 ? 'flex' : 'hidden'}`} step={1} prevStep={prevStep} nextStep={nextStep} validation={validation} slug={SLUG} formData={formData} setFormData={setFormData} />
          {!validation && <p className='mb-0 text-[1rem] text-[#ff6e19] text-center'>PREVIEW MODE - form validation disabled!</p>}
        </Card>
      </div>
      <FormButtons prevStep={prevStep} nextStep={nextStep} step={step} validation={validation} setValidation={setValidation} slug={SLUG} maxStep={MAX_STEPS} />
    </>
  )
}