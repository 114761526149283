import React, { useState, useEffect } from 'react'
import { Radio, Button, Checkbox, RadioGroup, FormHelperText } from '@mui/joy'
import { ArrowForward, ArrowBack } from '@mui/icons-material'
import { useFormik } from 'formik'
import * as yup from 'yup'
import IFormProps from 'component/Form/IFormProps.ts'
import EventBus from '../../../../../bus.ts'
import { TextField, FormControl, Select, MenuItem, ListItemText } from '@mui/material'

export default (props: IFormProps) => {

  const ALERGIC_REACTION_OPTIONS = [
    'Aspirin', 'Ibuprofen', 'Acetaminophen', 'Codeine', 'Penicillin', 'Erythromycin', 'Tetracycline', 'Acrylic', 'Sulfa', 'Local anesthetic', 'Fluoride', 'Metals', 'Iodine', 'Barbiturates or sedatives', 'Latex', 'Other'
  ]
  
  const validationSchema = () => {
    if(!props.validation) return yup.object({})
    const obj = {
      under_care_of_physician: yup.string().required('Required'),
      injury_of_illness_treatment: yup.string().required('Required'),
      injury_of_illness_hospitalized: yup.string().required('Required'),
      antibiotic_before_dental_tratment: yup.string().required('Required'),
      use_tobacco: yup.string().required('Required'),
      alergic_reaction: yup.string().required('Required'),
      pregnant: yup.string().required('Required'),
      breastfeeding: yup.string().required('Required'),
    }
    if(form1?.values.under_care_of_physician == 'true') obj['name_of_current_physician'] = yup.string().required('Required')
    if(form1?.values.alergic_reaction == 'true') obj['alergic_reaction_options'] = yup.array().of(yup.string()).min(1, 'Required')
    return yup.object(obj)
  }

  const form1 = useFormik({
    initialValues: props.formData['step_' + props.step],
    validationSchema: validationSchema,
    onSubmit: (values) => {
      try{
        props.setFormData({ ...props.formData, ['step_' + props.step]: values })
        props.nextStep()
      }catch(e){
        console.log(e)
      }
    },
  });

  useEffect(() => {
    EventBus.$on('submit-next-' + props.slug + '-' + props.step, form1.handleSubmit)
  }, [])
  
  useEffect(() => {
    Object.keys(props.formData['step_' + props.step]).forEach(key => form1?.setFieldValue(key, props.formData['step_' + props.step][key]))
  }, [ props.formData['step_' + props.step] ])

  return (
    <form onSubmit={form1.handleSubmit} className={'flex flex-col gap-4 ' + props.className}>
      <FormControl error={form1.touched.under_care_of_physician && Boolean(form1.errors.under_care_of_physician)} variant='standard'>
        <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Are you currently under the care of a physician?*</p>
        <RadioGroup value={ form1.values.under_care_of_physician } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='under_care_of_physician' orientation='horizontal'>
          <Radio label='Yes' value='true' />
          <Radio label='No' value='false' />
        </RadioGroup>
        {form1.touched.under_care_of_physician && Boolean(form1.errors.under_care_of_physician) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      { form1?.values.under_care_of_physician == 'true' &&
        <>
          <FormControl error={form1.touched.name_of_current_physician && Boolean(form1.errors.name_of_current_physician)}>
            <TextField
              label="Name of current physician*" variant="standard" name='name_of_current_physician' fullWidth
              value={ form1.values.name_of_current_physician } onChange={ form1.handleChange } onBlur={form1.handleBlur}
            />
            {form1.touched.name_of_current_physician && Boolean(form1.errors.name_of_current_physician) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
          </FormControl>
          <TextField
            label="Physician phone number" variant="standard" name='physician_phone_no' fullWidth
            value={ form1.values.physician_phone_no } onChange={ form1.handleChange } onBlur={form1.handleBlur}
          />
        </>
      }
      <TextField
        label="Preferred pharmacy" variant="standard" name='preferred_pharmacy' fullWidth
        value={ form1.values.preferred_pharmacy } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />
      <TextField
        label="Date of last physical exam" variant="standard" name='date_of_last_physical_exam' fullWidth
        value={ form1.values.date_of_last_physical_exam } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />
      <p className='mb-0 text-[0.8rem] text-[#000]/[0.6] -mt-2'>Provide the address for the location you prefer</p>
      <FormControl error={form1.touched.injury_of_illness_treatment && Boolean(form1.errors.injury_of_illness_treatment)}>
        <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Are you presently being treated for any injury or illness?*</p>
        <RadioGroup value={ form1.values.injury_of_illness_treatment } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='injury_of_illness_treatment' orientation='horizontal'>
          <Radio label='Yes' value='true' />
          <Radio label='No' value='false' />
        </RadioGroup>
        {form1.touched.injury_of_illness_treatment && Boolean(form1.errors.injury_of_illness_treatment) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      { form1?.values.injury_of_illness_treatment == 'true' &&
      <TextField
        label="Preferred pharmacy" variant="standard" name='preferred_pharmacy_reason' fullWidth
        value={ form1.values.injury_of_illness_treatment_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}
      <FormControl error={form1.touched.injury_of_illness_hospitalized && Boolean(form1.errors.injury_of_illness_hospitalized)}>
        <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Have you ever been hospitalized for an injury or illness?*</p>
        <RadioGroup value={ form1.values.injury_of_illness_hospitalized } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='injury_of_illness_hospitalized' orientation='horizontal'>
          <Radio label='Yes' value='true' />
          <Radio label='No' value='false' />
        </RadioGroup>
        {form1.touched.injury_of_illness_hospitalized && Boolean(form1.errors.injury_of_illness_hospitalized) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      { form1?.values.injury_of_illness_hospitalized == 'true' &&
      <TextField
        label="Preferred pharmacy" variant="standard" name='injury_of_illness_hospitalized_reason' fullWidth
        value={ form1.values.injury_of_illness_hospitalized_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}
      <FormControl error={form1.touched.antibiotic_before_dental_tratment && Boolean(form1.errors.antibiotic_before_dental_tratment)}>
        <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Are you required to pre-med with antibiotics before dental treatment?*</p>
        <RadioGroup value={ form1.values.antibiotic_before_dental_tratment } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='antibiotic_before_dental_tratment' orientation='horizontal'>
          <Radio label='Yes' value='true' />
          <Radio label='No' value='false' />
        </RadioGroup>
        {form1.touched.antibiotic_before_dental_tratment && Boolean(form1.errors.antibiotic_before_dental_tratment) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      { form1?.values.antibiotic_before_dental_tratment == 'true' &&
      <TextField
        label="Preferred pharmacy" variant="standard" name='antibiotic_before_dental_tratment_reason' fullWidth
        value={ form1.values.antibiotic_before_dental_tratment_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}
      <FormControl error={form1.touched.use_tobacco && Boolean(form1.errors.use_tobacco)}>
        <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Do you use or have you ever used tobacco?*</p>
        <RadioGroup value={ form1.values.use_tobacco } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='use_tobacco' orientation='horizontal'>
          <Radio label='Yes' value='true' />
          <Radio label='No' value='false' />
        </RadioGroup>
        {form1.touched.use_tobacco && Boolean(form1.errors.use_tobacco) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      { form1?.values.use_tobacco == 'true' &&
      <TextField
        label="Preferred pharmacy" variant="standard" name='use_tobacco_reason' fullWidth
        value={ form1.values.use_tobacco_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}
      <FormControl error={form1.touched.alergic_reaction && Boolean(form1.errors.alergic_reaction)}>
        <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Have you ever had an allergic reaction?*</p>
        <p className='mb-0 text-[0.7rem] text-[#000]/[0.6]'>Aspirin, Ibuprofen, Acetaminophen, Codeine, Penicillin, Erythromycin, Tetracycline, Acrylic, Sulfa, Local anesthetic, Fluoride, Metals, Iodine, Barbiturates or sedatives, Latex, Other...</p>
        <RadioGroup value={ form1.values.alergic_reaction } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='alergic_reaction' orientation='horizontal'>
          <Radio label='Yes' value='true' />
          <Radio label='No' value='false' />
        </RadioGroup>
        {form1.touched.alergic_reaction && Boolean(form1.errors.alergic_reaction) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      { form1.values.alergic_reaction == 'true' &&
      <FormControl error={form1.touched.alergic_reaction_options && Boolean(form1.errors.alergic_reaction_options)} variant='standard'>
        <Select name="alergic_reaction_options" multiple value={form1.values.alergic_reaction_options} renderValue={(selected) => selected.join(', ')}
          onChange={e => {
            const { target: { value } } = e
            form1.setFieldValue('alergic_reaction_options', typeof value === 'string' ? value.split(',') : value, true)
          }}
        >
          {ALERGIC_REACTION_OPTIONS.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={form1.values.alergic_reaction_options.indexOf(name) > -1} /> &nbsp;
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
        {form1.touched.alergic_reaction_options && Boolean(form1.errors.alergic_reaction_options) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>}
      {form1.values.alergic_reaction_options?.indexOf('Other') > -1 &&
      <TextField
        label="Please provide details here" variant="standard" name='alergic_reaction_option_other_reason' fullWidth
        value={ form1.values.alergic_reaction_option_other_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}
      <FormControl error={form1.touched.pregnant && Boolean(form1.errors.pregnant)}>
        <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Are you pregnant or planning to become pregnant?*</p>
        <RadioGroup value={ form1.values.pregnant } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='pregnant' orientation='horizontal'>
          <Radio label='Yes' value='true' />
          <Radio label='No' value='false' />
        </RadioGroup>
        {form1.touched.pregnant && Boolean(form1.errors.pregnant) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      { form1?.values.pregnant == 'true' &&
      <TextField
        label="Preferred pharmacy" variant="standard" name='pregnant_reason' fullWidth
        value={ form1.values.pregnant_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}
      <FormControl error={form1.touched.breastfeeding && Boolean(form1.errors.breastfeeding)}>
        <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Are you currently breastfeeding?*</p>
        <RadioGroup value={ form1.values.breastfeeding } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='breastfeeding' orientation='horizontal'>
          <Radio label='Yes' value='true' />
          <Radio label='No' value='false' />
        </RadioGroup>
        {form1.touched.breastfeeding && Boolean(form1.errors.breastfeeding) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      { form1?.values.breastfeeding == 'true' &&
      <TextField
        label="Preferred pharmacy" variant="standard" name='breastfeeding_reason' fullWidth
        value={ form1.values.breastfeeding_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}
      <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>* Mandatory fields</p>
      <div className="flex">
        {props.showBack && <Button variant='plain' onClick={props.prevStep}>
          <ArrowBack />
          Back
        </Button>}
        <Button color='primary' type='submit' className='!ml-auto'>
          Next
          <ArrowForward />
        </Button>
      </div>
    </form>
  )
}