import { Card } from '@mui/material'
import React, { useEffect, useState } from 'react'
import FormButtons from '../../FormFabs.tsx'
import BasicInfo from './steps/BasicInfo.tsx'
import AddressInfo from './steps/AddressInfo.tsx'
import ContactInfo from './steps/ContactInfo.tsx'
import EmergencyContact from './steps/EmergencyContact.tsx'
import WorkInfo from './steps/WorkInfo.tsx'
import Header from '../../Header.tsx'
import { useSelector } from 'react-redux'
import axios from 'axios'

export default () => {
  const SLUG = 'contact-details'
  const MAX_STEPS = 5
  const [ step, setStep ] = useState(1)
  const [ validation, setValidation ] = useState(false)
  
  const nextStep = () => {
    if(step < MAX_STEPS) setStep(step + 1)
  }
  const prevStep = () => {
    if(step > 1) setStep(step - 1)
  }

  const [ formData, setFormData ] = useState({
    step_1: {
      photo: '',
    },
    step_2: {
      email: '',
      phone: '',
      home_phone: '',
    },
    step_3: {
      address: '',
      city: '',
      state: '',
      zip: '',
    },
    step_4: {
      emergency_contact: 'true',
      full_name: '',
      contact_phone: '',
      relationship: '',
    },
    step_5: {
      provide_work_address: 'true',
      employer: '',
      occupation: '',
      address_work: '',
      city_work: '',
      state_work: '',
      zip_work: '',
    },
  })
  
  return (
    <>
      <Header title='Contact Details' />
      <div className='max-w-[1000px] mx-auto py-12'>
        <div className="flex gap-4 flex-wrap justify-between items-center">
          <p className="text-[#1d3243] font-bold text-[20px]">
            {
            step == 1 ? <span>Basic Information</span>
            :step == 2 ? <span>Contact Information</span>
            :step == 3 ? <span>Address</span>
            :step == 4 ? <span>Emergency Contact Information</span>
            :step == 5 ? <span>Work Information</span>
            :<span></span>
            }
          </p>
          <p className="text-[#1d3243] font-bold text-[16px]">Step {step}/{MAX_STEPS}</p>
        </div>
        <Card className='p-8 flex flex-col gap-4' elevation={3}>
          <BasicInfo className={`${step == 1 ? 'flex' : 'hidden'}`} step={1} prevStep={prevStep} nextStep={nextStep} validation={validation} slug={SLUG} formData={formData} setFormData={setFormData} />
          <ContactInfo className={`${step == 2 ? 'flex' : 'hidden'}`} showBack step={2} prevStep={prevStep} nextStep={nextStep} validation={validation} slug={SLUG} formData={formData} setFormData={setFormData} />
          <AddressInfo className={`${step == 3 ? 'flex' : 'hidden'}`} showBack step={3} prevStep={prevStep} nextStep={nextStep} validation={validation} slug={SLUG} formData={formData} setFormData={setFormData} />
          <EmergencyContact className={`${step == 4 ? 'flex' : 'hidden'}`} showBack step={4} prevStep={prevStep} nextStep={nextStep} validation={validation} slug={SLUG} formData={formData} setFormData={setFormData} />
          <WorkInfo className={`${step == 5 ? 'flex' : 'hidden'}`} showBack step={5} prevStep={prevStep} nextStep={nextStep} validation={validation} slug={SLUG} formData={formData} setFormData={setFormData} />
          {!validation && <p className='mb-0 text-[1rem] text-[#ff6e19] text-center'>PREVIEW MODE - form validation disabled!</p>}
        </Card>
      </div>
      <FormButtons prevStep={prevStep} nextStep={nextStep} step={step} validation={validation} setValidation={setValidation} slug={SLUG} maxStep={MAX_STEPS} />
    </>
  )
}