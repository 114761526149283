import React, { useEffect, useState } from "react";
import { useParams, useSearchParams, Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";
import * as actionTypes from "../../actionTypes/actionTypes";
import { Result, Spin } from 'antd';
import classes from './verify.module.scss';
import classNames from "classnames/index";

const Verify = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const { officeName, formName, token } = useParams();
  const isAdmin = searchParams.get('is_admin');

  useEffect(() => {
    if (token) {
      verifyToken(token);
    }
  }, []);

  const verifyToken = async (token) => {
    try {
      const { data } = await axios.post(`${process.env.REACT_APP_CARESUITE_APP_URL}/public/api/v1/patient_forms/verify`, { token: token});
      dispatch({
        type: actionTypes.SET_FORM_NAME,
        payload: formName,
      });

      dispatch({
        type: actionTypes.SET_OFFICE_NAME,
        payload: officeName,
      });

      const patient= {
        ...data,
        isAdmin: isAdmin ? true : false
      } ;
      console.log(patient)
      dispatch({
        type: actionTypes.SET_PATIENT,
        payload: patient,
      });
      setError('');
    } catch (e) {
      console.log(e)
      setError('Invalid Token');
    } finally {
      setIsLoading(false);
    }
  };

  if (!token) {
    return (<div className={classNames(classes.container)}>
      <Result
        status="403"
        title="403"
        subTitle="Sorry, you are not authorized to access this page."
      />
    </div>)
  }

  if (isLoading) {
    return (<div className={classNames(classes.container)}>
      <Spin />
    </div>)
  }

  if (error) {
    return (<div className={classNames(classes.container)}>
      <Result
        status="403"
        title="403"
        subTitle="Sorry, you are not authorized to access this page."
      />
    </div>)
  }

  return <Navigate to={'/'}/>
};

export default Verify;