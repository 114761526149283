import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { Result } from 'antd';
import classNames from "classnames/index";
import classes from './component/Verify/verify.module.scss';
import IdleTimerModal from "./component/IdleTimerModal/IdleTimerModal"

const ProtectedRoute = ({ children  }) => {
  const { patient, isSmsVerified } = useSelector(state => state);
  const location = useLocation();

  if (!patient) {
    return (<div className={classNames(classes.container)}>
      <Result
        status="403"
        title="403"
        subTitle="Sorry, you are not authorized to access this page."
      />
    </div>)
  }

  if (!isSmsVerified && location.pathname !== '/') {
    return <Navigate to={"/"} />
  }

  return <><IdleTimerModal />{children}</>;
};

export default ProtectedRoute;