import React from 'react'

export default () => {
  return (
    <div className={'flex flex-col gap-4 '}>
      <p className="text-[#1d3243] text-[15px]">Financial Policy</p>
      <p className="text-[#1d3243] text-[15px]">Thank you for choosing us as your dental care provider. We are committed to your treatment being successful. Please understand that payment of your bill is considered part of your treatment. The following is a statement of our financial policy which we require that you read and sign prior to any treatment. It is our hope that this policy will facilitate open communication between us and help avoid potential misunderstandings, allowing you to always make the best choices related to your care.</p>
      <p className="text-[#1d3243] text-[15px]">INSURANCE:</p>
      <p className="text-[#1d3243] text-[15px]">Please remember your insurance policy is a contract between you and your insurance company. We are not a party to that contract. As a courtesy to you, our office provides certain services, including a pre-treatment estimate which we send to the insurance company at your request. It is physically impossible for us to have the knowledge and keep track of every aspect of your insurance. It is up to you to contact your insurance company and inquire as to what benefits your employer has purchased for you. If you have any questions concerning the pre-treatment estimate and/or fees for service, it is your responsibility to have these answered prior to treatment to minimize any confusion on your behalf.</p>
      <p className="text-[#1d3243] text-[15px]">Please be aware some or perhaps all of the services provided may or may not be covered by your insurance policy. Any balance is your responsibility whether or not your insurance company pays any portion.</p>
      <p className="text-[#1d3243] text-[15px]">PAYMENT:</p>
      <p className="text-[#1d3243] text-[15px]">Understand that regardless of any insurance status, you are responsible for the balance due on your account. You are responsible for any and all professional services rendered. This includes but is not limited to: dental fees, surgical procedures, tests, office procedures, medications and also any other services not directly provided by the dentist.</p>
      <p className="text-[#1d3243] text-[15px]">FULL PAYMENT is due at the time of service. If insurance benefits apply, ESTIMATED PATIENT CO-PAYMENTS and DEDUCTIBLES are due at the time of service, unless other arrangements are made.</p>
      <p className="text-[#1d3243] text-[15px]">UNPAID BALANCE over 90 days old will be subject to a monthly interest of 1.0% (APR 12%). If payment is delinquent, the patient will be responsible for payment of collection, attorney’s fees, and court costs associated with the recovery of the monies due on the account.</p>
      <p className="text-[#1d3243] text-[15px]">MISSED APPOINTMENTS:</p>
      <p className="text-[#1d3243] text-[15px]">Unless we receive notice of cancellation 48 hours in advance, you will be charged ﻿$50﻿. Please help us maintain the highest quality of care by keeping scheduled appointments.</p>
      <p className="text-[#1d3243] text-[15px]">I have read, understand and agree to the terms and conditions of this Financial Agreement.</p>
    </div>
  )
}