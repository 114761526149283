import React, { useState, useEffect } from 'react'
import { Radio, Button, Checkbox, RadioGroup, FormHelperText } from '@mui/joy'
import { ArrowForward, ArrowBack } from '@mui/icons-material'
import { useFormik } from 'formik'
import * as yup from 'yup'
import IFormProps from 'component/Form/IFormProps.ts'
import EventBus from '../../../../../bus.ts'
import { TextField, FormControl, Select, MenuItem, ListItemText } from '@mui/material'
import { Dayjs } from 'dayjs'
import { DateField } from '@mui/x-date-pickers'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

export default (props: IFormProps) => {

  const onFilePicked = e => {
    let files = e.target.files
    var reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = function () {
      console.log({ name: e.target.name, value: reader.result })
      form1.setFieldValue(e.target.name, reader.result)
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }
  
  const validationSchema = () => {
    if(!props.validation) return yup.object({})
    const obj = {
      secondary_dental_insurance: yup.string().required('Required'),
    }
    if(form1?.values.update_card_photo == 'true') {
      obj['primary_insurance_front'] = yup.string().required('Required')
      obj['primary_insurance_back'] = yup.string().required('Required')
    }
    if(form1?.values.relation_with_insurance_holder != 'self') {
      obj['policy_holder_name'] = yup.string().required('Required')
      obj['policy_holder_dob'] = yup.string().required('Required')
      obj['policy_holder_ssn'] = yup.string().required('Required')
      obj['policy_holder_address'] = yup.string().required('Required')
      obj['policy_holder_city'] = yup.string().required('Required')
      obj['policy_holder_state'] = yup.string().required('Required')
      obj['policy_holder_zip'] = yup.string().required('Required')
      obj['policy_holder_employer'] = yup.string().required('Required')
      obj['insurance_company'] = yup.string().required('Required')
    }
    if(form1?.values.secondary_dental_insurance == 'true') {
      obj['id_number'] = yup.string().required('Required')
      obj['group_number'] = yup.string().required('Required')
    }
    return yup.object(obj)
  }

  const form1 = useFormik({
    initialValues: props.formData['step_' + props.step],
    validationSchema: validationSchema,
    onSubmit: (values) => {
      try{
        props.setFormData({ ...props.formData, ['step_' + props.step]: values })
        props.nextStep()
      }catch(e){
        console.log(e)
      }
    },
  });

  useEffect(() => {
    EventBus.$on('submit-next-' + props.slug + '-' + props.step, form1.handleSubmit)
  }, [])
  
  useEffect(() => {
    Object.keys(props.formData['step_' + props.step]).forEach(key => form1?.setFieldValue(key, props.formData['step_' + props.step][key]))
  }, [ props.formData['step_' + props.step] ])

  return (
    <form onSubmit={form1.handleSubmit} className={'flex flex-col gap-4 ' + props.className}>
      
      <FormControl error={form1.touched.secondary_dental_insurance && Boolean(form1.errors.secondary_dental_insurance)}>
        <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Do you have a secondary dental insurance?*</p>
        <RadioGroup value={ form1.values.secondary_dental_insurance } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='secondary_dental_insurance' orientation='horizontal'>
          <Radio label='Yes' value='true' />
          <Radio label='No' value='false' />
        </RadioGroup>
        {form1.touched.secondary_dental_insurance && Boolean(form1.errors.secondary_dental_insurance) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      { form1.values.secondary_dental_insurance == 'true' &&
      <>
        <FormControl error={form1.touched.update_card_photo && Boolean(form1.errors.update_card_photo)}>
          <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Would you like to upload insurance card photo?*</p>
          <RadioGroup value={ form1.values.update_card_photo } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='update_card_photo' orientation='horizontal'>
            <Radio label='Yes' value='true' />
            <Radio label='No' value='false' />
          </RadioGroup>
          {form1.touched.update_card_photo && Boolean(form1.errors.update_card_photo) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
        </FormControl>
        { form1.values.update_card_photo == 'true' &&
        <>
          <FormControl error={form1.touched.secondary_insurance_front && Boolean(form1.errors.secondary_insurance_front)}>
            <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Take a picture of the FRONT of your secondary insurance card*</p>
            <TextField variant="standard" name='secondary_insurance_front' fullWidth type='file' onChange={ onFilePicked } />
            {form1.touched.secondary_insurance_front && Boolean(form1.errors.secondary_insurance_front) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
          </FormControl>
          <FormControl error={form1.touched.secondary_insurance_back && Boolean(form1.errors.secondary_insurance_back)}>
            <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Take a picture of the BACK of your secondary insurance card*</p>
            <TextField variant="standard" name='secondary_insurance_back' fullWidth type='file' onChange={ onFilePicked } />
            {form1.touched.secondary_insurance_back && Boolean(form1.errors.secondary_insurance_back) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
          </FormControl>
        </>
        }
        <FormControl error={form1.touched.relation_with_insurance_holder && Boolean(form1.errors.relation_with_insurance_holder)}>
          <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Patient’s relationship to the Insurance Holder*</p>
          <RadioGroup value={ form1.values.relation_with_insurance_holder } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='relation_with_insurance_holder' orientation='horizontal'>
            <Radio label='Self' value='self' />
            <Radio label='Spouse' value='spouse' />
            <Radio label='Child' value='child' />
            <Radio label='Other' value='other' />
          </RadioGroup>
          {form1.touched.relation_with_insurance_holder && Boolean(form1.errors.relation_with_insurance_holder) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
        </FormControl>
        { form1.values.relation_with_insurance_holder != 'self' && form1.values.relation_with_insurance_holder != '' &&
        <>
          <FormControl error={form1.touched.policy_holder_name && Boolean(form1.errors.policy_holder_name)}>
            <TextField
              label="Policy Holder’s Name*" variant="standard" name='policy_holder_name' fullWidth
              value={ form1.values.policy_holder_name } onChange={ form1.handleChange } onBlur={form1.handleBlur}
            />
            {form1.touched.policy_holder_name && Boolean(form1.errors.policy_holder_name) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
          </FormControl>
          <FormControl error={form1.touched.policy_holder_dob && Boolean(form1.errors.policy_holder_dob)}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker']}>
                <DateField
                  label="Policy Holder’s Date of Birth*" variant="standard" name='policy_holder_dob' fullWidth
                  onChange={ ( v: Dayjs | null ) => {
                    if(v?.isValid()) form1.setFieldValue('policy_holder_dob', v?.toISOString().substring(0, 10), true)
                  }}
                  onBlur={form1.handleBlur}
                />
              </DemoContainer>
            </LocalizationProvider>
            {form1.touched.policy_holder_dob && Boolean(form1.errors.policy_holder_dob) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
          </FormControl>
          <FormControl error={form1.touched.policy_holder_ssn && Boolean(form1.errors.policy_holder_ssn)}>
            <TextField
              label="Policy Holder’s SSN*" variant="standard" name='policy_holder_ssn' fullWidth
              value={ form1.values.policy_holder_ssn } onChange={ form1.handleChange } onBlur={form1.handleBlur}
            />
            {form1.touched.policy_holder_ssn && Boolean(form1.errors.policy_holder_ssn) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
          </FormControl>
          <FormControl error={form1.touched.policy_holder_address && Boolean(form1.errors.policy_holder_address)}>
            <TextField
              label="Policy Holder’s Address*" variant="standard" name='policy_holder_address' fullWidth
              value={ form1.values.policy_holder_address } onChange={ form1.handleChange } onBlur={form1.handleBlur}
            />
            {form1.touched.policy_holder_address && Boolean(form1.errors.policy_holder_address) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
          </FormControl>
          <FormControl error={form1.touched.policy_holder_city && Boolean(form1.errors.policy_holder_city)}>
            <TextField
              label="Policy Holder’s City*" variant="standard" name='policy_holder_city' fullWidth
              value={ form1.values.policy_holder_city } onChange={ form1.handleChange } onBlur={form1.handleBlur}
            />
            {form1.touched.policy_holder_city && Boolean(form1.errors.policy_holder_city) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
          </FormControl>
          <FormControl error={form1.touched.policy_holder_state && Boolean(form1.errors.policy_holder_state)}>
            <TextField
              label="Policy Holder’s State*" variant="standard" name='policy_holder_state' fullWidth
              value={ form1.values.policy_holder_state } onChange={ form1.handleChange } onBlur={form1.handleBlur}
            />
            {form1.touched.policy_holder_state && Boolean(form1.errors.policy_holder_state) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
          </FormControl>
          <FormControl error={form1.touched.policy_holder_zip && Boolean(form1.errors.policy_holder_zip)}>
            <TextField
              label="Policy Holder’s ZIP*" variant="standard" name='policy_holder_zip' fullWidth
              value={ form1.values.policy_holder_zip } onChange={ form1.handleChange } onBlur={form1.handleBlur}
            />
            {form1.touched.policy_holder_zip && Boolean(form1.errors.policy_holder_zip) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
          </FormControl>
          <FormControl error={form1.touched.policy_holder_employer && Boolean(form1.errors.policy_holder_employer)}>
            <TextField
              label="Policy Holder’s Employer*" variant="standard" name='policy_holder_employer' fullWidth
              value={ form1.values.policy_holder_employer } onChange={ form1.handleChange } onBlur={form1.handleBlur}
            />
            {form1.touched.policy_holder_employer && Boolean(form1.errors.policy_holder_employer) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
          </FormControl>
        </>
        }
        <FormControl error={form1.touched.insurance_company && Boolean(form1.errors.insurance_company)}>
          <TextField
            label="Dental Insurance Company*" variant="standard" name='insurance_company' fullWidth
            value={ form1.values.insurance_company } onChange={ form1.handleChange } onBlur={form1.handleBlur}
          />
          {form1.touched.insurance_company && Boolean(form1.errors.insurance_company) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
        </FormControl>
        <FormControl error={form1.touched.id_number && Boolean(form1.errors.id_number)}>
          <TextField
            label="ID Number*" variant="standard" name='id_number' fullWidth
            value={ form1.values.id_number } onChange={ form1.handleChange } onBlur={form1.handleBlur}
          />
          {form1.touched.id_number && Boolean(form1.errors.id_number) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
        </FormControl>
        <FormControl error={form1.touched.group_number && Boolean(form1.errors.group_number)}>
          <TextField
            label="Group Number*" variant="standard" name='group_number' fullWidth
            value={ form1.values.group_number } onChange={ form1.handleChange } onBlur={form1.handleBlur}
          />
          {form1.touched.group_number && Boolean(form1.errors.group_number) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
        </FormControl>
        <FormControl error={form1.touched.phone_in_insurance && Boolean(form1.errors.phone_in_insurance)}>
          <TextField
            label="Phone number on the back of your insurance card" variant="standard" name='phone_in_insurance' fullWidth
            value={ form1.values.phone_in_insurance } onChange={ form1.handleChange } onBlur={form1.handleBlur}
          />
          {form1.touched.phone_in_insurance && Boolean(form1.errors.phone_in_insurance) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
        </FormControl>
        <FormControl error={form1.touched.address_in_insurance && Boolean(form1.errors.address_in_insurance)}>
          <TextField
            label="Address on the back of your insurance card" variant="standard" name='address_in_insurance' fullWidth
            value={ form1.values.address_in_insurance } onChange={ form1.handleChange } onBlur={form1.handleBlur}
          />
          {form1.touched.address_in_insurance && Boolean(form1.errors.address_in_insurance) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
        </FormControl>
      </>
      }

      <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>* Mandatory fields</p>
      <div className="flex">
        {props.showBack && <Button variant='plain' onClick={props.prevStep}>
          <ArrowBack />
          Back
        </Button>}
        <Button color='primary' type='submit' className='!ml-auto'>
          Next
          <ArrowForward />
        </Button>
      </div>
    </form>
  )
}