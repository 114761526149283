import { TextField } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { Button, FormControl, FormHelperText, Radio, RadioGroup } from '@mui/joy'
import { ArrowBack, ArrowForward } from '@mui/icons-material'
import IFormProps from 'component/Form/IFormProps.ts';
import { useFormik } from 'formik'
import * as yup from 'yup'
import EventBus from '../../../../../bus.ts';
import { LocalizationProvider, DateField } from '@mui/x-date-pickers';
import { Dayjs } from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo/DemoContainer.js';

export default (props: IFormProps) => {

  const validationSchema = () => {
    if(!props.validation) return yup.object({})
    if(form1 != undefined && form1.values.add_secondary_info == 'true') return yup.object({
      add_secondary_info: yup.string().required('Required').nullable(),
      full_name: yup.string().required('Required').nullable(),
      relationship: yup.string().required('Required').nullable(),
      dob: yup.string().required('Required').nullable(),
      phone: yup.string().required('Required').nullable(),
      legal_custody: yup.string().required('Required').nullable(),
    })
    return yup.object({})
  }

  const form1 = useFormik({
    initialValues: props.formData['step_' + props.step],
    validationSchema: validationSchema,
    onSubmit: (values) => {
      try{
        props.setFormData({ ...props.formData, ['step_' + props.step]: values })
        props.nextStep()
      }catch(e){
        console.log(e)
      }
    },
  });

  useEffect(() => {
    EventBus.$on('submit-next-' + props.slug + '-' + props.step, form1.handleSubmit)
  }, [])
  
  useEffect(() => {
    Object.keys(props.formData['step_' + props.step]).forEach(key => form1?.setFieldValue(key, props.formData['step_' + props.step][key]))
  }, [ props.formData['step_' + props.step] ])

  return (
    <form onSubmit={form1.handleSubmit} className={'flex flex-col gap-4 ' + props.className}>
      <FormControl error={form1.touched.add_secondary_info && Boolean(form1.errors.add_secondary_info)}>
        <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Would you like to add a second parent/guardian?*</p>
        <RadioGroup value={ form1.values.add_secondary_info } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='add_secondary_info' orientation='horizontal'>
          <Radio label='Yes' value='true' />
          <Radio label='No' value='false' />
        </RadioGroup>
        {form1.touched.add_secondary_info && Boolean(form1.errors.add_secondary_info) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      {
        form1.values.add_secondary_info == 'true' &&
        <>
          <FormControl error={form1.touched.full_name && Boolean(form1.errors.full_name)}>
            <TextField
              label="Full Name*" variant="standard" name='full_name' fullWidth placeholder='Who should we contact?'
              value={ form1.values.full_name } onChange={ form1.handleChange } onBlur={form1.handleBlur}
            />
            {form1.touched.full_name && Boolean(form1.errors.full_name) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
          </FormControl>
          <FormControl error={form1.touched.relationship && Boolean(form1.errors.relationship)}>
            <TextField
              label="Relation to patient*" variant="standard" name='relationship' fullWidth placeholder='Relationship to patient'
              value={ form1.values.relationship } onChange={ form1.handleChange } onBlur={form1.handleBlur}
            />
            {form1.touched.relationship && Boolean(form1.errors.relationship) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
          </FormControl>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker']}>
              <DateField
                label="Date of Birth" variant="standard" name='dob' fullWidth
                onChange={ ( v: Dayjs | null ) => {
                  if(v?.isValid()) form1.setFieldValue('dob', v?.toISOString().substring(0, 10), true)
                }}
                onBlur={form1.handleBlur}
              />
            </DemoContainer>
          </LocalizationProvider>
          <FormControl error={form1.touched.phone && Boolean(form1.errors.phone)}>
            <TextField
              label="Mobile phone number*" variant="standard" name='phone' fullWidth
              value={ form1.values.phone } onChange={ form1.handleChange } onBlur={form1.handleBlur}
            />
            {form1.touched.phone && Boolean(form1.errors.phone) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
          </FormControl>
          <FormControl error={form1.touched.home_phone && Boolean(form1.errors.home_phone)}>
            <TextField
              label="Home phone number" variant="standard" name='home_phone' fullWidth
              value={ form1.values.home_phone } onChange={ form1.handleChange } onBlur={form1.handleBlur}
            />
            {form1.touched.home_phone && Boolean(form1.errors.home_phone) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
          </FormControl>
          <FormControl error={form1.touched.email && Boolean(form1.errors.email)}>
            <TextField
              label="Email address" variant="standard" name='email' fullWidth placeholder='joe@example.com'
              value={ form1.values.email } onChange={ form1.handleChange } onBlur={form1.handleBlur}
            />
            {form1.touched.email && Boolean(form1.errors.email) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
          </FormControl>
          <FormControl error={form1.touched.legal_custody && Boolean(form1.errors.legal_custody)}>
            <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Has legal custody?*</p>
            <RadioGroup value={ form1.values.legal_custody } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='legal_custody' orientation='horizontal'>
              <Radio label='Yes' value='true' />
              <Radio label='No' value='false' />
            </RadioGroup>
            {form1.touched.legal_custody && Boolean(form1.errors.legal_custody) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
          </FormControl>
          <FormControl error={form1.touched.employer && Boolean(form1.errors.employer)}>
            <TextField
              label="Employer" variant="standard" name='employer' fullWidth placeholder='joe@example.com'
              value={ form1.values.employer } onChange={ form1.handleChange } onBlur={form1.handleBlur}
            />
            {form1.touched.employer && Boolean(form1.errors.employer) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
          </FormControl>
        </>
      }
      <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>* Mandatory fields</p>
      <div className="flex">
        {props.showBack && <Button variant='plain' onClick={props.prevStep}>
          <ArrowBack />
          Back
        </Button>}
        <Button color='primary' type='submit' className='!ml-auto'>
          Next
          <ArrowForward />
        </Button>
      </div>
    </form>
  )
}