import React, { useState, useEffect } from 'react'
import { Radio, Button, Checkbox, RadioGroup, FormHelperText } from '@mui/joy'
import { ArrowForward, ArrowBack } from '@mui/icons-material'
import { useFormik } from 'formik'
import * as yup from 'yup'
import IFormProps from 'component/Form/IFormProps.ts'
import EventBus from '../../../../../bus.ts'
import { TextField, Select, MenuItem, FormControl, ListItemText } from '@mui/material'

export default (props: IFormProps) => {

  const validationSchema = () => {
    if(!props.validation) return yup.object({})
    const obj = {
      bleeding_in_brushing: yup.string().required('Required'),
      is_brushing_pain: yup.string().required('Required'),
      gum_recession: yup.string().required('Required'),
      gum_disease: yup.string().required('Required'),
      removed_teeth: yup.string().required('Required'),
      missing_teeth: yup.string().required('Required'),
      braces: yup.string().required('Required'),
      crowded_teeth: yup.string().required('Required'),
      space_in_teeth: yup.string().required('Required'),
      food_in_teeth: yup.string().required('Required'),
      short_teeth: yup.string().required('Required'),
      problem_in_jaw: yup.string().required('Required'),
      difficult_in_wide_open: yup.string().required('Required'),
      gap_in_jaw: yup.string().required('Required'),
    }
    return yup.object(obj)
  }

  const form1 = useFormik({
    initialValues: props.formData['step_' + props.step],
    validationSchema: validationSchema,
    onSubmit: (values) => {
      try{
        props.setFormData({ ...props.formData, ['step_' + props.step]: values })
        props.nextStep()
      }catch(e){
        console.log(e)
      }
    },
  });

  useEffect(() => {
    EventBus.$on('submit-next-' + props.slug + '-' + props.step, form1.handleSubmit)
  }, [])
  
  useEffect(() => {
    Object.keys(props.formData['step_' + props.step]).forEach(key => form1?.setFieldValue(key, props.formData['step_' + props.step][key]))
  }, [ props.formData['step_' + props.step] ])

  return (
    <form onSubmit={form1.handleSubmit} className={'flex flex-col gap-4 ' + props.className}>
      <p className='mb-0 text-[1rem] text-[#000] text-center font-bold'>Please answer the following questions</p>
      
      <FormControl error={form1.touched.bleeding_in_brushing && Boolean(form1.errors.bleeding_in_brushing)}>
        <div className="flex gap-4 justify-between">
          <div>
            <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Do your gums bleed when brushing or flossing?</p>
          </div>
          <RadioGroup value={ form1.values.bleeding_in_brushing } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='bleeding_in_brushing' orientation='horizontal'>
            <Radio label='Yes' value='true' />
            <Radio label='No' value='false' />
          </RadioGroup>
        </div>
        {form1.touched.bleeding_in_brushing && Boolean(form1.errors.bleeding_in_brushing) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      {form1.values.bleeding_in_brushing == 'true' &&
      <TextField
        label="Please provide details here" variant="standard" name='bleeding_in_brushing_reason' fullWidth
        value={ form1.values.bleeding_in_brushing_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}
      <FormControl error={form1.touched.is_brushing_pain && Boolean(form1.errors.is_brushing_pain)}>
        <div className="flex gap-4 justify-between">
          <div>
            <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Is brushing or flossing typically painful?</p>
          </div>
          <RadioGroup value={ form1.values.is_brushing_pain } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='is_brushing_pain' orientation='horizontal'>
            <Radio label='Yes' value='true' />
            <Radio label='No' value='false' />
          </RadioGroup>
        </div>
        {form1.touched.is_brushing_pain && Boolean(form1.errors.is_brushing_pain) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      {form1.values.is_brushing_pain == 'true' &&
      <TextField
        label="Please provide details here" variant="standard" name='is_brushing_pain_reason' fullWidth
        value={ form1.values.is_brushing_pain_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}
      <FormControl error={form1.touched.gum_recession && Boolean(form1.errors.gum_recession)}>
        <div className="flex gap-4 justify-between">
          <div>
            <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Have you ever experienced or been told you have gum recession?</p>
          </div>
          <RadioGroup value={ form1.values.gum_recession } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='gum_recession' orientation='horizontal'>
            <Radio label='Yes' value='true' />
            <Radio label='No' value='false' />
          </RadioGroup>
        </div>
        {form1.touched.gum_recession && Boolean(form1.errors.gum_recession) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      {form1.values.gum_recession == 'true' &&
      <TextField
        label="Please provide details here" variant="standard" name='gum_recession_reason' fullWidth
        value={ form1.values.gum_recession_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}
      <FormControl error={form1.touched.gum_disease && Boolean(form1.errors.gum_disease)}>
        <div className="flex gap-4 justify-between">
          <div>
            <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Have you ever been treated for or been told you have gum disease?</p>
          </div>
          <RadioGroup value={ form1.values.gum_disease } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='gum_disease' orientation='horizontal'>
            <Radio label='Yes' value='true' />
            <Radio label='No' value='false' />
          </RadioGroup>
        </div>
        {form1.touched.gum_disease && Boolean(form1.errors.gum_disease) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      {form1.values.gum_disease == 'true' &&
      <TextField
        label="Please provide details here" variant="standard" name='gum_disease_reason' fullWidth
        value={ form1.values.gum_disease_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}
      <FormControl error={form1.touched.braces && Boolean(form1.errors.braces)}>
        <div className="flex gap-4 justify-between">
          <div>
            <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Have you had any teeth removed for braces or otherwise?</p>
          </div>
          <RadioGroup value={ form1.values.braces } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='braces' orientation='horizontal'>
            <Radio label='Yes' value='true' />
            <Radio label='No' value='false' />
          </RadioGroup>
        </div>
        {form1.touched.braces && Boolean(form1.errors.braces) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      {form1.values.braces == 'true' &&
      <TextField
        label="Please provide details here" variant="standard" name='braces_reason' fullWidth
        value={ form1.values.braces_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}
      <FormControl error={form1.touched.missing_teeth && Boolean(form1.errors.missing_teeth)}>
        <div className="flex gap-4 justify-between">
          <div>
            <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Do you know of any missing teeth or teeth that have never developed?</p>
          </div>
          <RadioGroup value={ form1.values.missing_teeth } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='missing_teeth' orientation='horizontal'>
            <Radio label='Yes' value='true' />
            <Radio label='No' value='false' />
          </RadioGroup>
        </div>
        {form1.touched.missing_teeth && Boolean(form1.errors.missing_teeth) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      {form1.values.missing_teeth == 'true' &&
      <TextField
        label="Please provide details here" variant="standard" name='missing_teeth_reason' fullWidth
        value={ form1.values.missing_teeth_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}
      <FormControl error={form1.touched.removed_teeth && Boolean(form1.errors.removed_teeth)}>
        <div className="flex gap-4 justify-between">
          <div>
            <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Have you ever had braces, orthodontic treatment or spacers, or had a "bite adjustment?"</p>
          </div>
          <RadioGroup value={ form1.values.removed_teeth } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='removed_teeth' orientation='horizontal'>
            <Radio label='Yes' value='true' />
            <Radio label='No' value='false' />
          </RadioGroup>
        </div>
        {form1.touched.removed_teeth && Boolean(form1.errors.removed_teeth) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      {form1.values.removed_teeth == 'true' &&
      <TextField
        label="Please provide details here" variant="standard" name='removed_teeth_reason' fullWidth
        value={ form1.values.removed_teeth_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}
      <FormControl error={form1.touched.crowded_teeth && Boolean(form1.errors.crowded_teeth)}>
        <div className="flex gap-4 justify-between">
          <div>
            <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Are your teeth becoming more crowded, overlapped, or "crooked?"</p>
          </div>
          <RadioGroup value={ form1.values.crowded_teeth } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='crowded_teeth' orientation='horizontal'>
            <Radio label='Yes' value='true' />
            <Radio label='No' value='false' />
          </RadioGroup>
        </div>
        {form1.touched.crowded_teeth && Boolean(form1.errors.crowded_teeth) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      {form1.values.crowded_teeth == 'true' &&
      <TextField
        label="Please provide details here" variant="standard" name='crowded_teeth_reason' fullWidth
        value={ form1.values.crowded_teeth_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}
      <FormControl error={form1.touched.crowded_teeth && Boolean(form1.errors.crowded_teeth)}>
        <div className="flex gap-4 justify-between">
          <div>
            <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Are your teeth developing spaces?</p>
          </div>
          <RadioGroup value={ form1.values.crowded_teeth } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='crowded_teeth' orientation='horizontal'>
            <Radio label='Yes' value='true' />
            <Radio label='No' value='false' />
          </RadioGroup>
        </div>
        {form1.touched.crowded_teeth && Boolean(form1.errors.crowded_teeth) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      {form1.values.crowded_teeth == 'true' &&
      <TextField
        label="Please provide details here" variant="standard" name='crowded_teeth_reason' fullWidth
        value={ form1.values.crowded_teeth_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}
      <FormControl error={form1.touched.food_in_teeth && Boolean(form1.errors.food_in_teeth)}>
        <div className="flex gap-4 justify-between">
          <div>
            <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Do you frequently get food caught between any teeth?</p>
          </div>
          <RadioGroup value={ form1.values.food_in_teeth } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='food_in_teeth' orientation='horizontal'>
            <Radio label='Yes' value='true' />
            <Radio label='No' value='false' />
          </RadioGroup>
        </div>
        {form1.touched.food_in_teeth && Boolean(form1.errors.food_in_teeth) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      {form1.values.food_in_teeth == 'true' &&
      <TextField
        label="Please provide details here" variant="standard" name='food_in_teeth_reason' fullWidth
        value={ form1.values.food_in_teeth_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}
      <FormControl error={form1.touched.short_teeth && Boolean(form1.errors.short_teeth)}>
        <div className="flex gap-4 justify-between">
          <div>
            <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Have you noticed your teeth becoming shorter, thinner, or flatter over the years?</p>
          </div>
          <RadioGroup value={ form1.values.short_teeth } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='short_teeth' orientation='horizontal'>
            <Radio label='Yes' value='true' />
            <Radio label='No' value='false' />
          </RadioGroup>
        </div>
        {form1.touched.short_teeth && Boolean(form1.errors.short_teeth) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      {form1.values.short_teeth == 'true' &&
      <TextField
        label="Please provide details here" variant="standard" name='short_teeth_reason' fullWidth
        value={ form1.values.short_teeth_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}
      <FormControl error={form1.touched.problem_in_jaw && Boolean(form1.errors.problem_in_jaw)}>
        <div className="flex gap-4 justify-between">
          <div>
            <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Do you have problems with your jaw joint? (TMD, popping, clicking, deviating from side to side when opening or closing?)</p>
          </div>
          <RadioGroup value={ form1.values.problem_in_jaw } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='problem_in_jaw' orientation='horizontal'>
            <Radio label='Yes' value='true' />
            <Radio label='No' value='false' />
          </RadioGroup>
        </div>
        {form1.touched.problem_in_jaw && Boolean(form1.errors.problem_in_jaw) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      {form1.values.problem_in_jaw == 'true' &&
      <TextField
        label="Please provide details here" variant="standard" name='problem_in_jaw_reason' fullWidth
        value={ form1.values.problem_in_jaw_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}
      <FormControl error={form1.touched.difficult_in_wide_open && Boolean(form1.errors.difficult_in_wide_open)}>
        <div className="flex gap-4 justify-between">
          <div>
            <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Is it often difficult to open wide?</p>
          </div>
          <RadioGroup value={ form1.values.difficult_in_wide_open } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='difficult_in_wide_open' orientation='horizontal'>
            <Radio label='Yes' value='true' />
            <Radio label='No' value='false' />
          </RadioGroup>
        </div>
        {form1.touched.difficult_in_wide_open && Boolean(form1.errors.difficult_in_wide_open) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      {form1.values.difficult_in_wide_open == 'true' &&
      <TextField
        label="Please provide details here" variant="standard" name='difficult_in_wide_open_reason' fullWidth
        value={ form1.values.difficult_in_wide_open_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}
      <FormControl error={form1.touched.gap_in_jaw && Boolean(form1.errors.gap_in_jaw)}>
        <div className="flex gap-4 justify-between">
          <div>
            <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Do you have more than one bite? Or do you notice shifting your jaw around to make your teeth fit together?</p>
          </div>
          <RadioGroup value={ form1.values.gap_in_jaw } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='gap_in_jaw' orientation='horizontal'>
            <Radio label='Yes' value='true' />
            <Radio label='No' value='false' />
          </RadioGroup>
        </div>
        {form1.touched.gap_in_jaw && Boolean(form1.errors.gap_in_jaw) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      {form1.values.gap_in_jaw == 'true' &&
      <TextField
        label="Please provide details here" variant="standard" name='gap_in_jaw_reason' fullWidth
        value={ form1.values.gap_in_jaw_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />}

      <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>* Mandatory fields</p>
      <div className="flex">
        {props.showBack && <Button variant='plain' onClick={props.prevStep}>
          <ArrowBack />
          Back
        </Button>}
        <Button color='primary' type='submit' className='!ml-auto'>
          Next
          <ArrowForward />
        </Button>
      </div>
    </form>
  )
}