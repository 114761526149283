import { TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Button, FormControl, FormHelperText } from '@mui/joy'
import { ArrowBack, ArrowForward } from '@mui/icons-material'
import { Dayjs } from 'dayjs'
import IFormProps from 'component/Form/IFormProps.ts';
import { useFormik } from 'formik'
import * as yup from 'yup'
import EventBus from '../../../../../bus.ts';

export default (props: IFormProps) => {

  const onFilePicked = e => {
    let files = e.target.files
    var reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = function () {
      console.log({ name: e.target.name, value: reader.result })
      form1.setFieldValue(e.target.name, reader.result)
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  const validationSchema = () => {
    if(!props.validation) return yup.object({})
    return yup.object({
      photo: yup.string().required('Required')
    })
  }

  const form1 = useFormik({
    initialValues: props.formData['step_' + props.step],
    validationSchema: validationSchema,
    onSubmit: (values) => {
      try{
        props.setFormData({ ...props.formData, ['step_' + props.step]: values })
        props.nextStep()
      }catch(e){
        console.log(e)
      }
    },
  });

  useEffect(() => {
    EventBus.$on('submit-next-' + props.slug + '-' + props.step, form1.handleSubmit)
  }, [])
  
  useEffect(() => {
    Object.keys(props.formData['step_' + props.step]).forEach(key => form1?.setFieldValue(key, props.formData['step_' + props.step][key]))
  }, [ props.formData['step_' + props.step] ])

  return (
    <form onSubmit={form1.handleSubmit} className={'flex flex-col gap-4 ' + props.className}>
      <FormControl error={form1.touched.photo && Boolean(form1.errors.photo)}>
        <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Please add your profile Picture*</p>
        <TextField variant="standard" name='photo' fullWidth type='file' onChange={ onFilePicked } />
        {form1.touched.photo && Boolean(form1.errors.photo) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>* Mandatory fields</p>
      <div className="flex">
        {props.showBack && <Button variant='plain' onClick={props.prevStep}>
          <ArrowBack />
          Back
        </Button>}
        <Button color='primary' type='submit' className='!ml-auto'>
          Next
          <ArrowForward />
        </Button>
      </div>
    </form>
  )
}