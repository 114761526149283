import React, { useEffect, useState } from 'react'
import { Button, Checkbox, FormHelperText, Radio, RadioGroup } from '@mui/joy'
import { ArrowForward, ArrowBack } from '@mui/icons-material'
import { useFormik } from 'formik'
import * as yup from 'yup'
import IFormProps from 'component/Form/IFormProps.ts'
import EventBus from '../../../../../bus.ts'
import { TextField, FormControl, Select, MenuItem, ListItemText } from '@mui/material'
import SignatureCanvas from 'react-signature-canvas'

export default (props: IFormProps) => {

  const SEND_COPY_OPTIONS = [
    { value: 'all', text: 'All of my dental records (x-rays, chart notes, periodontal charting)' },
    { value: 'xrays', text: 'Dental xrays only (bitewings, Panorex or Full Mouth Series taken within the past five years)' },
    { value: 'other', text: 'Other...' },
  ]

  const [ sigCanvas, setSigCanvas ] = useState(null)

  const onEndSignature = () => {
    form1.values.signature = sigCanvas?.toDataURL()
  }

  useEffect(() => {
    if(form1.values.signature) {
      sigCanvas?.fromDataURL(form1.values.signature)
    }
  }, [ sigCanvas ])
  
  const validationSchema = () => {
    if(!props.validation) return yup.object({})
    const obj = {
      scenario: yup.string().required('Required'),
    }
    if(form1?.values.scenario != '') {
      obj['agree'] = yup.boolean().isTrue().required('Required')
      obj['send_a_copy_of'] = yup.string().required('Required')
      obj['signature'] = yup.string().required('Required')
    }
    if(form1?.values.send_a_copy_of == 'other') {
      obj['send_a_copy_of_other'] = yup.string().required('Required')
    }
    if(form1?.values.scenario == 'prev') {
      obj['child_prev_dentist_name'] = yup.string().required('Required')
      obj['child_prev_dentist_address'] = yup.string().required('Required')
      obj['child_prev_dentist_phone'] = yup.string().required('Required')
      obj['child_prev_dentist_email'] = yup.string().required('Required')
    }
    if(form1?.values.scenario == 'prev') {
      obj['child_new_dentist_name'] = yup.string().required('Required')
      obj['child_new_dentist_address'] = yup.string().required('Required')
      obj['child_new_dentist_phone'] = yup.string().required('Required')
      obj['child_new_dentist_email'] = yup.string().required('Required')
    }
    return yup.object(obj)
  }

  const form1 = useFormik({
    initialValues: props.formData['step_' + props.step],
    validationSchema: validationSchema,
    onSubmit: (values) => {
      try{
        props.setFormData({ ...props.formData, ['step_' + props.step]: values })
        props.nextStep()
      }catch(e){
        console.log(e)
      }
    },
  });

  useEffect(() => {
    EventBus.$on('submit-next-' + props.slug + '-' + props.step, form1.handleSubmit)
  }, [])
  
  useEffect(() => {
    Object.keys(props.formData['step_' + props.step]).forEach(key => form1?.setFieldValue(key, props.formData['step_' + props.step][key]))
  }, [ props.formData['step_' + props.step] ])

  return (
    <form onSubmit={form1.handleSubmit} className={'flex flex-col gap-4 ' + props.className}>
      <FormControl error={form1.touched.scenario && Boolean(form1.errors.scenario)}>
        <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Please select which scenario applies to you*</p>
        <RadioGroup value={ form1.values.scenario } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='scenario' orientation='vertical'>
          <Radio label="I want the records from my child's previous dentist to be released" value='prev' />
          <Radio label="I want the records from this office to be released to my child's future office" value='new' />
        </RadioGroup>
        {form1.touched.scenario && Boolean(form1.errors.scenario) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      { form1?.values.scenario == 'prev' && 
      <>
        <FormControl error={form1.touched.child_prev_dentist_name && Boolean(form1.errors.child_prev_dentist_name)}>
          <TextField
            label="What is your child's previous dentist's name/practice name?*" variant="standard" name='child_prev_dentist_name' fullWidth
            value={ form1.values.child_prev_dentist_name } onChange={ form1.handleChange } onBlur={form1.handleBlur}
          />
          {form1.touched.child_prev_dentist_name && Boolean(form1.errors.child_prev_dentist_name) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
        </FormControl>
        <FormControl error={form1.touched.child_prev_dentist_address && Boolean(form1.errors.child_prev_dentist_address)}>
          <TextField
            label="What is your child's previous dentist's address?*" variant="standard" name='child_prev_dentist_address' fullWidth
            value={ form1.values.child_prev_dentist_address } onChange={ form1.handleChange } onBlur={form1.handleBlur}
          />
          {form1.touched.child_prev_dentist_address && Boolean(form1.errors.child_prev_dentist_address) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
        </FormControl>
        <FormControl error={form1.touched.child_prev_dentist_phone && Boolean(form1.errors.child_prev_dentist_phone)}>
          <TextField
            label="What is your child's previous dentist's phone number?*" variant="standard" name='child_prev_dentist_phone' fullWidth
            value={ form1.values.child_prev_dentist_phone } onChange={ form1.handleChange } onBlur={form1.handleBlur}
          />
          {form1.touched.child_prev_dentist_phone && Boolean(form1.errors.child_prev_dentist_phone) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
        </FormControl>
        <FormControl error={form1.touched.child_prev_dentist_email && Boolean(form1.errors.child_prev_dentist_email)}>
          <TextField
            label="What is your child's previous dentist's email address?*" variant="standard" name='child_prev_dentist_email' fullWidth
            value={ form1.values.child_prev_dentist_email } onChange={ form1.handleChange } onBlur={form1.handleBlur}
          />
          {form1.touched.child_prev_dentist_email && Boolean(form1.errors.child_prev_dentist_email) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
        </FormControl>
      </>}
      { form1?.values.scenario == 'new' && 
      <>
        <FormControl error={form1.touched.child_new_dentist_name && Boolean(form1.errors.child_new_dentist_name)}>
          <TextField
            label="What is your child's new dentist's name/practice name?*" variant="standard" name='child_new_dentist_name' fullWidth
            value={ form1.values.child_new_dentist_name } onChange={ form1.handleChange } onBlur={form1.handleBlur}
          />
          {form1.touched.child_new_dentist_name && Boolean(form1.errors.child_new_dentist_name) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
        </FormControl>
        <FormControl error={form1.touched.child_new_dentist_address && Boolean(form1.errors.child_new_dentist_address)}>
          <TextField
            label="What is your child's new dentist's address?*" variant="standard" name='child_new_dentist_address' fullWidth
            value={ form1.values.child_new_dentist_address } onChange={ form1.handleChange } onBlur={form1.handleBlur}
          />
          {form1.touched.child_new_dentist_address && Boolean(form1.errors.child_new_dentist_address) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
        </FormControl>
        <FormControl error={form1.touched.child_new_dentist_phone && Boolean(form1.errors.child_new_dentist_phone)}>
          <TextField
            label="What is your child's new dentist's phone number?*" variant="standard" name='child_new_dentist_phone' fullWidth
            value={ form1.values.child_new_dentist_phone } onChange={ form1.handleChange } onBlur={form1.handleBlur}
          />
          {form1.touched.child_new_dentist_phone && Boolean(form1.errors.child_new_dentist_phone) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
        </FormControl>
        <FormControl error={form1.touched.child_new_dentist_email && Boolean(form1.errors.child_new_dentist_email)}>
          <TextField
            label="What is your child's new dentist's email address?*" variant="standard" name='child_new_dentist_email' fullWidth
            value={ form1.values.child_new_dentist_email } onChange={ form1.handleChange } onBlur={form1.handleBlur}
          />
          {form1.touched.child_new_dentist_email && Boolean(form1.errors.child_new_dentist_email) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
        </FormControl>
      </>}
      { form1?.values?.scenario != '' &&
      <>
        <FormControl error={form1.touched.send_a_copy_of && Boolean(form1.errors.send_a_copy_of)} variant='standard'>
          <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Please send a copy of:*</p>
          <Select name="send_a_copy_of" value={form1.values.send_a_copy_of}
            onChange={e => {
              const { target: { value } } = e
              form1.setFieldValue('send_a_copy_of', value, true)
            }}
          >
            {SEND_COPY_OPTIONS.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {/* <Checkbox checked={form1.values.send_a_copy_of.indexOf(name) > -1} /> &nbsp; */}
                <ListItemText primary={option.text} />
              </MenuItem>
            ))}
          </Select>
          {form1.touched.send_a_copy_of && Boolean(form1.errors.send_a_copy_of) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
        </FormControl>
        { form1?.values.send_a_copy_of == 'other' && 
        <FormControl error={form1.touched.send_a_copy_of_other && Boolean(form1.errors.send_a_copy_of_other)}>
          <TextField
            label="Please provide details here*" variant="standard" name='send_a_copy_of_other' fullWidth
            value={ form1.values.send_a_copy_of_other } onChange={ form1.handleChange } onBlur={form1.handleBlur}
          />
          {form1.touched.send_a_copy_of_other && Boolean(form1.errors.send_a_copy_of_other) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
        </FormControl>}
        <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Release of Records Authorization</p>
        <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>
          { form1?.values.scenario == 'prev' ?
          <span>By signing below, I consent for my child's dental treatment records and/or x-rays to be transferred by email to info@lakewooddentaltrails.com.</span>
          :<span>By signing below, I consent for my child's dental treatment records and/or x-rays to be transferred by email.</span>}
        </p>
        { form1?.values?.scenario == 'prev' &&
        <>
          <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Practice Name: Lakewood Dental Trails</p>
          <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Practice Address: 10252 W Adams Ave #101, Temple, TX 76502</p>
          <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Practice Phone number: (254) 206 3200</p>
        </>}
        <FormControl error={form1.touched.agree && Boolean(form1.errors.agree)}>
          <Checkbox label='I confirm and agree*' name='agree' onChange={ form1.handleChange } checked={ form1.values.agree } />
          {form1.touched.agree && Boolean(form1.errors.agree) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
        </FormControl>
        <p className='mb-0 text-[0.8rem] text-[#000]/[0.6]'>We have enjoyed being your dentist! We warmly welcome your feedback, please let us know your reason for leaving the practice.</p>
        <p className='text-[1rem] text-[#000]/[0.6] mb-0'>Parent or Guardian Signature*</p>
        <FormControl error={form1.touched.signature && Boolean(form1.errors.signature)}>
          {props.className.includes('flex') && <SignatureCanvas penColor='black' ref={setSigCanvas} canvasProps={{ className: 'bg-[#f1f3f4] w-full h-[250px] border-2 border-[#bbc2c7] rounded-[25px]' }} onEnd={onEndSignature} clearOnResize={false} />}
          {form1.touched.signature && Boolean(form1.errors.signature) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Please sign by drawing in the box above.</FormHelperText>}
        </FormControl>
        <p className='text-[10px] text-[#000]/[0.6]'>By drawing in the box above I understand and agree that this is a legal representation of my signature.</p>
      </>}

      <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>* Mandatory fields</p>
      <div className="flex">
        {props.showBack && <Button variant='plain' onClick={props.prevStep}>
          <ArrowBack />
          Back
        </Button>}
        <Button color='primary' type='submit' className='!ml-auto'>
          Next
          <ArrowForward />
        </Button>
      </div>
    </form>
  )
}