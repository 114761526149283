import { Card } from '@mui/material'
import React, { useEffect, useState } from 'react'
import FormButtons from '../../FormFabs.tsx'
import GeneralHealthInformation from './steps/GeneralHealthInformation.tsx'
import Signature from './steps/Signature.tsx'
import PersonalHistory from './steps/PersonalHistory.tsx'
import OfficeRelationship from './steps/OfficeRelationship.tsx'
import Header from '../../Header.tsx'
import { useSelector } from 'react-redux'
import axios from 'axios'
import DentalStructure from './steps/DentalStructure.tsx'

export default () => {
  const SLUG = 'dental-history'
  const MAX_STEPS = 5
  const [ step, setStep ] = useState(1)
  const [ validation, setValidation ] = useState(false)
  const [ submitting, setSubmitting ] = useState(false)
  const [ fetching, setFetching ] = useState(true)
  const { patient, formName } = useSelector((state) => state)
  const [ patientForm, setPatientForm ] = useState({})
  
  const nextStep = () => {
    if(step < MAX_STEPS) setStep(step + 1)
    else submitForm()
  }
  const prevStep = () => {
    if(step > 1) setStep(step - 1)
  }

  const [ formData, setFormData ] = useState({
    step_1: {
      prev_dentist: '',
      date_of_last_dental_exam: '',
      date_of_last_cleaning: '',
      immediate_concern: '',
      immediate_concern_options: [],
      immediate_concern_option_other_reason: '',
    },
    step_2: {
      value_most_in_visit: '',
      prefer_anything: '',
      dental_treatment_scaling: '',
    },
    step_3: {
      concerned_in_appearance_of_teeth: '',
      concerned_in_appearance_of_teeth_reason: '',
      interested_in_improving_smile: '',
      interested_in_improving_smile_reason: '',
      cavities_in_2_yrs: '',
      cavities_in_2_yrs_reason: '',
      sensitive_teeth: '',
      sensitive_teeth_reason: '',
      difficulty_in_biting: '',
      difficulty_in_biting_reason: '',
      problems_in_sleep: '',
      problems_in_sleep_reason: '',
      clench_in_day: '',
      clench_in_day_reason: '',
      wear_bite_appliance: '',
      wear_bite_appliance_reason: '',
      bite_nails: '',
      bite_nails_reason: '',
      low_silva: '',
      low_silva_reason: '',
      unpleasant_taste: '',
      unpleasant_taste_reason: '',
    },
    step_4: {
      bleeding_in_brushing: '',
      bleeding_in_brushing_reason: '',
      is_brushing_pain: '',
      is_brushing_pain_reason: '',
      gum_recession: '',
      gum_recession_reason: '',
      gum_disease: '',
      gum_disease_reason: '',
      removed_teeth: '',
      removed_teeth_reason: '',
      missing_teeth: '',
      missing_teeth_reason: '',
      braces: '',
      braces_reason: '',
      crowded_teeth: '',
      crowded_teeth_reason: '',
      space_in_teeth: '',
      space_in_teeth_reason: '',
      food_in_teeth: '',
      food_in_teeth_reason: '',
      short_teeth: '',
      short_teeth_reason: '',
      problem_in_jaw: '',
      problem_in_jaw_reason: '',
      difficult_in_wide_open: '',
      difficult_in_wide_open_reason: '',
      gap_in_jaw: '',
      gap_in_jaw_reason: '',
    },
    step_5: {
      sign_as: '',
      signature: '',
      guardian_full_name: '',
      guardian_dob: '',
      guardian_address: '',
      guardian_city: '',
      guardian_state: '',
      guardian_zip: '',
    },
  })

  const submitForm = async () => {
    if(submitting) return
    setSubmitting(true)
    try{
      const payload = {
        form_activation_id: patient?.form_activation_id,
        patient_id: patient?.patient?.id,
        form_name: formName,
        form_data: formData
      };
      const { data } = patientForm?.id ? await axios.put(`${process.env.REACT_APP_CARESUITE_APP_URL}/public/api/v1/patient_forms/${patientForm?.id}`, payload) : await axios.post(`${process.env.REACT_APP_CARESUITE_APP_URL}/public/api/v1/patient_forms`, payload);
      setPatientForm(data)
    }catch(e){
      console.log(e)
    }
    setSubmitting(false)
  }

  const fetchData = async () => {
    console.log(patient)
    const { data } = await axios.get(`${process.env.REACT_APP_CARESUITE_APP_URL}/public/api/v1/patient_forms/show_by_form_name`,{
      params: { form_activation_id: patient?.form_activation_id }
    });
    if(data){
      setPatientForm(data)
      setFormData(data.form_data)
    }
    setFetching(false)
  }
  
  useEffect(() => { fetchData() }, []);
  return (
    <>
      <Header title='Dental History' />
      <div className='max-w-[1000px] mx-auto py-12'>
        <div className="flex gap-4 flex-wrap justify-between items-center">
          <p className="text-[#1d3243] font-bold text-[20px]">
            {
            step == 1 ? <span>General Information</span>
            :step == 2 ? <span>Office Relationship</span>
            :step == 3 ? <span>Personal History</span>
            :step == 4 ? <span>Dental Structural History</span>
            :step == 5 ? <span>Signature</span>
            :<span></span>
            }
          </p>
          <p className="text-[#1d3243] font-bold text-[16px]">Step {step}/{MAX_STEPS}</p>
        </div>
        <Card className='p-8 flex flex-col gap-4' elevation={3}>
          <GeneralHealthInformation className={`${step == 1 ? 'flex' : 'hidden'}`} step={1} prevStep={prevStep} nextStep={nextStep} validation={validation} slug={SLUG} formData={formData} setFormData={setFormData} />
          <OfficeRelationship className={`${step == 2 ? 'flex' : 'hidden'}`} showBack step={2} prevStep={prevStep} nextStep={nextStep} validation={validation} slug={SLUG} formData={formData} setFormData={setFormData} />
          <PersonalHistory className={`${step == 3 ? 'flex' : 'hidden'}`} showBack step={3} prevStep={prevStep} nextStep={nextStep} validation={validation} slug={SLUG} formData={formData} setFormData={setFormData} />
          <DentalStructure className={`${step == 4 ? 'flex' : 'hidden'}`} showBack step={4} prevStep={prevStep} nextStep={nextStep} validation={validation} slug={SLUG} formData={formData} setFormData={setFormData} />
          <Signature className={`${step == 5 ? 'flex' : 'hidden'}`} showBack step={5} prevStep={prevStep} nextStep={nextStep} validation={validation} slug={SLUG} formData={formData} setFormData={setFormData} />
          {!validation && <p className='mb-0 text-[1rem] text-[#ff6e19] text-center'>PREVIEW MODE - form validation disabled!</p>}
        </Card>
      </div>
      <FormButtons prevStep={prevStep} nextStep={nextStep} step={step} validation={validation} setValidation={setValidation} slug={SLUG} maxStep={MAX_STEPS} />
    </>
  )
}