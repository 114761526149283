import React, { useState, useEffect } from 'react'
import { Radio, Button, Checkbox, RadioGroup, FormHelperText } from '@mui/joy'
import { ArrowForward, ArrowBack } from '@mui/icons-material'
import { useFormik } from 'formik'
import * as yup from 'yup'
import IFormProps from 'component/Form/IFormProps.ts'
import EventBus from '../../../../../bus.ts'
import { TextField, FormControl, Select, MenuItem, ListItemText } from '@mui/material'

export default (props: IFormProps) => {

  const IMMEDIATE_CONCERN_OPTIONS = [
    'Tooth pain', 'Bad breath', 'More attractive smile', 'Crooked/Crowded teeth', 'Discoloration', 'Missing teeth', 'Other'
  ]
  
  const validationSchema = () => {
    if(!props.validation) return yup.object({})
    const obj = {
      dental_visit_reason: yup.string().required('Required'),
    }
    return yup.object(obj)
  }

  const form1 = useFormik({
    initialValues: props.formData['step_' + props.step],
    validationSchema: validationSchema,
    onSubmit: (values) => {
      try{
        props.setFormData({ ...props.formData, ['step_' + props.step]: values })
        props.nextStep()
      }catch(e){
        console.log(e)
      }
    },
  });

  useEffect(() => {
    EventBus.$on('submit-next-' + props.slug + '-' + props.step, form1.handleSubmit)
  }, [])
  
  useEffect(() => {
    Object.keys(props.formData['step_' + props.step]).forEach(key => form1?.setFieldValue(key, props.formData['step_' + props.step][key]))
  }, [ props.formData['step_' + props.step] ])

  return (
    <form onSubmit={form1.handleSubmit} className={'flex flex-col gap-4 ' + props.className}>
      <FormControl error={form1.touched.prev_dentist && Boolean(form1.errors.prev_dentist)}>
        <TextField
          label="Who was your child's previous Dentist?" variant="standard" name='prev_dentist' fullWidth
          value={ form1.values.prev_dentist } onChange={ form1.handleChange } onBlur={form1.handleBlur}
        />
        {form1.touched.prev_dentist && Boolean(form1.errors.prev_dentist) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      <FormControl error={form1.touched.date_of_last_dental_exam && Boolean(form1.errors.date_of_last_dental_exam)}>
        <TextField
          label="Date of your child's last dental exam" variant="standard" name='date_of_last_dental_exam' fullWidth
          value={ form1.values.date_of_last_dental_exam } onChange={ form1.handleChange } onBlur={form1.handleBlur}
        />
        {form1.touched.date_of_last_dental_exam && Boolean(form1.errors.date_of_last_dental_exam) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      <FormControl error={form1.touched.date_of_last_cleaning && Boolean(form1.errors.date_of_last_cleaning)}>
        <TextField
          label="Date of your child's last cleaning" variant="standard" name='date_of_last_cleaning' fullWidth
          value={ form1.values.date_of_last_cleaning } onChange={ form1.handleChange } onBlur={form1.handleBlur}
        />
        {form1.touched.date_of_last_cleaning && Boolean(form1.errors.date_of_last_cleaning) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      <FormControl error={form1.touched.dental_visit_reason && Boolean(form1.errors.dental_visit_reason)}>
        <TextField
          label="What is the reason for your child’s dental visit?*" variant="standard" name='dental_visit_reason' fullWidth
          value={ form1.values.dental_visit_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
        />
        {form1.touched.dental_visit_reason && Boolean(form1.errors.dental_visit_reason) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>

      <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>* Mandatory fields</p>
      <div className="flex">
        {props.showBack && <Button variant='plain' onClick={props.prevStep}>
          <ArrowBack />
          Back
        </Button>}
        <Button color='primary' type='submit' className='!ml-auto'>
          Next
          <ArrowForward />
        </Button>
      </div>
    </form>
  )
}