import { TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { DateField } from '@mui/x-date-pickers'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { Radio, Select, Option, Button, FormControl, FormHelperText, RadioGroup } from '@mui/joy'
import { ArrowBack, ArrowForward } from '@mui/icons-material'
import { Dayjs } from 'dayjs'
import IFormProps from 'component/Form/IFormProps.ts';
import { useFormik } from 'formik'
import * as yup from 'yup'
import EventBus from '../../../../../bus.ts';

export default (props: IFormProps) => {

  const onFilePicked = e => {
    let files = e.target.files
    var reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = function () {
      console.log({ name: e.target.name, value: reader.result })
      form1.setFieldValue(e.target.name, reader.result)
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  const validationSchema = () => {
    if(!props.validation) return yup.object({})
    return yup.object({
      child_first_name: yup.boolean().required('Required').nullable(),
      child_last_name: yup.boolean().required('Required').nullable(),
      preferred_name: yup.boolean().required('Required').nullable(),
      dob: yup.boolean().required('Required').nullable(),
      photo: yup.boolean().required('Required').nullable(),
      gender: yup.boolean().required('Required').nullable(),
    })
  }

  const form1 = useFormik({
    initialValues: props.formData['step_' + props.step],
    validationSchema: validationSchema,
    onSubmit: (values) => {
      try{
        props.setFormData({ ...props.formData, ['step_' + props.step]: values })
        props.nextStep()
      }catch(e){
        console.log(e)
      }
    },
  });

  useEffect(() => {
    EventBus.$on('submit-next-' + props.slug + '-' + props.step, form1.handleSubmit)
  }, [])
  
  useEffect(() => {
    Object.keys(props.formData['step_' + props.step]).forEach(key => form1?.setFieldValue(key, props.formData['step_' + props.step][key]))
  }, [ props.formData['step_' + props.step] ])

  return (
    <form onSubmit={form1.handleSubmit} className={'flex flex-col gap-4 ' + props.className}>
      <FormControl error={form1.touched.child_first_name && Boolean(form1.errors.child_first_name)}>
        <TextField
          label="Child's First Name*" variant="standard" name='child_first_name' fullWidth
          value={ form1.values.child_first_name } onChange={ form1.handleChange } onBlur={form1.handleBlur}
        />
        {form1.touched.child_first_name && Boolean(form1.errors.child_first_name) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      <FormControl error={form1.touched.child_last_name && Boolean(form1.errors.child_last_name)}>
        <TextField
          label="Child's Last Name*" variant="standard" name='child_last_name' fullWidth
          value={ form1.values.child_last_name } onChange={ form1.handleChange } onBlur={form1.handleBlur}
        />
        {form1.touched.child_last_name && Boolean(form1.errors.child_last_name) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      <TextField
        label="Preferred Name" variant="standard" name='preferred_name' fullWidth
        value={ form1.values.preferred_name } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={['DatePicker']}>
          <DateField
            label="Date of Birth" variant="standard" name='dob' fullWidth
            onChange={ ( v: Dayjs | null ) => {
              if(v?.isValid()) form1.setFieldValue('dob', v?.toISOString().substring(0, 10), true)
            }}
            onBlur={form1.handleBlur}
          />
        </DemoContainer>
      </LocalizationProvider>
      <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Please add your profile Picture</p>
      <TextField variant="standard" name='photo' fullWidth type='file' onChange={ onFilePicked } />
      <FormControl error={form1.touched.gender && Boolean(form1.errors.gender)}>
        <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Gender*</p>
        <RadioGroup value={ form1.values.gender } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='gender' orientation='horizontal'>
          <Radio label='Male' value="Male" />
          <Radio label='Female' value="Female" />
          <Radio label='Other' value="Other" />
          <Radio label='Prefer not to self identify' value="Prefer not to self identify" />
        </RadioGroup>
        {form1.touched.gender && Boolean(form1.errors.gender) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>* Mandatory fields</p>
      <div className="flex">
        {props.showBack && <Button variant='plain' onClick={props.prevStep}>
          <ArrowBack />
          Back
        </Button>}
        <Button color='primary' type='submit' className='!ml-auto'>
          Next
          <ArrowForward />
        </Button>
      </div>
    </form>
  )
}