import React, { useState, useEffect } from 'react'
import { Radio, Button, Checkbox, RadioGroup, FormControl, FormHelperText } from '@mui/joy'
import { ArrowForward, ArrowBack } from '@mui/icons-material'
import { useFormik } from 'formik'
import * as yup from 'yup'
import IFormProps from 'component/Form/IFormProps.ts'
import EventBus from '../../../../../bus.ts';

export default (props: IFormProps) => {

  const validationSchema = () => {
    if(!props.validation) return yup.object({})
    return yup.object({
      agree: yup.boolean().isTrue().required('Required'),
    })
  }

  const form1 = useFormik({
    initialValues: props.formData['step_' + props.step],
    validationSchema: validationSchema,
    onSubmit: (values) => {
      try{
        props.setFormData({ ...props.formData, ['step_' + props.step]: values })
        props.nextStep()
      }catch(e){
        console.log(e)
      }
    },
  });

  useEffect(() => {
    EventBus.$on('submit-next-' + props.slug + '-' + props.step, form1.handleSubmit)
  }, [])
  
  useEffect(() => {
    Object.keys(props.formData['step_' + props.step]).forEach(key => form1?.setFieldValue(key, props.formData['step_' + props.step][key]))
  }, [ props.formData['step_' + props.step] ])

  return (
    <form onSubmit={form1.handleSubmit} className={'flex flex-col gap-4 ' + props.className}>
      <p className="text-[#1d3243] text-[15px]">Consent for Dental Treatment</p>
      <p className="text-[#1d3243] text-[15px]">I understand that I am having one or more of the following completed in the office of Lakewood Dental Trails: an oral examination, necessary x-rays, cleaning, fluoride treatment and/or sealants.</p>
      <p className="text-[#1d3243] text-[15px]">1. ORAL EXAMINATION: I understand that during my exam a hygienist will check the gums and general oral hygiene to ensure that there are no problems with my gum health. A periodontal probe will be used to test the depth of the pockets around the teeth in order to detect if periodontal disease (gum disease) is present. The dentist will then perform an oral exam. Dental decay, which may appear as a hole or sometimes as a dark shadow underneath the surface of the enamel, will be noted. Finally, the soft tissues are checked for ulcerations or other lesions. The dentist may also check the jaws and associated muscles.</p>
      <p className="text-[#1d3243] text-[15px]">2. DIGITAL X-RAYS: Digital x-rays provide a great benefit to the patient. Not only are they an excellent diagnostic tool, but they decrease radiation to the patient by over 80% when compared to traditional x-rays. X-rays are used to diagnose the extent of bone loss associated with periodontal disease, interproximal caries (decay in between the teeth), pathology of pulp, integrity of root canal fillings, verify tooth or root structure, pathological root resorption, supernumerary teeth, impacted teeth, third molar location and position, bone pathology, and/or need for orthodontic treatment. Current x-rays will be necessary before any diagnosis can be finalized. No teeth will be treated without a current x-ray of the tooth. NO EXCEPTIONS.</p>
      <p className="text-[#1d3243] text-[15px]">PREGNANT WOMEN: x-rays will be avoided unless it is an emergency. Please inform this office if you are pregnant or possibly pregnant and x-rays may be postponed.</p>
      <p className="text-[#1d3243] text-[15px]">3. BASIC CLEANING: Teeth cleaning (also known as prophylaxis) is a procedure for the removal of tartar (mineralized plaque) that may develop even with careful brushing and flossing, especially in areas that are difficult to reach in routine brushing. A basic teeth cleaning includes supra-gingival (above the gum line) tooth scaling and tooth polishing.</p>
      <p className="text-[#1d3243] text-[15px]">4. FLUORIDE: Fluoride-containing compounds are used in topical and systemic fluoride therapy for preventing tooth decay.</p>
      <p className="text-[#1d3243] text-[15px]">5. DENTAL SEALANTS: Dental sealants are thin coatings that are applied to the grooves on the chewing surfaces of the teeth to protect them from tooth decay.</p>
      <p className="text-[#1d3243] text-[15px]">I understand that dentistry is not an exact science and that reputable practitioners cannot properly guarantee results. I acknowledge that no guarantee or assurance has been made by anyone regarding the dental treatment, which I have requested and authorized.</p>
      <p className="text-[#1d3243] text-[15px]">I hereby authorize any of the doctors or dental auxiliaries to proceed with and perform the dental treatments stated above. I understand that regardless of any dental insurance coverage I may have, I am responsible for the payment of the dental fees. I further understand that a finance charge or any fees associated with collection of an overdue account will be added to any overdue balance. I hereby authorize this office to obtain a copy of my credit report from a credit reporting agency for the purpose of considering payment options. </p>
      <FormControl error={form1.touched.agree && Boolean(form1.errors.agree)}>
        <Checkbox label='I confirm and agree*' name='agree' onChange={ form1.handleChange } checked={ form1.values.agree } />
        {form1.touched.agree && Boolean(form1.errors.agree) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>* Mandatory fields</p>
      <div className="flex">
        {props.showBack && <Button variant='plain' onClick={props.prevStep}>
          <ArrowBack />
          Back
        </Button>}
        <Button color='primary' type='submit' className='!ml-auto'>
          Next
          <ArrowForward />
        </Button>
      </div>
    </form>
  )
}