import React, { useState, useEffect } from 'react'
import { Radio, Button, Checkbox, RadioGroup, FormHelperText } from '@mui/joy'
import { ArrowForward, ArrowBack } from '@mui/icons-material'
import { useFormik } from 'formik'
import * as yup from 'yup'
import IFormProps from 'component/Form/IFormProps.ts'
import EventBus from '../../../../../bus.ts'
import { TextField, FormControl, Select, MenuItem, ListItemText } from '@mui/material'

export default (props: IFormProps) => {
  
  const validationSchema = () => {
    if(!props.validation) return yup.object({})
    const obj = {}
    return yup.object(obj)
  }

  const form1 = useFormik({
    initialValues: props.formData['step_' + props.step],
    validationSchema: validationSchema,
    onSubmit: (values) => {
      try{
        props.setFormData({ ...props.formData, ['step_' + props.step]: values })
        props.nextStep()
      }catch(e){
        console.log(e)
      }
    },
  });

  useEffect(() => {
    EventBus.$on('submit-next-' + props.slug + '-' + props.step, form1.handleSubmit)
  }, [])
  
  useEffect(() => {
    Object.keys(props.formData['step_' + props.step]).forEach(key => form1?.setFieldValue(key, props.formData['step_' + props.step][key]))
  }, [ props.formData['step_' + props.step] ])

  return (
    <form onSubmit={form1.handleSubmit} className={'flex flex-col gap-4 ' + props.className}>
      <FormControl error={form1.touched.name_of_current_physician && Boolean(form1.errors.name_of_current_physician)}>
        <TextField
          label="Child's Physician/Pediatrician" variant="standard" name='name_of_current_physician' fullWidth
          value={ form1.values.name_of_current_physician } onChange={ form1.handleChange } onBlur={form1.handleBlur}
        />
        {form1.touched.name_of_current_physician && Boolean(form1.errors.name_of_current_physician) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      <FormControl error={form1.touched.phone_of_current_physician && Boolean(form1.errors.phone_of_current_physician)}>
        <TextField
          label="Physician/Pediatrician phone number" variant="standard" name='phone_of_current_physician' fullWidth
          value={ form1.values.phone_of_current_physician } onChange={ form1.handleChange } onBlur={form1.handleBlur}
        />
        {form1.touched.phone_of_current_physician && Boolean(form1.errors.phone_of_current_physician) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      <FormControl error={form1.touched.address_of_current_physician && Boolean(form1.errors.address_of_current_physician)}>
        <TextField
          label="Pediatrician's Address" variant="standard" name='address_of_current_physician' fullWidth
          value={ form1.values.address_of_current_physician } onChange={ form1.handleChange } onBlur={form1.handleBlur}
        />
        {form1.touched.address_of_current_physician && Boolean(form1.errors.address_of_current_physician) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      <TextField
        label="Preferred pharmacy" variant="standard" name='preferred_pharmacy' fullWidth
        value={ form1.values.preferred_pharmacy } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />
      <TextField
        label="Date of last physical exam" variant="standard" name='date_of_last_exam' fullWidth
        value={ form1.values.date_of_last_exam } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />

      <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>* Mandatory fields</p>
      <div className="flex">
        {props.showBack && <Button variant='plain' onClick={props.prevStep}>
          <ArrowBack />
          Back
        </Button>}
        <Button color='primary' type='submit' className='!ml-auto'>
          Next
          <ArrowForward />
        </Button>
      </div>
    </form>
  )
}