import { IS_OTP_SENT_SUCCESS, IS_OTP_SENT_FAILED, SET_FORM_NAME, SET_OFFICE_NAME, SET_PATIENT, IS_SMS_VERIFIED } from "../actionTypes/actionTypes";

const initialState = {
  formName: '',
  officeName: '',
  patient: null,
  isOtpSent: false,
  isSmsVerified: false,
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FORM_NAME:
      return {
        ...state,
        formName: action.payload
      };
    case SET_OFFICE_NAME:
      return {
        ...state,
        officeName: action.payload
      };
    case SET_PATIENT:
      return {
        ...state,
        patient: action.payload
      };
    case IS_OTP_SENT_SUCCESS:
      return {
        ...state,
        isOtpSent: action.payload,
      };

    case IS_OTP_SENT_FAILED:
      return {
        ...state,
        isOtpSent: action.payload,
      };
    case IS_SMS_VERIFIED:
      return {
        ...state,
        isSmsVerified: action.payload,
      };
    default:
      return state;
  }
};
