import React, { useState, useEffect } from 'react'
import { Radio, Button, Checkbox, RadioGroup, FormControl, FormHelperText } from '@mui/joy'
import { ArrowForward, ArrowBack } from '@mui/icons-material'
import { useFormik } from 'formik'
import * as yup from 'yup'
import IFormProps from 'component/Form/IFormProps.ts'
import EventBus from '../../../../../bus.ts';
import { TextField } from '@mui/material'

export default (props: IFormProps) => {

  const validationSchema = () => {
    if(!props.validation) return yup.object({})
    return yup.object({
      agree: yup.boolean().isTrue().required('Required'),
      info_by_email: yup.string().isTrue().required('Required'),
      info_by_text: yup.string().isTrue().required('Required'),
    })
  }

  const form1 = useFormik({
    initialValues: props.formData['step_' + props.step],
    validationSchema: validationSchema,
    onSubmit: (values) => {
      try{
        props.setFormData({ ...props.formData, ['step_' + props.step]: values })
        props.nextStep()
      }catch(e){
        console.log(e)
      }
    },
  });

  useEffect(() => {
    EventBus.$on('submit-next-' + props.slug + '-' + props.step, form1.handleSubmit)
  }, [])
  
  useEffect(() => {
    Object.keys(props.formData['step_' + props.step]).forEach(key => form1?.setFieldValue(key, props.formData['step_' + props.step][key]))
  }, [ props.formData['step_' + props.step] ])

  return (
    <form onSubmit={form1.handleSubmit} className={'flex flex-col gap-4 ' + props.className}>
      <p className="text-[#1d3243] text-[15px]">Email Consent Form</p>
      <p className="text-[#1d3243] text-[15px]">PURPOSE: This form is used to obtain your consent to communicate with you by email regarding your Protected Health Information. Lakewood Dental Trails offers patients the opportunity to communicate by email. Transmitting patient information by email has a number of risks that patients should consider before granting consent to use email for these purposes. Lakewood Dental Trails will use reasonable means to protect the security and confidentiality of email information sent and received. However, Lakewood Dental Trails cannot guarantee the security and confidentiality of email communication and will not be liable for inadvertent disclosure of confidential information.</p>
      <p className="text-[#1d3243] text-[15px]">I acknowledge that I have read and fully understand this consent form. I understand the risks associated with communication of email between Lakewood Dental Trails and myself, and consent to the conditions outlined herein. Any questions I may have, been answered by Lakewood Dental Trails.</p>
      <FormControl error={form1.touched.info_by_email && Boolean(form1.errors.info_by_email)}>
        <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Please choose*</p>
        <RadioGroup value={ form1.values.info_by_email } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='info_by_email' orientation='horizontal'>
          <Radio label='I consent and accept the risk in receiving information via email' value='true' />
          <Radio label='I do not want to receive information via email' value='false' />
        </RadioGroup>
        {form1.touched.info_by_email && Boolean(form1.errors.info_by_email) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      { form1.values.info_by_email == 'false' &&
        <TextField
          label="Please explain why" variant="standard" name='info_by_email_reason' fullWidth
          value={ form1.values.info_by_email_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
        />
      }
      <p className="text-[#1d3243] text-[15px]">Text Message to Mobile Consent Form</p>
      <p className="text-[#1d3243] text-[15px]">PURPOSE: This form is used to obtain your consent to communicate with you by mobile text messaging regarding your Protected Health Information. Lakewood Dental Trails, offers patients the opportunity to communicate by mobile text messaging. Transmitting patient information by mobile text messaging has a number of risks that patients should consider before granting consent to use mobile text messaging for these purposes. Lakewood Dental Trails will use reasonable means to protect the security and confidentiality of mobile text messaging information sent and received. However, Lakewood Dental Trails cannot guarantee the security and confidentiality of mobile text messaging communication and will not be liable for inadvertent disclosure of confidential information.</p>
      <p className="text-[#1d3243] text-[15px]">I acknowledge that I have read and fully understand this consent form. I understand the risks associated with the communication of mobile text messaging between Lakewood Dental Trails and myself, and consent to the conditions outlined herein. Any questions I may have, been answered by Lakewood Dental Trails.</p>
      <FormControl error={form1.touched.info_by_text && Boolean(form1.errors.info_by_text)}>
        <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Please choose*</p>
        <RadioGroup value={ form1.values.info_by_text } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='info_by_text' orientation='vertical'>
          <Radio label='I consent to receiving information via mobile text messaging' value='true' />
          <Radio label='I do not want to receive information via mobile text messaging' value='false' />
        </RadioGroup>
        {form1.touched.info_by_text && Boolean(form1.errors.info_by_text) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      { form1.values.info_by_text == 'false' &&
        <TextField
          label="Please explain why" variant="standard" name='info_by_text_reason' fullWidth
          value={ form1.values.info_by_text_reason } onChange={ form1.handleChange } onBlur={form1.handleBlur}
        />
      }
      <FormControl error={form1.touched.agree && Boolean(form1.errors.agree)}>
        <Checkbox label='I confirm and agree*' name='agree' onChange={ form1.handleChange } checked={ form1.values.agree } />
        {form1.touched.agree && Boolean(form1.errors.agree) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>* Mandatory fields</p>
      <div className="flex">
        {props.showBack && <Button variant='plain' onClick={props.prevStep}>
          <ArrowBack />
          Back
        </Button>}
        <Button color='primary' type='submit' className='!ml-auto'>
          Next
          <ArrowForward />
        </Button>
      </div>
    </form>
  )
}