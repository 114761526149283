import { TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Button, Checkbox, FormControl, FormHelperText } from '@mui/joy'
import { ArrowBack, ArrowForward } from '@mui/icons-material'
import { Dayjs } from 'dayjs'
import IFormProps from 'component/Form/IFormProps.ts';
import { useFormik } from 'formik'
import * as yup from 'yup'
import EventBus from '../../../../../bus.ts';

export default (props: IFormProps) => {

  const validationSchema = () => {
    if(!props.validation) return yup.object({})
    return yup.object({
      agree: yup.boolean().isTrue().required('Required')
    })
  }

  const form1 = useFormik({
    initialValues: props.formData['step_' + props.step],
    validationSchema: validationSchema,
    onSubmit: (values) => {
      try{
        props.setFormData({ ...props.formData, ['step_' + props.step]: values })
        props.nextStep()
      }catch(e){
        console.log(e)
      }
    },
  });

  useEffect(() => {
    EventBus.$on('submit-next-' + props.slug + '-' + props.step, form1.handleSubmit)
  }, [])
  
  useEffect(() => {
    Object.keys(props.formData['step_' + props.step]).forEach(key => form1?.setFieldValue(key, props.formData['step_' + props.step][key]))
  }, [ props.formData['step_' + props.step] ])

  return (
    <form onSubmit={form1.handleSubmit} className={'flex flex-col gap-4 ' + props.className}>
      <p className="text-[#1d3243] text-[15px]">We noticed your experience was less than exceptional and would like to know how we can improve for next time. Would you be willing to participate in a brief survey?</p>
      <p className="text-[#1d3243] text-[15px]">I understand that my participation in this post-appointment survey is optional and will only be used to improve future patient experience. </p>
      
      <FormControl error={form1.touched.agree && Boolean(form1.errors.agree)}>
        <Checkbox label='I confirm and agree*' name='agree' onChange={ form1.handleChange } checked={ form1.values.agree } />
        {form1.touched.agree && Boolean(form1.errors.agree) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      <FormControl error={form1.touched.experience_on_practice && Boolean(form1.errors.experience_on_practice)}>
        <TextField
          label="Please describe your experience visiting our practice" variant="standard" name='experience_on_practice' fullWidth placeholder='Type here'
          value={ form1.values.experience_on_practice } onChange={ form1.handleChange } onBlur={form1.handleBlur}
        />
        {form1.touched.experience_on_practice && Boolean(form1.errors.experience_on_practice) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      <FormControl error={form1.touched.experience_on_doctors && Boolean(form1.errors.experience_on_doctors)}>
        <TextField
          label="Please describe your experience working with the doctor(s) and staff" variant="standard" name='experience_on_doctors' fullWidth placeholder='Type here'
          value={ form1.values.experience_on_doctors } onChange={ form1.handleChange } onBlur={form1.handleBlur}
        />
        {form1.touched.experience_on_doctors && Boolean(form1.errors.experience_on_doctors) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      <FormControl error={form1.touched.suggestion && Boolean(form1.errors.suggestion)}>
        <TextField
          label="What area could we improve upon to make your experience even more enjoyable?" variant="standard" name='suggestion' fullWidth placeholder='Type here'
          value={ form1.values.suggestion } onChange={ form1.handleChange } onBlur={form1.handleBlur}
        />
        {form1.touched.suggestion && Boolean(form1.errors.suggestion) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>

      <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>* Mandatory fields</p>
      <div className="flex">
        {props.showBack && <Button variant='plain' onClick={props.prevStep}>
          <ArrowBack />
          Back
        </Button>}
        <Button color='primary' type='submit' className='!ml-auto'>
          Next
          <ArrowForward />
        </Button>
      </div>
    </form>
  )
}