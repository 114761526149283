import { TextField, Select, MenuItem } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { DateField } from '@mui/x-date-pickers'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { Radio, Button, FormControl, FormHelperText, RadioGroup } from '@mui/joy'
import { ArrowBack, ArrowForward } from '@mui/icons-material'
import { Dayjs } from 'dayjs'
import IFormProps from 'component/Form/IFormProps.ts';
import { useFormik } from 'formik'
import * as yup from 'yup'
import EventBus from '../../../../../bus.ts';

export default (props: IFormProps) => {

  const onFilePicked = e => {
    let files = e.target.files
    var reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = function () {
      console.log({ name: e.target.name, value: reader.result })
      form1.setFieldValue(e.target.name, reader.result)
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  const validationSchema = () => {
    if(!props.validation) return yup.object({})
    const obj = {
      first_name: yup.string().required('Required').nullable(),
      last_name: yup.string().required('Required').nullable(),
      dob: yup.string().required('Required').nullable(),
      gender: yup.string().required('Required').nullable(),
      marital_status: yup.string().required('Required').nullable(),
    }
    if(form1?.values?.heard_from == 'Other') obj['heard_from_other'] = yup.string().required('Required').nullable()
    return yup.object(obj)
  }

  const form1 = useFormik({
    initialValues: props.formData['step_' + props.step],
    validationSchema: validationSchema,
    onSubmit: (values) => {
      try{
        props.setFormData({ ...props.formData, ['step_' + props.step]: values })
        props.nextStep()
      }catch(e){
        console.log(e)
      }
    },
  });

  useEffect(() => {
    EventBus.$on('submit-next-' + props.slug + '-' + props.step, form1.handleSubmit)
  }, [])
  
  useEffect(() => {
    Object.keys(props.formData['step_' + props.step]).forEach(key => form1?.setFieldValue(key, props.formData['step_' + props.step][key]))
  }, [ props.formData['step_' + props.step] ])

  return (
    <form onSubmit={form1.handleSubmit} className={'flex flex-col gap-4 ' + props.className}>
      <FormControl error={form1.touched.first_name && Boolean(form1.errors.first_name)}>
        <TextField
          label="First Name*" variant="standard" name='first_name' fullWidth
          value={ form1.values.first_name } onChange={ form1.handleChange } onBlur={form1.handleBlur}
        />
        {form1.touched.first_name && Boolean(form1.errors.first_name) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      <FormControl error={form1.touched.last_name && Boolean(form1.errors.last_name)}>
        <TextField
          label="Last Name*" variant="standard" name='last_name' fullWidth
          value={ form1.values.last_name } onChange={ form1.handleChange } onBlur={form1.handleBlur}
        />
        {form1.touched.last_name && Boolean(form1.errors.last_name) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      <TextField
        label="Middle Initial" variant="standard" name='middle_initial' fullWidth
        value={ form1.values.middle_initial } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />
      <TextField
        label="Preferred Name" variant="standard" name='preferred_name' fullWidth
        value={ form1.values.preferred_name } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />
      <FormControl error={form1.touched.dob && Boolean(form1.errors.dob)}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={['DatePicker']}>
            <DateField
              label="Date of Birth" variant="standard" name='dob' fullWidth
              onChange={ ( v: Dayjs | null ) => {
                if(v?.isValid()) form1.setFieldValue('dob', v?.toISOString().substring(0, 10), true)
              }}
              onBlur={form1.handleBlur}
            />
          </DemoContainer>
        </LocalizationProvider>
        {form1.touched.dob && Boolean(form1.errors.dob) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Please add your profile Picture</p>
      <TextField variant="standard" name='photo' fullWidth type='file' onChange={ onFilePicked } />
      <FormControl error={form1.touched.gender && Boolean(form1.errors.gender)}>
        <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Gender*</p>
        <RadioGroup value={ form1.values.gender } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='gender' orientation='horizontal'>
          <Radio label='Male' value="Male" />
          <Radio label='Female' value="Female" />
          <Radio label='Other' value="Other" />
          <Radio label='Prefer not to self identify' value="Prefer not to self identify" />
        </RadioGroup>
        {form1.touched.gender && Boolean(form1.errors.gender) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>
      <TextField
        label="SSN#" variant="standard" name='ssn' fullWidth
        value={ form1.values.ssn } onChange={ form1.handleChange } onBlur={form1.handleBlur}
      />
      <div>
        <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Please take a picture of the FRONT of your Driver's Licence/ID card</p>
        <TextField variant="standard" name='licence_front_photo' fullWidth type='file' onChange={ onFilePicked } />
      </div>
      <div>
        <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Please take a picture of the BACK of your Driver's Licence/ID card</p>
        <TextField variant="standard" name='licence_back_photo' fullWidth type='file' onChange={ onFilePicked } />
      </div>
      <FormControl error={form1.touched.marital_status && Boolean(form1.errors.marital_status)}>
        <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>Marital Status*</p>
        <RadioGroup value={ form1.values.marital_status } onChange={ form1.handleChange } onBlur={form1.handleBlur} name='marital_status' orientation='horizontal'>
          <Radio label='Single' value="Single" />
          <Radio label='Married' value="Married" />
          <Radio label='Widowed' value="Widowed" />
          <Radio label='Divorced' value="Divorced" />
          <Radio label='Prefer not to say' value="Prefer not to say" />
        </RadioGroup>
        {form1.touched.marital_status && Boolean(form1.errors.marital_status) && <FormHelperText className='!mt-0 !mb-2 !text-[12px] !text-red-400'>Required</FormHelperText>}
      </FormControl>

      <p className='mb-0 text-[1rem] text-[#000]/[0.6]'>* Mandatory fields</p>
      <div className="flex">
        {props.showBack && <Button variant='plain' onClick={props.prevStep}>
          <ArrowBack />
          Back
        </Button>}
        <Button color='primary' type='submit' className='!ml-auto'>
          Next
          <ArrowForward />
        </Button>
      </div>
    </form>
  )
}